import { createAsyncThunk } from '@reduxjs/toolkit'

import { initiateLogin, refreshAuth } from '../../api/auth'

export const login = createAsyncThunk(
  'auth/login',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await initiateLogin(email, password)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const refreshToken = createAsyncThunk(
  'auth/refresh',
  async (refreshToken, { rejectWithValue }) => {
    try {
      const response = await refreshAuth(refreshToken)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)
