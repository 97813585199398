import Add from '@mui/icons-material/Add'
import { Button, IconButton, Tooltip } from '@mui/material'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import { useApp } from '../../../../app/context/AppContext'
import BottomDrawer from '../../../../components/BottomDrawer'
import EnhancedTable from '../../../../components/EnhancedTable'
import Page from '../../../../components/Page'
import { selectAuthenticatedUserHasPermissions } from '../../../auth/slice'
import { createUser, editUser, loadUsers, removeUser } from '../api'
import { UserDialog } from '../components'
import UserDetail from '../components/UserDetail'
import { UsersTableColumns } from '../components/UsersTable'
import {
  selectUser,
  selectUsersViewProperties,
  setUsersOrder,
  setUsersOrderBy,
  setUsersPage,
  setUsersSearch
} from '../slice'

function UsersView() {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [searchParams, setSearchParams] = useSearchParams({})
  const { isSideBarOpen, sideBarWidth } = useApp()

  const [isLoading, setIsLoading] = useState(true)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [focusedId, setFocusedId] = useState(null)
  const [selectedIds, setSelectedIds] = useState([])
  const [isBottomDrawerOpen, setIsBottomDrawerOpen] = useState(false)
  const [bottomDrawerHeight, setBottomDrawerHeight] = useState(null)

  const table = useRef()

  const [canEdit, canDelete] = useSelector(
    selectAuthenticatedUserHasPermissions(['change_user', 'delete_user'])
  )

  const { search, page, order, orderBy } = useSelector(
    selectUsersViewProperties
  )

  const selectedUser = useSelector(
    selectUser(selectedIds.length == 1 ? selectedIds[0] : null)
  )

  const getDataFetcher = () => (queryParams) => {
    return dispatch(loadUsers(queryParams)).unwrap()
  }

  const handleSubmit = (values) => {
    if (_.get(values, 'id', null) != null) {
      dispatch(editUser(values))
        .unwrap()
        .then((e) => {
          enqueueSnackbar('Update successful', {
            variant: 'success'
          })
          table.current.loadData()
        })
        .catch((err) => {
          enqueueSnackbar('Update failed', {
            variant: 'error'
          })
        })
    } else {
      dispatch(createUser(values))
        .unwrap()
        .then((result) => {
          enqueueSnackbar('Creation successful', {
            variant: 'success'
          })
          table.current.loadData()
        })
        .catch((err) => {
          enqueueSnackbar('Creation failed', {
            variant: 'error'
          })
        })
    }

    setSelectedIds([])
  }

  const handleDelete = (id) => {
    dispatch(removeUser(id))
      .unwrap()
      .then((res) => {
        if (isDialogOpen) {
          setIsDialogOpen(false)
        }
        enqueueSnackbar('Deletion successful', {
          variant: 'success'
        })
        table.current.loadData()
      })
      .catch((err) => {
        enqueueSnackbar('Deletion failed', {
          variant: 'error'
        })
      })
  }

  useEffect(() => {
    const searchObject = { ...searchParams }

    if (isLoading) {
      const urlSearch = searchParams.get('search')
      const urlSelected = searchParams.get('selected') || []
      const urlPage = searchParams.get('page')
      const urlOrder = searchParams.get('order')
      const urlOrderBy = searchParams.get('orderBy')

      if (urlSearch) {
        dispatch(setUsersSearch(urlSearch))
      }

      if (urlSelected.length > 0) {
        setSelectedIds(
          searchParams
            .get('selected')
            .split(',')
            .map((p) => parseInt(p))
        )
      }

      if (urlPage) {
        dispatch(setUsersPage(parseInt(urlPage)))
      }

      if (urlOrder) {
        dispatch(setUsersOrder(urlOrder))
      }

      if (urlOrderBy) {
        dispatch(setUsersOrderBy(urlOrderBy))
      }

      setIsLoading(false)
    }

    if (selectedIds.length > 0) {
      searchObject.selected = selectedIds.join(',')
    } else {
      delete searchObject.selected
    }

    if (!_.isEmpty(search)) {
      searchObject.search = search
    } else {
      delete searchObject.search
    }

    if (page > 0) {
      searchObject.page = page
    } else {
      delete searchObject.page
    }

    if (!_.isEmpty(order)) {
      searchObject.order = order
    } else {
      delete searchObject.order
    }

    if (!_.isEmpty(orderBy)) {
      searchObject.orderBy = orderBy
    } else {
      delete searchObject.orderBy
    }

    setSearchParams(searchObject)
  }, [search, page, order, orderBy, selectedIds])

  return (
    <Page loading={isLoading}>
      <div
        style={{
          display: 'block',
          width: '100%',
          marginBottom: isBottomDrawerOpen ? `${bottomDrawerHeight}px` : '0px'
        }}
      >
        <EnhancedTable
          ref={table}
          selectable
          editable={canEdit}
          hoverable
          searchable
          deletable={canDelete}
          title="Users"
          columns={UsersTableColumns}
          filters={[
            {
              id: 'is_active',
              group: 'User Filters',
              label: 'Status',
              type: 'select',
              getParamValue: (option) => (option === 0 ? null : option === 1),
              options: [
                { label: 'All', value: 0 },
                { label: 'Active', value: 1 },
                { label: 'Inactive', value: 2 }
              ],
              defaultValue: 0
            }
          ]}
          fetchData={getDataFetcher()}
          defaultOrder={order || 'asc'}
          defaultOrderBy={orderBy || 'id'}
          initialSearch={search}
          initialPage={page}
          initialSelected={selectedIds}
          actions={[
            <Tooltip key="new" title="Add New">
              <IconButton
                onClick={() => {
                  setFocusedId(null)
                  setIsDialogOpen(true)
                }}
              >
                <Add />
              </IconButton>
            </Tooltip>
          ]}
          onEdit={(row) => {
            setFocusedId(row.id)
            setIsDialogOpen(true)
          }}
          onSelectedChange={(selected) => {
            setSelectedIds(selected)
          }}
          onSearch={(value) => dispatch(setUsersSearch(value))}
          onSortChange={(newOrder, newOrderBy) => {
            dispatch(setUsersOrder(newOrder))
            dispatch(setUsersOrderBy(newOrderBy))
          }}
          onPageChange={(value) => dispatch(setUsersPage(value))}
        />
      </div>
      <UserDialog
        open={isDialogOpen}
        userId={focusedId}
        onSubmit={handleSubmit}
        onClose={() => {
          setIsDialogOpen(false)
          setFocusedId(null)
        }}
        extraActions={
          focusedId != null
            ? [
                <Button
                  key="delete"
                  color="error"
                  onClick={() => handleDelete(focusedId)}
                >
                  Delete
                </Button>
              ]
            : []
        }
      />
      <BottomDrawer
        open={isBottomDrawerOpen}
        title={
          selectedUser
            ? 'User Details'
            : selectedIds.length > 0
            ? `Selected: ${selectedIds.join(', ')}`
            : 'Select a User'
        }
        onToggle={() => setIsBottomDrawerOpen(!isBottomDrawerOpen)}
        PaperStyle={{
          marginLeft: isSideBarOpen ? `${sideBarWidth}px` : 0
        }}
        onHeightChange={(height) => {
          setBottomDrawerHeight(height)
        }}
      >
        {selectedUser && <UserDetail user={selectedUser} />}
      </BottomDrawer>
    </Page>
  )
}

export default UsersView
