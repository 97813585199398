import { schema } from 'normalizr'

import CommonTypeSchema from '../common-types/schema'
import UserSchema from '../users/schema'
import AddressSchema from './addresses/schema'
import ContactSchema from './contacts/schema'

const OrganizationSchema = new schema.Entity('organizations', {
  user: UserSchema,
  org_type: CommonTypeSchema,
  lead_source: CommonTypeSchema,
  contacts: [ContactSchema],
  addresses: [AddressSchema]
})

export const OrganizationProductSchema = new schema.Entity(
  'organizationProducts',
  {
    product: CommonTypeSchema
  }
)

export default OrganizationSchema
