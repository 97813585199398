import React, { lazy } from 'react'

import { Loadable } from '../components'
import { CalendarView } from '../features/apps/calendar/views'
import {
  ContactPrefixesView,
  LeadSourcesView,
  OrganizationContactsView,
  OrganizationTypesView,
  ProductsView
} from '../features/apps/common-types/views'
import MailMergeView from '../features/apps/organizations/mail-merge/views/MailMergeView'
import {
  OrganizationCallBacksView,
  OrganizationDashboardView,
  OrganizationsDetailView,
  OrganizationsView
} from '../features/apps/organizations/views'
import {
  GroupsDetailView,
  GroupsView
} from '../features/apps/users/groups/views'
import { UsersView } from '../features/apps/users/views'
import UserDetailView from '../features/apps/users/views/UserDetailView'
import { Login, Logout } from '../features/auth/views'

const AppSelectionView = Loadable(
  lazy(() => import('../views/AppSelectionView'))
)

const routes = [
  {
    app: null,
    path: '/',
    name: 'home',
    visibility: 'private',
    redirect: '/apps',
    main: () => <Login />,
    showTopBar: false,
    showSideBar: false,
    showDetailPane: false
  },
  {
    app: null,
    path: '/apps/',
    name: 'apps',
    visibility: 'private',
    main: () => <AppSelectionView />,
    showTopBar: true,
    showSideBar: false,
    showDetailPane: false
  },
  {
    app: 'organizations',
    path: '/organizations/',
    name: 'organizations',
    visibility: 'private',
    main: () => <OrganizationsView />,
    showTopBar: true,
    showSideBar: true,
    showDetailPane: true
  },
  {
    app: 'organizations',
    path: '/organizations/dashboard/',
    name: 'organizations-dashboard',
    visibility: 'private',
    main: () => <OrganizationDashboardView />,
    showTopBar: true,
    showSideBar: true,
    showDetailPane: false
  },
  {
    app: 'organizations',
    path: '/organizations/call-backs/',
    name: 'organizations-call-backs',
    visibility: 'private',
    main: () => <OrganizationCallBacksView />,
    showTopBar: true,
    showSideBar: true,
    showDetailPane: false
  },
  {
    app: 'organizations',
    path: '/organizations/mail-merges/',
    name: 'organizations-mail-merge',
    visibility: 'private',
    main: () => <MailMergeView />,
    showTopBar: true,
    showSideBar: true,
    showDetailPane: false
  },
  {
    app: 'organizations',
    path: '/organizations/:id/',
    name: 'organizations-detail',
    visibility: 'private',
    main: () => <OrganizationsDetailView />,
    showTopBar: true,
    showSideBar: true,
    showDetailPane: false
  },
  {
    app: 'common-types',
    path: '/common-types/',
    name: 'common-types',
    visibility: 'private',
    redirect: (state) => {
      return `/common-types/${state.commonTypes.view.category
        .split(' ')
        .map((s) => s.toLowerCase())
        .join('-')}/`
    },
    showTopBar: true,
    showSideBar: true,
    showDetailPane: false
  },
  {
    app: 'common-types',
    path: '/common-types/contact-prefixes/',
    name: 'common-types_contact-prefixes',
    visibility: 'private',
    main: () => <ContactPrefixesView />,
    showTopBar: true,
    showSideBar: true,
    showDetailPane: false
  },
  {
    app: 'common-types',
    path: '/common-types/lead-sources/',
    name: 'common-types_lead-sources',
    visibility: 'private',
    main: () => <LeadSourcesView />,
    showTopBar: true,
    showSideBar: true,
    showDetailPane: false
  },
  {
    app: 'common-types',
    path: '/common-types/organization-contacts/',
    name: 'common-types_organization-contacts',
    visibility: 'private',
    main: () => <OrganizationContactsView />,
    showTopBar: true,
    showSideBar: true,
    showDetailPane: false
  },
  {
    app: 'common-types',
    path: '/common-types/organization-types/',
    name: 'common-types_organization-types',
    visibility: 'private',
    main: () => <OrganizationTypesView />,
    showTopBar: true,
    showSideBar: true,
    showDetailPane: false
  },
  {
    app: 'common-types',
    path: '/common-types/products/',
    name: 'common-types_products',
    visibility: 'private',
    main: () => <ProductsView />,
    showTopBar: true,
    showSideBar: true,
    showDetailPane: false
  },
  {
    app: 'users',
    path: '/users/',
    name: 'users',
    visibility: 'private',
    main: () => <UsersView />,
    showTopBar: true,
    showSideBar: true,
    showDetailPane: true
  },
  {
    app: 'users',
    path: '/users/:id/',
    name: 'users-detail',
    visibility: 'private',
    main: () => <UserDetailView />,
    showTopBar: true,
    showSideBar: true,
    showDetailPane: false
  },
  {
    app: 'users',
    path: '/users/groups/',
    name: 'users-groups',
    visibility: 'private',
    main: () => <GroupsView />,
    showTopBar: true,
    showSideBar: true,
    showDetailPane: false
  },
  {
    app: 'users',
    path: '/users/groups/:id/',
    name: 'users-groups-detail',
    visibility: 'private',
    main: () => <GroupsDetailView />,
    showTopBar: true,
    showSideBar: true,
    showDetailPane: false
  },
  {
    app: 'calendar',
    path: '/calendar/',
    name: 'calendar',
    visibility: 'private',
    main: () => <CalendarView />,
    showTopBar: true,
    showSideBar: false,
    showDetailPane: false
  },
  {
    app: null,
    path: '/login/',
    name: 'login',
    visibility: 'public',
    main: () => <Login />,
    showTopBar: false,
    showSideBar: false,
    showDetailPane: false
  },
  {
    app: null,
    path: '/logout/',
    name: 'logout',
    visibility: 'private',
    main: () => <Logout />,
    showTopBar: false,
    showSideBar: false,
    showDetailPane: false
  }
]

export default routes
