import { Box, CircularProgress, Skeleton } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

function LoadingPanel({ containerSx, indicatorSx }) {
  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: 'calc(100vh - 64px)',
        flexGrow: 1,
        p: 2,
        ...containerSx
      }}
    >
      <CircularProgress
        sx={{
          position: 'absolute',
          marginLeft: 'auto',
          marginRight: 'auto',
          top: '50%',
          left: 0,
          right: 0,
          textAlign: 'center',
          ...indicatorSx
        }}
      />
      <Skeleton sx={{ transform: 'none', width: '100%' }} />
    </Box>
  )
}

LoadingPanel.propTypes = {
  containerSx: PropTypes.object,
  indicatorSx: PropTypes.object
}

LoadingPanel.defaultProps = {
  containerSx: {},
  indicatorSx: {}
}

export default LoadingPanel
