import { createAsyncThunk } from '@reduxjs/toolkit'
import { normalize } from 'normalizr'

import {
  deleteCommonType,
  fetchCommonType,
  fetchCommonTypes,
  patchCommonType,
  postCommonType
} from '../../../api/common-type'
import CommonTypeSchema from './schema'

export const loadCommonTypes = createAsyncThunk(
  'common-types/fetchMany',
  async (queryParams, { rejectWithValue }) => {
    try {
      const response = await fetchCommonTypes(queryParams)
      response.normalized = normalize(response.data, [CommonTypeSchema])
      return response
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const loadCommonType = createAsyncThunk(
  'common-types/fetchOne',
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetchCommonType(id)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const createCommonType = createAsyncThunk(
  'common-types/create',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await postCommonType(payload)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const editCommonType = createAsyncThunk(
  'common-types/patch',
  async ({ id, ...data }, { rejectWithValue }) => {
    try {
      const response = await patchCommonType(id, data)
      return response.data
    } catch (e) {
      rejectWithValue(e.response.data)
    }
  }
)

export const removeCommonType = createAsyncThunk(
  'common-types/delete',
  async (id, { rejectWithValue }) => {
    try {
      await deleteCommonType(id)
      return id
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)
