import { Box } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { loadAuthenticatedUser } from '../../apps/users/api'
import { login } from '../api'
import { LoginForm } from '../components'

function Login() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const [isSuccessful, setIsSuccessful] = useState(false)
  const [isAuthenticating, setIsAuthenticating] = useState(false)
  const [error, setError] = useState(null)

  const handleSuccessfulLogin = (payload) => {
    const { first_name, last_name } = payload
    setError(null)
    setIsSuccessful(true)
    enqueueSnackbar(`Welcome, ${first_name} ${last_name}!`, {
      variant: 'success'
    })
    navigate('/apps')
  }

  const handleFailedLogin = (error) => {
    setError(error)
    setIsSuccessful(false)
    enqueueSnackbar(error.message, {
      variant: 'error'
    })
  }

  return (
    <Box
      sx={{
        backgroundImage:
          'linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(content/cookies.jpeg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        width: '100%',
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <LoginForm
        error={error != null}
        onSubmit={(email, password) => {
          setIsAuthenticating(true)
          dispatch(login({ email, password }))
            .unwrap()
            .then(() => {
              dispatch(loadAuthenticatedUser()).then((response) =>
                handleSuccessfulLogin(response.payload)
              )
            })
            .catch((err) => handleFailedLogin(err))
            .finally(() => setIsAuthenticating(false))
        }}
      />
    </Box>
  )
}

export default Login
