const COLOR_MODE_KEY = 'cc.app.colorMode'

class AppService {
  clearAll() {
    this.colorMode = null
  }

  get colorMode() {
    return localStorage.getItem(COLOR_MODE_KEY)
  }

  set colorMode(value) {
    if (value === null) {
      localStorage.removeItem(COLOR_MODE_KEY)
    } else {
      localStorage.setItem(COLOR_MODE_KEY, value)
    }
  }
}

export default new AppService()
