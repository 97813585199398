import AddIcon from '@mui/icons-material/Add'
import { Button, IconButton, Tooltip } from '@mui/material'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import EnhancedTable from '../../../../components/EnhancedTable'
import Page from '../../../../components/Page'
import {
  createCommonType,
  editCommonType,
  loadCommonTypes,
  removeCommonType
} from '../api'
import { CommonTypeDialog } from '../components'
import {
  getCommonTypesViewProperties,
  setCommonTypesCategory,
  setCommonTypesOrder,
  setCommonTypesOrderBy,
  setCommonTypesPage,
  setCommonTypesSearch
} from '../slice'

function CommonTypesBaseView({ title, category, columns, onSubmit, ...props }) {
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams({})
  const { enqueueSnackbar } = useSnackbar()

  const [isLoading, setIsLoading] = useState(true)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [selectedIds, setSelectedIds] = useState([])
  const [focusedId, setFocusedId] = useState(null)

  const table = useRef()

  const {
    search,
    page,
    order,
    orderBy,
    category: currentCategory
  } = useSelector(getCommonTypesViewProperties)

  const getDataFetcher = () => (queryParams) => {
    return dispatch(loadCommonTypes({ category, ...queryParams })).unwrap()
  }

  const handleDelete = (id) => {
    dispatch(removeCommonType(id))
      .unwrap()
      .then(() => {
        if (isDialogOpen) {
          setIsDialogOpen(false)
        }
        enqueueSnackbar('Deletion successful', {
          variant: 'success'
        })
        table.current.loadData()
      })
      .catch(() => {
        enqueueSnackbar('Deletion failed', {
          variant: 'error'
        })
      })
  }

  const handleSubmit = (values) => {
    if (onSubmit) {
      return onSubmit
    }

    if (_.get(values, 'id', null) != null) {
      dispatch(editCommonType(values))
        .unwrap()
        .then(() => {
          enqueueSnackbar('Update successful', {
            variant: 'success'
          })
          table.current.loadData()
        })
        .catch((e) => {
          console.log(e)
          enqueueSnackbar('Update failed', {
            variant: 'error'
          })
        })
    } else {
      dispatch(createCommonType(values))
        .unwrap()
        .then(() => {
          enqueueSnackbar('Creation successful', {
            variant: 'success'
          })
          table.current.loadData()
        })
        .catch(() => {
          enqueueSnackbar('Creation failed', {
            variant: 'error'
          })
        })
    }

    setSelectedIds([])
  }

  useEffect(() => {
    if (currentCategory !== category) {
      dispatch(setCommonTypesCategory(category))
    }
  }, [])

  useEffect(() => {
    const searchObject = { ...searchParams }

    if (isLoading) {
      const urlSearch = searchParams.get('search')
      const urlSelected = searchParams.get('selected') || []
      const urlPage = searchParams.get('page')
      const urlOrder = searchParams.get('order')
      const urlOrderBy = searchParams.get('orderBy')

      if (urlSearch) {
        dispatch(
          setCommonTypesSearch({
            pageKey: category,
            value: urlSearch
          })
        )
      }

      if (urlSelected.length > 0) {
        setSelectedIds(
          searchParams
            .get('selected')
            .split(',')
            .map((p) => parseInt(p))
        )
      }

      if (urlPage) {
        dispatch(
          setCommonTypesPage({ pageKey: category, value: parseInt(urlPage) })
        )
      }

      if (urlOrder) {
        dispatch(
          setCommonTypesOrder({
            pageKey: category,
            value: urlOrder
          })
        )
      }

      if (urlOrderBy) {
        dispatch(
          setCommonTypesOrderBy({
            pageKey: category,
            value: urlOrderBy
          })
        )
      }

      setIsLoading(false)
    }

    if (selectedIds.length > 0) {
      searchObject.selected = selectedIds.join(',')
    } else {
      delete searchObject.selected
    }

    if (!_.isEmpty(search[category])) {
      searchObject.search = search[category]
    } else {
      delete searchObject.search
    }

    if (page[category] > 0) {
      searchObject.page = page[category]
    } else {
      delete searchObject.page
    }

    if (!_.isEmpty(order[category])) {
      searchObject.order = order[category]
    } else {
      delete searchObject.order
    }

    if (!_.isEmpty(orderBy[category])) {
      searchObject.orderBy = orderBy[category]
    } else {
      delete searchObject.orderBy
    }

    setSearchParams(searchObject)
  }, [search, page, order, orderBy, selectedIds])

  return (
    <Page loading={isLoading}>
      <EnhancedTable
        ref={table}
        editable
        selectable
        hoverable
        searchable
        title={title}
        {...props}
        columns={columns}
        fetchData={getDataFetcher()}
        defaultOrder={order[category] || 'asc'}
        defaultOrderBy={orderBy[category] || 'id'}
        initialSearch={search[category]}
        initialPage={page[category]}
        initialSelected={selectedIds}
        onEdit={(row) => {
          setFocusedId(row.id)
          setIsDialogOpen(true)
        }}
        onDelete={(ids) => ids.forEach((id) => handleDelete(id))}
        actions={[
          <Tooltip key="new" title="Add New">
            <IconButton
              onClick={() => {
                setFocusedId(null)
                setIsDialogOpen(true)
              }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        ]}
        onSearch={(value) =>
          dispatch(setCommonTypesSearch({ pageKey: category, value }))
        }
        onSelectedChange={(selected) => {
          setFocusedId(selected.length === 1 ? selected[0] : null)

          setSelectedIds(selected)
        }}
        onSortChange={(newOrder, newOrderBy) => {
          dispatch(setCommonTypesOrder({ pageKey: category, value: newOrder }))
          dispatch(
            setCommonTypesOrderBy({ pageKey: category, value: newOrderBy })
          )
        }}
        onPageChange={(value) =>
          dispatch(setCommonTypesPage({ pageKey: category, value }))
        }
      />
      <CommonTypeDialog
        category={category}
        open={isDialogOpen}
        commonTypeId={focusedId}
        onSubmit={handleSubmit}
        onClose={() => {
          setIsDialogOpen(false)
        }}
        extraActions={
          focusedId != null
            ? [
                <Button
                  key="delete"
                  color="error"
                  onClick={() => handleDelete(focusedId)}
                >
                  Delete
                </Button>
              ]
            : []
        }
      />
    </Page>
  )
}

CommonTypesBaseView.propTypes = {
  title: PropTypes.string,
  category: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object),
  actions: PropTypes.arrayOf(PropTypes.element),
  onSubmit: PropTypes.func
}

export default CommonTypesBaseView
