import {
  createEntityAdapter,
  createSelector,
  createSlice
} from '@reduxjs/toolkit'
import _ from 'lodash'

import {
  createGroup,
  editGroup,
  loadGroup,
  loadGroups,
  removeGroup
} from './api'

const groupsAdapter = createEntityAdapter()

const slice = createSlice({
  name: 'groups',
  initialState: groupsAdapter.getInitialState({
    view: {
      search: null,
      page: 0,
      order: 'asc',
      orderBy: 'id'
    }
  }),
  reducers: {
    setPage(state, action) {
      state.view.page = action.payload
    },
    setSearch(state, action) {
      state.view.search = action.payload
    },
    setOrder(state, action) {
      state.view.order = action.payload
    },
    setOrderBy(state, action) {
      state.view.orderBy = action.payload
    }
  },
  extraReducers: {
    [loadGroups.fulfilled]: (state, action) => {
      const {
        normalized: { entities }
      } = action.payload
      groupsAdapter.upsertMany(state, entities.groups ?? {})
    },
    [loadGroups.rejected]: (state, action) => {
      console.log(action)
    },
    [loadGroup.fulfilled]: (state, action) => {
      const {
        normalized: { entities }
      } = action.payload
      groupsAdapter.upsertOne(state, Object.values(entities.groups)[0])
    },
    [loadGroup.rejected]: (state, action) => {
      console.log(action)
    },
    [createGroup.fulfilled]: (state, action) => {
      groupsAdapter.addOne(state, action.payload)
    },
    [createGroup.rejected]: (state, action) => {
      console.log(action)
    },
    [editGroup.fulfilled]: (state, action) => {
      groupsAdapter.upsertOne(state, action.payload)
    },
    [editGroup.rejected]: (state, action) => {
      console.log(action)
    },
    [removeGroup.fulfilled]: (state, action) => {
      groupsAdapter.removeOne(state, action.payload)
    },
    [removeGroup.rejected]: (state, action) => {
      console.log(action)
    }
  }
})

export const selectGroupEntities = (state) => {
  return state.groups
}

const selectGroupIds = (state, groupIds) => groupIds

const { selectById } = groupsAdapter.getSelectors(selectGroupEntities)

export const selectGroup = (id) => (state) => selectById(state, id)

export const selectGroupsByIds = createSelector(
  [selectGroupEntities, selectGroupIds],
  (groups, ids) => {
    return _.reduce(
      groups.entities,
      (result, value, key) => {
        if (key in ids) {
          result[key] = value
        }
        return result
      },
      {}
    )
  }
)

export const getGroupsViewProperties = createSelector(
  selectGroupEntities,
  (state) => state.view
)

export const {
  setPage: setGroupsPage,
  setSearch: setGroupsSearch,
  setOrder: setGroupsOrder,
  setOrderBy: setGroupsOrderBy
} = slice.actions

const reducer = slice.reducer
export default reducer
