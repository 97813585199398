import {
  Box,
  Breadcrumbs,
  Button,
  Link,
  Tooltip,
  Typography
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom'

import Page from '../../../../../components/Page'
import { loadGroup, removeGroup } from '../api'
import { GroupDetail } from '../components'
import { selectGroup } from '../slice'

function GroupDetailView() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const group = useSelector(selectGroup(id))

  const [isLoading, setIsLoading] = useState(false)

  const handleDelete = () =>
    dispatch(removeGroup(id))
      .unwrap()
      .then(() => {
        enqueueSnackbar('Deletion successful', {
          variant: 'success'
        })
        navigate('/users/groups', { replace: true })
      })
      .catch((err) =>
        enqueueSnackbar('Deletion failed', {
          variant: 'error'
        })
      )

  useEffect(() => {
    setIsLoading(true)
    dispatch(loadGroup(id))
      .unwrap()
      .catch((err) => {
        console.log(err)
      })
      .finally(() => setIsLoading(false))
  }, [])

  return (
    <Page loading={isLoading}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          pl: { sm: 2 },
          py: 1.5,
          pr: { xs: 1, sm: 1 },
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`
        }}
      >
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link
            component={RouterLink}
            underline="hover"
            color="inherit"
            to="/users/groups/"
          >
            Groups
          </Link>
          {group && (
            <Typography color="text.primary">
              {group.id || 'Unknown'}
            </Typography>
          )}
        </Breadcrumbs>
        <Tooltip title="Delete">
          <Button color="error" onClick={handleDelete}>
            Delete
          </Button>
        </Tooltip>
      </Box>
      {group && <GroupDetail group={group} />}
    </Page>
  )
}

GroupDetailView.propTypes = {}

export default GroupDetailView
