import { createAsyncThunk } from '@reduxjs/toolkit'
import { normalize } from 'normalizr'

import {
  deleteUser,
  fetchAuthenticatedUser,
  fetchUser,
  fetchUsers,
  patchUser,
  postUser,
  putUserPassword
} from '../../../api/user'
import UserSchema from './schema'

export const loadUsers = createAsyncThunk(
  'users/fetchMany',
  async (queryParams, { rejectWithValue }) => {
    try {
      const response = await fetchUsers(queryParams)
      response.normalized = normalize(response.data, [UserSchema])
      return response
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const loadUser = createAsyncThunk(
  'users/fetchOne',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await fetchUser(userId)
      response.normalized = normalize(response.data, UserSchema)
      return response
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const createUser = createAsyncThunk(
  'users/create',
  async (data, { rejectWithValue }) => {
    try {
      const response = await postUser(data)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const editUser = createAsyncThunk(
  'users/edit',
  async ({ id, ...data }, { rejectWithValue }) => {
    try {
      const response = await patchUser(id, data)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const editUserPassword = createAsyncThunk(
  'users/password/edit',
  async ({ id, password }, { rejectWithValue }) => {
    try {
      const response = await putUserPassword(id, password)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const removeUser = createAsyncThunk(
  'users/delete',
  async (userId, { rejectWithValue }) => {
    try {
      await deleteUser(userId)
      return userId
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const loadAuthenticatedUser = createAsyncThunk(
  'users/fetchAuthUser',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchAuthenticatedUser()
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)
