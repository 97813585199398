import {
  createEntityAdapter,
  createSelector,
  createSlice
} from '@reduxjs/toolkit'

import {
  createComment,
  editComment,
  loadComment,
  loadComments,
  removeComment
} from './api'

export const commentsAdapter = createEntityAdapter()

const slice = createSlice({
  name: 'comments',
  initialState: commentsAdapter.getInitialState({
    view: {
      search: null,
      page: 0,
      order: 'asc',
      orderBy: 'id'
    }
  }),
  reducers: {
    setPage(state, action) {
      state.view.page = action.payload
    },
    setSearch(state, action) {
      state.view.search = action.payload
    },
    setOrder(state, action) {
      state.view.order = action.payload
    },
    setOrderBy(state, action) {
      state.view.orderBy = action.payload
    }
  },
  extraReducers: {
    [loadComments.fulfilled]: (state, action) => {
      const {
        normalized: { entities }
      } = action.payload
      commentsAdapter.upsertMany(state, entities.comments ?? {})
    },
    [loadComments.rejected]: (state, action) => {
      console.log(action)
    },
    [loadComment.fulfilled]: (state, action) => {
      commentsAdapter.upsertOne(state, action.payload)
    },
    [loadComment.rejected]: (state, action) => {
      console.log(action)
    },
    [createComment.fulfilled]: (state, action) => {
      commentsAdapter.addOne(state, action.payload)
    },
    [createComment.rejected]: (state, action) => {
      console.log(action)
    },
    [editComment.fulfilled]: (state, action) => {
      commentsAdapter.upsertOne(state, action.payload)
    },
    [editComment.rejected]: (state, action) => {
      console.log(action)
    },
    [removeComment.fulfilled]: (state, action) => {
      commentsAdapter.removeOne(state, action.payload)
    },
    [removeComment.rejected]: (state, action) => {
      console.log(action)
    }
  }
})

export const selectCommentEntities = (state) => {
  return state.comments
}

export const { selectById } = commentsAdapter.getSelectors(
  selectCommentEntities
)

export const selectComment = (id) => (state) => selectById(state, id)

export const getCommentsViewProperties = createSelector(
  selectCommentEntities,
  (state) => state.view
)

export const {
  setPage: setCommentsPage,
  setSearch: setCommentsSearch,
  setOrder: setCommentsOrder,
  setOrderBy: setCommentsOrderBy
} = slice.actions

const reducer = slice.reducer
export default reducer
