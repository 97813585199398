import { Checkbox, FormControlLabel, TextField } from '@mui/material'
import DialogContentText from '@mui/material/DialogContentText'
import { useFormik } from 'formik'
import produce from 'immer'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as yup from 'yup'

import ResponsiveDialog from '../../../../components/ResponsiveDialog'
import { selectCommonType } from '../slice'

function CommonTypeDialog({
  category,
  commonTypeId,
  open,
  onClose,
  onSubmit,
  ...props
}) {
  const [initialValues, setInitialValues] = useState({})
  const commonType = useSelector(selectCommonType(commonTypeId))

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: yup.object().shape({
      name: yup.string('Enter type name').required('Name is required')
    }),
    onSubmit: (values) => {
      handleSubmit(values)
    }
  })

  const handleSubmit = (values) => {
    onSubmit(
      produce(values, (draft) => {
        if (commonTypeId != null) {
          draft.id = commonTypeId
        }
        draft.category = category
        return draft
      })
    )
    formik.resetForm()
    onClose()
  }

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  useEffect(
    () =>
      setInitialValues({
        name: commonType?.name ?? '',
        is_active: commonType?.is_active ?? false
      }),
    [commonType]
  )

  return (
    <ResponsiveDialog
      title={`${commonTypeId ? 'Edit' : 'New'} ${category}`}
      open={open}
      onClose={handleClose}
      onSubmit={() => formik.submitForm()}
      {...props}
    >
      <DialogContentText>
        Please enter all required details here.
      </DialogContentText>
      <TextField
        required
        fullWidth
        name="name"
        type="text"
        label="Name"
        size="small"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
      />
      <FormControlLabel
        name="is_active"
        label="Active"
        control={<Checkbox checked={formik.values.is_active} />}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
    </ResponsiveDialog>
  )
}

CommonTypeDialog.propTypes = {
  category: PropTypes.string.isRequired,
  commonTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default CommonTypeDialog
