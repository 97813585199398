import { Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

import { ActivityStatus } from '../../../../components'

export const UsersTableColumns = [
  {
    id: 'id',
    label: 'ID',
    sortable: true,
    style: { width: '100px' },
    cellRenderer: (datum, row, rowIndex) => {
      return (
        <Button component={Link} to={`/users/${row.id}/`}>
          {datum}
        </Button>
      )
    }
  },
  {
    id: 'first_name',
    label: 'First Name',
    sortable: true
  },
  {
    id: 'last_name',
    label: 'Last Name',
    sortable: true
  },
  {
    id: 'email',
    label: 'E-mail Address'
  },
  {
    id: 'is_active',
    label: 'Active',
    sortable: true,
    cellRenderer: (datum) => <ActivityStatus active={Boolean(datum)} />
  }
]
