import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'

import callBacksReducer from '../features/apps/calendar/slice'
import commonTypesReducer from '../features/apps/common-types/slice'
import addressReducer from '../features/apps/organizations/addresses/slice'
import commentReducer from '../features/apps/organizations/comments/slice'
import contactReducer from '../features/apps/organizations/contacts/slice'
import deliveryReducer from '../features/apps/organizations/deliveries/slice'
import organizationReducer from '../features/apps/organizations/slice'
import groupReducer from '../features/apps/users/groups/slice'
import userReducer from '../features/apps/users/slice'
import authReducer from '../features/auth/slice'
import globalsReducer from './globals/slice'

export const history = createBrowserHistory()

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(routerMiddleware(history)),
  reducer: combineReducers({
    router: connectRouter(history),
    globals: globalsReducer,
    auth: authReducer,
    organizations: organizationReducer,
    commonTypes: commonTypesReducer,
    callBacks: callBacksReducer,
    users: userReducer,
    comments: commentReducer,
    deliveries: deliveryReducer,
    contacts: contactReducer,
    addresses: addressReducer,
    groups: groupReducer
  })
})
