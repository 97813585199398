import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import {
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  alpha,
  useMediaQuery
} from '@mui/material'
import { Box } from '@mui/system'
import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import { Link, matchPath, useLocation } from 'react-router-dom'

import { useApp } from '../../app/context/AppContext'
import MenuDivider from '../MenuDivider'

export function SideBarItem({ active, menu, childRenderer, onClick }) {
  const [isOpen, setIsOpen] = useState(false)

  const hasChildren = menu.items != null && menu.items.length > 0

  return (
    <Fragment key={`${menu.name}-frag`}>
      <ListItem
        button
        component={menu.route != null ? Link : 'div'}
        to={menu.route}
        onClick={() => {
          if (hasChildren) {
            setIsOpen(!isOpen)
          } else {
            if (onClick) {
              onClick()
            }
          }
        }}
        sx={{
          bgcolor: (theme) =>
            active ? theme.palette.action.selected : theme.palette.text
        }}
      >
        <ListItemIcon
          sx={{
            color: (theme) =>
              active ? theme.palette.primary.main : theme.palette.text.secondary
          }}
        >
          {menu.icon}
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={
            <Typography
              variant="button"
              sx={{
                color: (theme) =>
                  active ? theme.palette.primary.main : theme.palette.text
              }}
            >
              {menu.name}
            </Typography>
          }
        />
        {hasChildren ? isOpen ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItem>
      {hasChildren ? (
        <Collapse
          in={isOpen}
          timeout="auto"
          unmountOnExit
          style={{ paddingLeft: '24px' }}
        >
          {menu.items.map((child) => {
            childRenderer(child)
          })}
        </Collapse>
      ) : null}
    </Fragment>
  )
}

SideBarItem.propTypes = {
  active: PropTypes.bool,
  menu: PropTypes.object,
  childRenderer: PropTypes.func,
  onClick: PropTypes.func
}

function SideBar({ open, width, items }) {
  const location = useLocation()
  const { colorMode, toggleSideBar } = useApp()

  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const drawerWidth = isMobile ? '100vw' : width || '240px'

  const renderMenu = (menu) => {
    if (menu.section != null) {
      return (
        <Fragment key={`${menu.section}-frag`}>
          <MenuDivider text={menu.section} />
          {menu.items.map((child) => renderMenu(child))}
        </Fragment>
      )
    }

    const testMatchPath = matchPath(menu.route, location.pathname)
    const isItemActive = menu.route != null ? Boolean(testMatchPath) : false

    return (
      <SideBarItem
        key={`${menu.name}-${menu.route}`}
        active={isItemActive}
        menu={menu}
        childRenderer={renderMenu}
        onClick={() => {
          if (isMobile) {
            toggleSideBar(false)
          }
        }}
      />
    )
  }

  return (
    <Drawer
      variant={isTablet ? 'temporary' : 'persistent'}
      anchor="left"
      open={open}
      sx={{
        flexShrink: 0
      }}
      PaperProps={{
        sx: {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: (theme) =>
            colorMode === 'dark'
              ? alpha(theme.palette.background.paper, 0.5)
              : theme.palette.background.paper,
          ...(colorMode === 'dark'
            ? { backdropFilter: 'saturate(180%) blur(20px)' }
            : {})
        }
      }}
    >
      <Box sx={{ padding: '10px', overflow: 'auto', marginTop: '64px' }}>
        <List
          sx={{
            '& .MuiListItemIcon-root': {
              minWidth: '36px'
            },
            '& .MuiButtonBase-root': {
              borderRadius: '8px',
              marginBottom: '8px',
              px: 1,
              py: 0.5,
              ':last-child': {
                marginBottom: 0
              }
            }
          }}
        >
          {items.map((item) => renderMenu(item))}
        </List>
      </Box>
    </Drawer>
  )
}

SideBar.propTypes = {
  open: PropTypes.bool,
  width: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object)
}

export default SideBar
