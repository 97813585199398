import { createAsyncThunk } from '@reduxjs/toolkit'
import { normalize } from 'normalizr'

import {
  deleteComment,
  fetchComment,
  fetchComments,
  patchComment,
  postComment
} from '../../../../api/comment'
import CommentSchema from './schema'

export const loadComments = createAsyncThunk(
  'organizations/comments/fetchMany',
  async (queryParams, { rejectWithValue }) => {
    try {
      const response = await fetchComments(queryParams)
      response.normalized = normalize(response.data, [CommentSchema])
      return response
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const loadComment = createAsyncThunk(
  'organizations/comments/fetchOne',
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetchComment(id)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const createComment = createAsyncThunk(
  'organizations/comments/create',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await postComment(payload)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const editComment = createAsyncThunk(
  'organizations/comments/patch',
  async ({ id, ...data }, { rejectWithValue }) => {
    try {
      const response = await patchComment(id, data)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const removeComment = createAsyncThunk(
  'organizations/comments/delete',
  async (commentId, { rejectWithValue }) => {
    try {
      await deleteComment(commentId)
      return commentId
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)
