import CloseIcon from '@mui/icons-material/Close'
import {
  DialogActions,
  DialogContent,
  Stack,
  useMediaQuery
} from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />
})

function ResponsiveDialog({
  title,
  open,
  sx = {
    '& .MuiDialogContent-root > :not(style):not(.MuiDialogContentText-root)': {
      mt: 2
    },
    '& .MuiDialogContent-root': { py: 4 }
  },
  children,
  extraActions,
  maxWidth = 'sm',
  submitText = 'Save',
  canSubmit,
  onClose,
  onSubmit
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      if (onSubmit && canSubmit == null ? true : canSubmit) {
        onSubmit()
      }
    }
  }

  useEffect(() => {
    if (open) {
      document.addEventListener('keyup', handleKeyUp)
    } else {
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [open])

  return (
    <Dialog
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      fullWidth
      maxWidth={maxWidth}
      fullScreen={isMobile}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h4" color="inherit">
            {title}
          </Typography>
          {isMobile && onSubmit && (
            <Button autoFocus color="inherit" onClick={onSubmit}>
              {submitText}
            </Button>
          )}
        </Toolbar>
      </AppBar>

      <DialogContent dividers>{children}</DialogContent>
      {!isMobile && (
        <DialogActions
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: extraActions ? 'space-between' : 'end'
          }}
        >
          {extraActions ? <Stack direction="row">{extraActions}</Stack> : null}
          <Stack direction="row">
            <Button color="inherit" onClick={onClose}>
              Cancel
            </Button>
            {onSubmit && (
              <Button
                autoFocus
                disabled={canSubmit == null ? false : !canSubmit}
                color="primary"
                onClick={onSubmit}
              >
                {submitText}
              </Button>
            )}
          </Stack>
        </DialogActions>
      )}
    </Dialog>
  )
}

ResponsiveDialog.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  sx: PropTypes.array,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.object
  ]),
  extraActions: PropTypes.arrayOf(PropTypes.element),
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  submitText: PropTypes.string,
  canSubmit: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
}

export default ResponsiveDialog
