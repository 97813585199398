import { Box, Checkbox, TableSortLabel } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { visuallyHidden } from '@mui/utils'
import PropTypes from 'prop-types'
import React from 'react'

function EnhancedTableHead(props) {
  const {
    showActions = false,
    showCheckBoxColumn = false,
    order,
    orderBy,
    numSelected,
    rowCount,
    columns,
    onSelectAllClick,
    onRequestSort
  } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {showCheckBoxColumn && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all'
              }}
            />
          </TableCell>
        )}
        {columns.map((headCell, index) => {
          const orderByKey = headCell.param ? headCell.param : headCell.id
          return (
            <TableCell
              key={`${headCell.id}-${orderByKey}-${index}`}
              align={headCell.align ?? 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === orderByKey ? order : false}
              style={headCell.style ?? {}}
            >
              {headCell.sortable || false ? (
                <TableSortLabel
                  active={orderBy === orderByKey}
                  direction={orderBy === orderByKey ? order : 'asc'}
                  onClick={createSortHandler(orderByKey)}
                >
                  {headCell.label}
                  {orderBy === orderByKey ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                <span>{headCell.label}</span>
              )}
            </TableCell>
          )
        })}
        {showActions && <TableCell padding="normal" align="right"></TableCell>}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  editable: PropTypes.bool,
  showActions: PropTypes.bool,
  showCheckBoxColumn: PropTypes.bool,
  numSelected: PropTypes.number,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.object),
  onSelectAllClick: PropTypes.func,
  onRequestSort: PropTypes.func
}

export default EnhancedTableHead
