import { createTheme } from '@mui/material/styles'

// assets
import colors from '../../assets/scss/_themes-vars.module.scss'
// project imports
import componentStyleOverrides from './components'
import themePalette from './palette'
import themeTypography from './typography'

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = (customization) => {
  const color = colors

  const isLightMode = (customization.mode ?? 'light') === 'light'

  const themeOption = {
    colors: color,
    navbar: isLightMode ? color.primary800 : color.darkTopBarMain,
    heading: isLightMode ? color.grey900 : color.darkTextTitle,
    paper: isLightMode ? color.paper : color.darkPaper,
    backgroundDefault: isLightMode ? color.background : color.darkBackground,
    background: isLightMode ? color.background : color.darkBackground,
    darkTextPrimary: isLightMode ? color.grey700 : color.darkTextPrimary,
    darkTextSecondary: isLightMode ? color.grey500 : color.darkTextSecondary,
    textDark: isLightMode ? color.grey900 : color.darkTextPrimary,
    menuSelected: isLightMode ? color.secondaryDark : color.darkSecondaryDark,
    menuSelectedBack: isLightMode
      ? color.secondaryLight
      : color.darkSecondaryLight,
    divider: color.grey200,
    customization
  }

  const themeOptions = {
    direction: 'ltr',
    palette: themePalette(themeOption),
    mixins: {
      toolbar: {
        minHeight: '48px',
        '@media (min-width: 600px)': {
          minHeight: '32px'
        }
      }
    },
    typography: themeTypography(themeOption)
  }

  const themes = createTheme(themeOptions)
  themes.components = componentStyleOverrides(themeOption)

  return themes
}

export default theme
