/* eslint-disable react/prop-types */
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { TextField } from '@mui/material'
import DialogContentText from '@mui/material/DialogContentText'
import { format } from 'date-fns'
import { useFormik } from 'formik'
import produce from 'immer'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as yup from 'yup'

import { DEFAULT_DATE_FORMAT } from '../../../../../app/constants'
import ProductAutocomplete from '../../../../../components/ProductAutocomplete'
import ResponsiveDialog from '../../../../../components/ResponsiveDialog'
import { selectProducts } from '../../../common-types/slice'
import { selectDelivery } from '../slice'

function DeliveryDialog({
  organizationId,
  open,
  deliveryId,
  onSubmit,
  onClose,
  ...props
}) {
  const products = useSelector(selectProducts)
  const delivery = useSelector(selectDelivery(deliveryId))

  const [initialValues, setInitialValues] = useState({})

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: yup.object({
      product: yup.string('Select product').required('Product is required'),
      delivery_date: yup
        .string('Enter delivery date')
        .required('Delivery date is required'),
      depart_time: yup
        .string('Enter delivery date')
        .required('Delivery date is required'),
      pickup_time: yup
        .string('Enter delivery date')
        .required('Delivery date is required')
    }),
    onSubmit: (values) => {
      handleSubmit(values)
    }
  })

  const handleSubmit = (values) => {
    onSubmit(
      produce(values, (draft) => {
        if (deliveryId != null) {
          draft.id = deliveryId
        }
        draft.organization = organizationId
        draft.delivery_date = format(draft.delivery_date, DEFAULT_DATE_FORMAT)
        return draft
      })
    )
    formik.resetForm()
    onClose()
  }

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  useEffect(
    () =>
      setInitialValues({
        product: deliveryId ? delivery.product : '',
        delivery_date: deliveryId ? delivery.delivery_date : null,
        depart_time: deliveryId ? delivery.depart_time : null,
        pickup_time: deliveryId ? delivery.pickup_time : null
      }),
    [delivery]
  )

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ResponsiveDialog
        title={`${deliveryId ? 'Edit' : 'New'} Delivery Log Entry`}
        open={open}
        onClose={handleClose}
        onSubmit={() => {
          formik.submitForm()
        }}
        {...props}
      >
        <DialogContentText>
          To add a new log entry, please enter all required details here.
        </DialogContentText>
        <ProductAutocomplete
          fullWidth
          name="product"
          label="Product"
          value={formik.values.product}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.product && Boolean(formik.errors.product)}
        />
        <DatePicker
          name="delivery_date"
          label="Delivery Date"
          value={formik.values.delivery_date}
          renderInput={(params) => (
            <TextField fullWidth size="small" {...params} />
          )}
          onChange={(value) => formik.setFieldValue('delivery_date', value)}
          onBlur={formik.handleBlur}
          error={
            formik.touched.delivery_date && Boolean(formik.errors.delivery_date)
          }
        />
        <DateTimePicker
          name="depart_time"
          label="Depart Time"
          value={formik.values.depart_time}
          renderInput={(params) => (
            <TextField fullWidth size="small" {...params} />
          )}
          onChange={(value) => formik.setFieldValue('depart_time', value)}
          onBlur={formik.handleBlur}
          error={
            formik.touched.depart_time && Boolean(formik.errors.depart_time)
          }
        />
        <DateTimePicker
          name="pickup_time"
          label="Pickup Time"
          value={formik.values.pickup_time}
          renderInput={(params) => (
            <TextField fullWidth size="small" {...params} />
          )}
          onChange={(value) => formik.setFieldValue('pickup_time', value)}
          onBlur={formik.handleBlur}
          error={
            formik.touched.pickup_time && Boolean(formik.errors.pickup_time)
          }
        />
      </ResponsiveDialog>
    </LocalizationProvider>
  )
}

DeliveryDialog.propTypes = {
  onSubmit: PropTypes.func
}

export default DeliveryDialog
