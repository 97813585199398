import apiClient from '../http-common'

export const ADDRESS_ENDPOINT = '/address'

export const fetchAddresses = (queryParams) =>
  apiClient
    .get(`${ADDRESS_ENDPOINT}/`, { params: queryParams })
    .then((res) => res.data)

export const fetchAddress = (addressId) =>
  apiClient.get(`${ADDRESS_ENDPOINT}/${addressId}/`).then((res) => res.data)

export const postAddress = (data) =>
  apiClient.post(`${ADDRESS_ENDPOINT}/`, data).then((res) => res.data)

export const patchAddress = (addressId, data) =>
  apiClient
    .patch(`${ADDRESS_ENDPOINT}/${addressId}/`, data)
    .then((res) => res.data)

export const deleteAddress = (addressId) =>
  apiClient.delete(`${ADDRESS_ENDPOINT}/${addressId}/`)
