import { createAsyncThunk } from '@reduxjs/toolkit'
import { normalize } from 'normalizr'

import {
  deleteCallBack,
  fetchCallBack,
  fetchCallBacks,
  patchCallBack,
  postCallBack
} from '../../../api/call-back'
import CallBackSchema from './schema'

export const loadCallBacks = createAsyncThunk(
  'calendar/callBacks/fetchMany',
  async (queryParams, { rejectWithValue }) => {
    try {
      const response = await fetchCallBacks(queryParams)
      response.normalized = normalize(response.data, [CallBackSchema])
      return response
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const loadCallBack = createAsyncThunk(
  'calendar/callBacks/fetchOne',
  async (callBackId, { rejectWithValue }) => {
    try {
      const response = await fetchCallBack(callBackId)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const createCallBack = createAsyncThunk(
  'calendar/call-backs/create',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await postCallBack(payload)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const editCallBack = createAsyncThunk(
  'calendar/callBacks/patch',
  async ({ id, ...data }, { rejectWithValue }) => {
    try {
      const response = await patchCallBack(id, data)
      return response.data
    } catch (e) {
      rejectWithValue(e.response.data)
    }
  }
)

export const removeCallBack = createAsyncThunk(
  'calendar/callBacks/delete',
  async (callBackId, { rejectWithValue }) => {
    try {
      await deleteCallBack(callBackId)
      return callBackId
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)
