import {
  createEntityAdapter,
  createSelector,
  createSlice
} from '@reduxjs/toolkit'
import _ from 'lodash'

import {
  createUser,
  editUser,
  loadAuthenticatedUser,
  loadUser,
  loadUsers,
  removeUser
} from './api'

const usersAdapter = createEntityAdapter()

const slice = createSlice({
  name: 'users',
  initialState: usersAdapter.getInitialState({
    view: {
      search: null,
      page: 0,
      order: 'asc',
      orderBy: 'id'
    }
  }),
  reducers: {
    setPage(state, action) {
      state.view.page = action.payload
    },
    setSearch(state, action) {
      state.view.search = action.payload
    },
    setOrder(state, action) {
      state.view.order = action.payload
    },
    setOrderBy(state, action) {
      state.view.orderBy = action.payload
    }
  },
  extraReducers: {
    [loadUsers.fulfilled]: (state, action) => {
      const {
        normalized: { entities }
      } = action.payload
      usersAdapter.upsertMany(state, entities.users ?? {})
    },
    [loadUsers.rejected]: (state, action) => {
      console.log(action)
    },
    [loadUser.fulfilled]: (state, action) => {
      const {
        normalized: { entities }
      } = action.payload
      usersAdapter.upsertOne(state, Object.values(entities.users)[0])
    },
    [loadUser.rejected]: (state, action) => {
      console.log(action)
    },
    [createUser.fulfilled]: (state, action) => {
      usersAdapter.addOne(state, action.payload)
    },
    [createUser.rejected]: (state, action) => {
      console.log(action)
    },
    [editUser.fulfilled]: (state, action) => {
      usersAdapter.upsertOne(state, action.payload)
    },
    [editUser.rejected]: (state, action) => {
      console.log(action)
    },
    [removeUser.fulfilled]: (state, action) => {
      usersAdapter.removeOne(state, action.payload)
    },
    [removeUser.rejected]: (state, action) => {
      console.log(action)
    },
    [loadAuthenticatedUser.fulfilled]: (state, action) => {
      usersAdapter.upsertOne(state, action.payload)
    }
  }
})

export const selectUserEntities = (state) => {
  return state.users
}

const { selectById } = usersAdapter.getSelectors(selectUserEntities)

export const selectUser = (id) => (state) => selectById(state, id)

export const selectUsers = () =>
  createSelector(selectUserEntities, (state) =>
    Object.values(state.entities).reduce((p, c) => {
      p[c.id] = c
      return p
    }, {})
  )

export const selectUserPermissions = (state, id) =>
  _.get(selectById(state, id), 'permissions', [])

export const selectUserHasPermission = (state, id, permissionName) =>
  selectUserPermissions(state, id).includes(permissionName)

export const selectIsUserSuperUser = (state, id) =>
  _.get(selectById(state, id), 'is_superuser', false)

export const selectUsersViewProperties = createSelector(
  selectUserEntities,
  (state) => state.view
)

export const {
  setPage: setUsersPage,
  setSearch: setUsersSearch,
  setOrder: setUsersOrder,
  setOrderBy: setUsersOrderBy
} = slice.actions

const reducer = slice.reducer
export default reducer
