/* eslint-disable react/prop-types */
import { LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import DialogContentText from '@mui/material/DialogContentText'
import { useFormik } from 'formik'
import produce from 'immer'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as yup from 'yup'

import ProductAutocomplete from '../../../../components/ProductAutocomplete'
import ResponsiveDialog from '../../../../components/ResponsiveDialog'
import UserAutocomplete from '../../../../components/UserAutocomplete'
import { selectOrganizationProduct } from '../slice'

function OrganizationProductDialog({
  organizationId,
  open,
  productId,
  onSubmit,
  onClose,
  ...props
}) {
  const product = useSelector(selectOrganizationProduct(productId))

  const [initialValues, setInitialValues] = useState({
    user: '',
    product: ''
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: yup.object({
      product: yup.string('Select product').required('Product is required')
    }),
    onSubmit: (values) => {
      handleSubmit(values)
    }
  })

  const handleSubmit = (values) => {
    onSubmit(
      produce(values, (draft) => {
        if (productId != null) {
          draft.id = productId
        }
        draft.organization = organizationId
        return draft
      })
    )
    formik.resetForm()
    onClose()
  }

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  useEffect(() => {
    setInitialValues({
      product: productId ? (product != null ? product.product : '') : '',
      user: product != null ? product.user : ''
    })
  }, [productId, open])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ResponsiveDialog
        title={`${productId ? 'Edit' : 'New'} Organization Product`}
        open={open}
        onClose={handleClose}
        onSubmit={() => {
          formik.submitForm()
        }}
        {...props}
      >
        <DialogContentText>
          To add a product, please enter all required details here.
        </DialogContentText>
        <UserAutocomplete
          fullWidth
          name="user"
          label="User"
          value={formik.values.user}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.user && Boolean(formik.errors.user)}
        />
        <ProductAutocomplete
          fullWidth
          name="product"
          label="Product"
          value={formik.values.product}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.product && Boolean(formik.errors.product)}
        />
      </ResponsiveDialog>
    </LocalizationProvider>
  )
}

OrganizationProductDialog.propTypes = {
  onSubmit: PropTypes.func
}

export default OrganizationProductDialog
