import { createSelector, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

import { loadGlobals } from './api'

const slice = createSlice({
  name: 'globals',
  initialState: {
    loaded: false,
    data: null
  },
  reducers: {},
  extraReducers: {
    [loadGlobals.fulfilled]: (state, action) => {
      state.data = action.payload
      state.loaded = true
    },
    [loadGlobals.rejected]: (state, action) => {
      console.log(action)
    }
  }
})

export const selectGlobals = (state) => {
  return state.globals
}

export const selectGlobalsData = createSelector(
  selectGlobals,
  (state) => state.data ?? null
)

const reducer = slice.reducer
export default reducer
