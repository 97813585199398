import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import DialogContentText from '@mui/material/DialogContentText'
import { useFormik } from 'formik'
import produce from 'immer'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as yup from 'yup'

import ResponsiveDialog from '../../../../components/ResponsiveDialog'
import { selectAuthenticatedUser } from '../../../auth/slice'
import {
  selectLeadSources,
  selectOrganizationTypes
} from '../../common-types/slice'
import { selectOrganization } from '../slice'

function OrganizationDialog({
  organizationId,
  open,
  onSubmit,
  onClose,
  ...props
}) {
  const organizationTypes = useSelector(selectOrganizationTypes)
  const organization = useSelector(selectOrganization(organizationId))
  const leadSources = useSelector(selectLeadSources)
  const user = useSelector(selectAuthenticatedUser)

  const [initialValues, setInitialValues] = useState({})

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: yup.object({
      name: yup.string('Enter organization name').required('Name is required'),
      org_type: yup.number().required(),
      lead_source: yup.number().required(),
      group_size: yup
        .number('Enter group size')
        .min(0, 'Group size should be 0 or more')
        .required('Group size is required'),
      user: yup.number().nullable()
    }),
    onSubmit: (values) => {
      handleSubmit(values)
    }
  })

  const handleSubmit = (values) => {
    onSubmit(
      produce(values, (draft) => {
        if (organizationId != null) {
          draft.id = organizationId
        }
        draft.user = user.id
        return draft
      })
    )
    handleClose()
  }

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  useEffect(() => {
    setInitialValues({
      name: organizationId ? organization?.name : '',
      org_type: organizationId ? organization?.org_type : '',
      group_size: organizationId ? organization?.group_size : 0,
      lead_source: organizationId ? organization?.lead_source : '',
      user: organizationId ? organization?.user : ''
    })
  }, [organization])

  return (
    <ResponsiveDialog
      title={`${organizationId ? 'Edit' : 'New'} Organization`}
      open={open}
      onClose={handleClose}
      onSubmit={() => {
        formik.submitForm()
      }}
      {...props}
    >
      <DialogContentText>
        To {organizationId ? 'edit this' : 'add a new'} organization, please
        enter all required details here.
      </DialogContentText>
      <input name="id" type="hidden" value={formik.values.id} />

      <FormControl required fullWidth size="small">
        <InputLabel id="org-type-select-label">Organization Type</InputLabel>
        <Select
          labelId="org-type-select-label"
          name="org_type"
          label="Organization Type"
          value={formik.values.org_type}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.org_type && Boolean(formik.errors.org_type)}
        >
          {Object.values(organizationTypes).map((o, index) => (
            <MenuItem key={index} value={o.id}>
              {o.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        required
        fullWidth
        name="name"
        type="text"
        label="Name"
        size="small"
        value={formik.values.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
      />
      <TextField
        required
        fullWidth
        name="group_size"
        type="number"
        label="Group Size"
        size="small"
        value={formik.values.group_size}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.group_size && Boolean(formik.errors.group_size)}
        helperText={formik.touched.group_size && formik.errors.group_size}
      />
      <FormControl required fullWidth size="small">
        <InputLabel id="lead-source-select-label">Lead Source</InputLabel>
        <Select
          labelId="lead-source-select-label"
          name="lead_source"
          label="Lead Source"
          value={formik.values.lead_source}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.lead_source && Boolean(formik.errors.lead_source)
          }
        >
          {Object.values(leadSources).map((o, index) => (
            <MenuItem key={index} value={o.id}>
              {o.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ResponsiveDialog>
  )
}

OrganizationDialog.propTypes = {
  open: PropTypes.bool,
  organizationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  organizationTypes: PropTypes.object,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
}

export default OrganizationDialog
