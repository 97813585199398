import { Box, Button, Typography } from '@mui/material'
import { PropTypes } from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

function Detail({ title, value, nullText, to }) {
  return (
    <Box sx={{ mb: 1 }}>
      <Typography
        variant="subtitle2"
        sx={{ color: (theme) => theme.palette.text.secondary }}
      >
        {title}
      </Typography>
      {to && value ? (
        <Link to={to}>{value}</Link>
      ) : (
        <Typography>
          {!value || value.toUpperCase() === 'NULL' ? nullText : value}
        </Typography>
      )}
    </Box>
  )
}

Detail.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.any,
  nullText: PropTypes.string,
  to: PropTypes.any
}

Detail.defaultProps = {
  nullText: 'None'
}

export default Detail
