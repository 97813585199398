import { createAsyncThunk } from '@reduxjs/toolkit'
import { normalize } from 'normalizr'

import {
  deleteAddress,
  fetchAddress,
  fetchAddresses,
  patchAddress,
  postAddress
} from '../../../../api/address'
import AddressSchema from './schema'

export const loadAddresses = createAsyncThunk(
  'organizations/address/fetchMany',
  async (queryParams, { rejectWithValue }) => {
    try {
      const response = await fetchAddresses(queryParams)
      response.normalized = normalize(response.data, [AddressSchema])
      return response
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const loadAddress = createAsyncThunk(
  'organizations/address/fetchOne',
  async (addressId, { rejectWithValue }) => {
    try {
      const response = await fetchAddress(addressId)
      response.normalzed = normalize(response.data, AddressSchema)
      return response
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const createAddress = createAsyncThunk(
  'organizations/address/create',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await postAddress(payload)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const editAddress = createAsyncThunk(
  'organizations/address/patch',
  async ({ id, ...data }, { rejectWithValue }) => {
    try {
      const response = await patchAddress(id, data)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const removeAddress = createAsyncThunk(
  'organizations/address/delete',
  async (addressId, { rejectWithValue }) => {
    try {
      await deleteAddress(addressId)
      return addressId
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)
