/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { SearchOff } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Checkbox,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import produce from 'immer'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'

import {
  getComparator,
  parseFiltersToActive,
  stableSort
} from '../../utils/table'
import EnhancedTableHead from './EnhancedTableHead'
import EnhancedTableToolbar from './EnhancedTableToolbar'
import { LoadingPanel } from '..'

function DynamicTable(
  {
    title,
    rows,
    columns,
    editable = false,
    selectable = false,
    hoverable = false,
    searchable = false,
    dateRange = false,
    deletable = false,
    fetchData,
    filters = [],
    initialPage,
    initialSearch,
    initialSelected,
    searchFields,
    defaultOrder = 'asc',
    defaultOrderBy,
    defaultRowsPerPage = 50,
    actions,
    footerRow,
    startDate,
    endDate,
    dateKeyword,
    onEdit,
    onDelete,
    onPageChange,
    onSelectedChange,
    onSortChange,
    onSearch,
    onRowsPerPageChange,
    onDateRangeChange,
    onActiveFiltersChange,
    onDataChange,
    ...tableProps
  },
  ref
) {
  const { enqueueSnackbar } = useSnackbar()

  const [data, setData] = useState(rows || [])
  const [totalCount, setTotalCount] = useState(data ? data.length : 0)
  const [isFetching, setIsFetching] = useState(false)
  const [selected, setSelected] = useState(initialSelected ?? [])
  const [order, setOrder] = useState(defaultOrder)
  const [orderBy, setOrderBy] = useState(defaultOrderBy)
  const [page, setPage] = useState(initialPage || 0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage || 50)
  const [search, setSearch] = useState(initialSearch || '')
  const [activeFilters, setActiveFilters] = useState(
    parseFiltersToActive(filters)
  )
  const [isInitialState, setIsInitialState] = useState(!!fetchData)

  const isFetchRequired = !!fetchData
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const formatSearchQuery = (text) => {
    return text === '' || !text ? '' : text.split(' ').join(',')
  }

  const loadData = () => {
    setIsFetching(true)

    const offset = page * rowsPerPage

    const fetchProps = {
      limit: rowsPerPage,
      offset: offset
    }

    if (dateRange) {
      fetchProps.dateRange = {
        field: dateKeyword,
        start: startDate,
        end: endDate
      }
    }

    if (orderBy && order) {
      fetchProps.ordering = (order === 'desc' ? '-' : '') + orderBy
    }

    if (searchable && search) {
      fetchProps.search = formatSearchQuery(search)
    }

    activeFilters.forEach((f) => {
      if (filters[f.index] === undefined) {
        return
      }

      const filterDefinition = filters[f.index]

      let urlKeyword = filterDefinition.id

      const param = _.get(filterDefinition, 'param', null)
      if (param != null) {
        urlKeyword = param
      }

      fetchProps[urlKeyword] = filterDefinition.getParamValue
        ? filterDefinition.getParamValue(f.value)
        : f.value

      if (fetchProps[urlKeyword] == null) {
        delete fetchProps[urlKeyword]
      }
    })

    return fetchData(fetchProps)
      .then((res) => {
        if (_.has(res, ['payload', 'meta', 'total_count'])) {
          setTotalCount(res.payload.meta.total_count)
        } else if (_.has(res, ['meta', 'total_count'])) {
          setTotalCount(res.meta.total_count)
        }

        if (_.has(res, 'data')) {
          setData(res.data)
        } else if (_.has(res, ['payload', 'data'])) {
          setData(res.payload.data)
        } else if (Array.isArray(res)) {
          setData(res)
        } else {
          setData([])
          setTotalCount(0)
          enqueueSnackbar('Failed to fetch table data', {
            variant: 'error'
          })
        }

        return data
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar('Failed to fetch table data', {
          variant: 'error'
        })
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  const handleSearch = (value) => {
    setSearch(value)
    if (onSearch) {
      onSearch(value)
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id)
      setSelected(newSelecteds)
    } else {
      setSelected([])
    }
  }

  const handleClick = (event, id, isCheckbox) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (!isCheckbox) {
      newSelected = [id]
    } else {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1))
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        )
      }
    }
    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleDelete = (ids) => {
    setSelected([])
    onDelete(ids)
  }

  const handleApplyFilters = (newFilters) => {
    setActiveFilters(
      produce(activeFilters, (draft) => {
        newFilters.forEach((f) => {
          if (draft.find((af) => af.index === f.index)) {
            draft[f.index].value = f.value
          } else {
            draft.push(f)
          }
        })
        return draft
      })
    )
  }

  useEffect(() => {
    if (search != null && !isInitialState) {
      if (isFetchRequired) {
        loadData()
      }
      if (onSearch) {
        onSearch(search)
      }
    }
  }, [search])

  useImperativeHandle(ref, () => ({
    loadData
  }))

  useEffect(() => {
    if (onDataChange) {
      onDataChange(data)
    }
  }, [data])

  useEffect(() => {
    if (isFetchRequired) {
      loadData()
    }
    if (isInitialState) {
      setIsInitialState(false)
    }
  }, [page, rowsPerPage, order, orderBy, startDate, endDate, activeFilters])

  useEffect(() => {
    if (onActiveFiltersChange) {
      onActiveFiltersChange(activeFilters)
    }
  }, [activeFilters])

  useEffect(() => {
    if (onRowsPerPageChange) {
      onRowsPerPageChange(rowsPerPage)
    }
  }, [rowsPerPage])

  useEffect(() => {
    if (onPageChange && !isInitialState) {
      onPageChange(page)
    }
  }, [page])

  useEffect(() => {
    if (onSortChange && !isInitialState) {
      onSortChange(order, orderBy)
    }
  }, [order, orderBy])

  useEffect(() => {
    if (onSelectedChange) {
      onSelectedChange(selected)
    }
  }, [selected])

  const isSelected = (id) => selected.indexOf(id) !== -1

  const tableData = isFetchRequired
    ? data
    : stableSort(data, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      )

  return (
    <Box sx={{ width: '100%' }}>
      <EnhancedTableToolbar
        title={title}
        editable={editable}
        searchable={searchable}
        dateRange={dateRange}
        deletable={deletable}
        selected={selected}
        search={search}
        filters={filters}
        actions={actions}
        startDate={startDate}
        endDate={endDate}
        allowsRefresh={isFetchRequired}
        onRefreshClick={loadData}
        onSearch={handleSearch}
        onApplyFilters={handleApplyFilters}
        onDateRangeChange={onDateRangeChange}
        onDelete={handleDelete}
      />
      {isFetching ? (
        <LoadingPanel containerSx={{ minHeight: 'calc(100vh - 160px)' }} />
      ) : (
        <TableContainer sx={{ position: 'relative' }}>
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={isDesktop ? 'small' : 'medium'}
            {...tableProps}
          >
            <EnhancedTableHead
              editable={editable}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              rowCount={tableData.length || 0}
              columns={columns}
              showActions={editable}
              showCheckBoxColumn={selectable}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {data.length > 0 ? (
                tableData.map((row, rowIndex) => {
                  const isItemSelected = isSelected(row.id)

                  return (
                    <TableRow
                      key={`${row.id}-${rowIndex}`}
                      selected={isItemSelected}
                      hover={hoverable}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                    >
                      {selectable && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            onClick={(event) =>
                              handleClick(event, row.id, true)
                            }
                            inputProps={{
                              'aria-labelledby': `enhanced-table-checkbox-${rowIndex}`
                            }}
                          />
                        </TableCell>
                      )}
                      {columns.map((column, columnIndex) => {
                        let value = row[column.id] || ''

                        if (typeof column.cellRenderer === 'function') {
                          value = column.cellRenderer(value, row, rowIndex)
                        }

                        return (
                          <TableCell
                            key={`${row.id}-${rowIndex}-${columnIndex}`}
                            align={column.align ?? 'left'}
                            onClick={(event) => {
                              if (hoverable && selectable) {
                                handleClick(event, row.id, false)
                              }
                            }}
                          >
                            {value}
                          </TableCell>
                        )
                      })}
                      {editable && onEdit && (
                        <TableCell align="right">
                          <Tooltip title="Edit">
                            <IconButton onClick={() => onEdit(row)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      )}
                    </TableRow>
                  )
                })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={
                      columns.length +
                      (selectable ? 1 : 0) +
                      (editable && onEdit ? 1 : 0)
                    }
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        p: 8
                      }}
                    >
                      <SearchOff fontSize="large" sx={{ mb: 2 }} />
                      <Typography variant="h7">No records found</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {footerRow}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {data.length > 0 && !isFetching && (
        <TablePagination
          sx={{
            '& p': {
              mt: 'unset',
              mb: 'unset'
            }
          }}
          showFirstButton
          showLastButton
          component="div"
          count={totalCount || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  )
}

const EnhancedTable = forwardRef(DynamicTable)

EnhancedTable.propTypes = {
  title: PropTypes.string,
  rows: PropTypes.array,
  columns: PropTypes.arrayOf(PropTypes.object),
  editable: PropTypes.bool,
  selectable: PropTypes.bool,
  hoverable: PropTypes.bool,
  searchable: PropTypes.bool,
  dateRange: PropTypes.bool,
  deletable: PropTypes.bool,
  fetchData: PropTypes.func,
  filters: PropTypes.arrayOf(PropTypes.object),
  initialPage: PropTypes.number,
  initialSearch: PropTypes.string,
  initialSelected: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  searchFields: PropTypes.arrayOf(PropTypes.string),
  defaultOrder: PropTypes.string,
  defaultOrderBy: PropTypes.string,
  defaultRowsPerPage: PropTypes.number,
  actions: PropTypes.arrayOf(PropTypes.element),
  footerRow: PropTypes.element,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  dateKeyword: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onPageChange: PropTypes.func,
  onSelectedChange: PropTypes.func,
  onSortChange: PropTypes.func,
  onSearch: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onDateRangeChange: PropTypes.func,
  onActiveFiltersChange: PropTypes.func,
  onDataChange: PropTypes.func
}

export default EnhancedTable
