import { TextField } from '@mui/material'
import DialogContentText from '@mui/material/DialogContentText'
import { useFormik } from 'formik'
import produce from 'immer'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as yup from 'yup'

import ResponsiveDialog from '../../../../components/ResponsiveDialog'
import { selectUser } from '../slice'

function UserDialog({ open, userId, onSubmit, onClose, ...props }) {
  const user = useSelector(selectUser(userId))

  const [initialValues, setInitialValues] = useState({})

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: yup.object({
      first_name: yup
        .string('Enter first name')
        .required('First name is required'),
      last_name: yup
        .string('Enter last name')
        .required('Last name is required'),
      email: yup
        .string('Enter e-mail address')
        .required('E-mail address is required')
    }),
    onSubmit: (values) => {
      handleSubmit(values)
    }
  })

  const handleSubmit = (values) => {
    onSubmit(
      produce(values, (draft) => {
        if (userId != null) {
          draft.id = userId
        }
        return draft
      })
    )
    formik.resetForm()
    onClose()
  }

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  useEffect(
    () =>
      setInitialValues({
        first_name: user?.first_name ?? '',
        last_name: user?.last_name ?? '',
        email: user?.email ?? ''
      }),
    [user]
  )

  return (
    <ResponsiveDialog
      title={`${userId ? 'Edit' : 'New'} User`}
      open={open}
      onClose={handleClose}
      onSubmit={() => {
        formik.submitForm()
      }}
      {...props}
    >
      <DialogContentText>
        To configure this user, please enter all required details here.
      </DialogContentText>
      <TextField
        autoFocus
        fullWidth
        name="first_name"
        label="First Name"
        type="text"
        variant="standard"
        value={formik.values.first_name}
        onChange={formik.handleChange}
        error={formik.touched.first_name && Boolean(formik.errors.first_name)}
        helperText={formik.touched.first_name && formik.errors.first_name}
      />
      <TextField
        autoFocus
        fullWidth
        name="last_name"
        label="Last Name"
        type="text"
        variant="standard"
        value={formik.values.last_name}
        onChange={formik.handleChange}
        error={formik.touched.last_name && Boolean(formik.errors.last_name)}
        helperText={formik.touched.last_name && formik.errors.last_name}
      />
      <TextField
        fullWidth
        label="E-mail Address"
        name="email"
        type="email"
        variant="standard"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
    </ResponsiveDialog>
  )
}

UserDialog.propTypes = {
  open: PropTypes.bool,
  userId: PropTypes.number,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
}

export default UserDialog
