import apiClient from '../http-common'

export const GROUP_ENDPOINT = '/group'

export const fetchGroups = (queryParams) =>
  apiClient
    .get(`${GROUP_ENDPOINT}/`, { params: queryParams })
    .then((res) => res.data)

export const fetchGroup = (groupId) =>
  apiClient.get(`${GROUP_ENDPOINT}/${groupId}/`).then((res) => res.data)

export const postGroup = (data) =>
  apiClient.post(`${GROUP_ENDPOINT}/`, data).then((res) => res.data)

export const patchGroup = (groupId, data) =>
  apiClient.patch(`${GROUP_ENDPOINT}/${groupId}/`, data).then((res) => res.data)

export const deleteGroup = (groupId) =>
  apiClient.delete(`${GROUP_ENDPOINT}/${groupId}/`)
