import React from 'react'

import { LoadingPanel } from '../../../components'
import tokenService from '../../../services/token'

function Logout() {
  setTimeout(() => {
    tokenService.logout()
    window.location.href = '/login'
  }, 1000)

  return <LoadingPanel />
}

export default Logout
