import apiClient from '../http-common'

export const CALLBACK_ENDPOINT = '/call-back'

export const fetchCallBacks = (queryParams) =>
  apiClient
    .get(`${CALLBACK_ENDPOINT}/`, { params: queryParams })
    .then((res) => res.data)

export const fetchCallBack = (callBackId) =>
  apiClient.get(`${CALLBACK_ENDPOINT}/${callBackId}/`).then((res) => res.data)

export const postCallBack = (data) =>
  apiClient.post(`${CALLBACK_ENDPOINT}/`, data).then((res) => res.data)

export const patchCallBack = (callBackId, data) =>
  apiClient
    .patch(`${CALLBACK_ENDPOINT}/${callBackId}/`, data)
    .then((res) => res.data)

export const deleteCallBack = (callBackId) =>
  apiClient.delete(`${CALLBACK_ENDPOINT}/${callBackId}/`)
