import { createAsyncThunk } from '@reduxjs/toolkit'
import { normalize } from 'normalizr'

import {
  deleteOrganization,
  deleteOrganizationProduct,
  fetchOrganization,
  fetchOrganizationProduct,
  fetchOrganizationProducts,
  fetchOrganizations,
  patchOrganization,
  patchOrganizationProduct,
  postOrganization,
  postOrganizationProduct
} from '../../../api/organization'
import OrganizationSchema, { OrganizationProductSchema } from './schema'

export const loadOrganizations = createAsyncThunk(
  'organizations/fetchMany',
  async (queryParams, { rejectWithValue }) => {
    try {
      const response = await fetchOrganizations(queryParams)
      response.normalized = normalize(response.data, [OrganizationSchema])
      return response
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const loadOrganization = createAsyncThunk(
  'organizations/fetchOne',
  async (organizationId, { rejectWithValue }) => {
    try {
      const response = await fetchOrganization(organizationId)
      response.normalized = normalize(response.data, OrganizationSchema)
      return response
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const createOrganization = createAsyncThunk(
  'organizations/create',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await postOrganization(payload)
      response.normalized = normalize(response.data, OrganizationSchema)
      return response
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const editOrganization = createAsyncThunk(
  'organizations/patch',
  async ({ id, ...data }, { rejectWithValue }) => {
    try {
      const response = await patchOrganization(id, data)
      response.normalized = normalize(response.data, OrganizationSchema)
      return response
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const removeOrganization = createAsyncThunk(
  'organizations/delete',
  async (organizationId, { rejectWithValue }) => {
    try {
      await deleteOrganization(organizationId)
      return organizationId
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const loadOrganizationProducts = createAsyncThunk(
  'organizations/products/fetchMany',
  async (queryParams, { rejectWithValue }) => {
    try {
      const response = await fetchOrganizationProducts(queryParams)
      response.normalized = normalize(response.data, [
        OrganizationProductSchema
      ])
      return response
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const loadOrganizationProduct = createAsyncThunk(
  'organizations/products/fetchOne',
  async (organizationProductId, { rejectWithValue }) => {
    try {
      const response = await fetchOrganizationProduct(organizationProductId)
      response.normalized = normalize(response.data, OrganizationProductSchema)
      return response
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const createOrganizationProduct = createAsyncThunk(
  'organizations/products/create',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await postOrganizationProduct(payload)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const editOrganizationProduct = createAsyncThunk(
  'organizations/products/patch',
  async ({ id, ...data }, { rejectWithValue }) => {
    try {
      const response = await patchOrganizationProduct(id, data)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const removeOrganizationProduct = createAsyncThunk(
  'organizations/products/delete',
  async (organizationProductId, { rejectWithValue }) => {
    try {
      await deleteOrganizationProduct(organizationProductId)
      return organizationProductId
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)
