import axios from 'axios'

import { store } from './app/store'
import { refreshToken } from './features/auth/api'
import tokenService from './services/token'

const setupAxiosInterceptors = (onUnauthenticated) => {
  const client = axios.create({
    baseURL: 'https://portal.janddfundraising.com/api',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json'
    },
    responseType: 'json'
  })

  client.interceptors.request.use(
    (config) => {
      const token = tokenService.accessToken
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  client.interceptors.response.use(
    (response) => {
      return response
    },
    async function (error) {
      const originalRequest = error.config
      switch (error.response.status) {
        case 401:
        case 403:
          if (error.config.url.includes('/auth/refresh')) {
            tokenService.logout()
            window.location.href = '/login'
          }
          if (
            tokenService.refreshToken &&
            tokenService.refreshToken !== 'undefined'
          ) {
            if (!originalRequest._retry) {
              originalRequest._retry = true
              tokenService.idToken = null
              await onUnauthenticated()
              return client(originalRequest)
            }
          }

          return Promise.reject(error)
        default:
          return Promise.reject(error)
      }
    }
  )

  return client
}

const apiClient = setupAxiosInterceptors(() =>
  store.dispatch(refreshToken(tokenService.refreshToken))
)

export default apiClient
