/* eslint-disable react/prop-types */
import { DateTimePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField
} from '@mui/material'
import DialogContentText from '@mui/material/DialogContentText'
import { formatISO } from 'date-fns'
import { useFormik } from 'formik'
import produce from 'immer'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import * as yup from 'yup'

import ProductAutocomplete from '../../../../../components/ProductAutocomplete'
import ResponsiveDialog from '../../../../../components/ResponsiveDialog'
import { selectAuthenticatedUser } from '../../../../auth/slice'
import { selectProducts } from '../../../common-types/slice'
import { selectComment } from '../slice'

function CallLogDialog({
  organizationId,
  open,
  callLogId,
  onSubmit,
  onClose,
  ...props
}) {
  const products = useSelector(selectProducts)
  const user = useSelector(selectAuthenticatedUser)
  const callLog = useSelector(selectComment(callLogId))

  const [initialValues, setInitialValues] = useState({})

  const generateValidationSchema = () => {
    if (callLogId) {
      return yup.object({
        product: yup.string('Select product').required('Product is required'),
        call_time: yup
          .string('Enter a valid future time')
          .required('Call time is required'),
        text: yup
          .string('Enter the call comment')
          .required('Call comment is required')
      })
    }

    return yup.object({
      product: yup.string('Select product').required('Product is required'),
      call_date: yup
        .string('Enter a valid future time')
        .required('Call time is required'),
      text: yup
        .string('Enter the call comment')
        .required('Call comment is required'),
      is_urgent: yup.bool(),
      call_type: yup.number().required('Call type is required')
    })
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: generateValidationSchema(),
    onSubmit: (values) => {
      handleSubmit(values)
    }
  })

  const handleSubmit = (values) => {
    onSubmit(
      produce(values, (draft) => {
        if (callLogId != null) {
          draft.id = callLogId
        }
        draft.organization = organizationId
        draft.user = user.id
        return draft
      })
    )
    formik.resetForm()
    onClose()
  }

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  useEffect(() => {
    if (callLogId) {
      setInitialValues({
        product: callLog?.product?.id || '',
        call_time: callLog?.call_time || formatISO(new Date()),
        text: callLog?.text || ''
      })
    } else {
      setInitialValues({
        product: '',
        call_date: formatISO(new Date()),
        text: '',
        is_urgent: false,
        call_type: 0
      })
    }
  }, [callLog])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ResponsiveDialog
        title={`${callLog ? 'Edit' : 'New'} Call Log Entry`}
        open={open}
        onClose={handleClose}
        onSubmit={() => {
          formik.submitForm()
        }}
        {...props}
      >
        <DialogContentText>
          To add a new call log entry, please enter all required details here.
        </DialogContentText>
        {callLogId ?? (
          <ProductAutocomplete
            fullWidth
            name="product"
            label="Product"
            products={Object.values(products)}
            value={formik.values.product}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.product && Boolean(formik.errors.product)}
          />
        )}
        {callLogId ? (
          <DateTimePicker
            name="call_time"
            label="Call Time"
            value={formik.values.call_time}
            renderInput={(params) => (
              <TextField fullWidth size="small" {...params} />
            )}
            onChange={(value) => formik.setFieldValue('call_time', value)}
            onBlur={formik.handleBlur}
            error={formik.touched.call_time && Boolean(formik.errors.call_time)}
          />
        ) : (
          <DateTimePicker
            name="call_date"
            label="Next Call Time"
            value={formik.values.call_date}
            renderInput={(params) => (
              <TextField fullWidth size="small" {...params} />
            )}
            onChange={(value) => formik.setFieldValue('call_date', value)}
            onBlur={formik.handleBlur}
            error={
              formik.touched.call_datet && Boolean(formik.errors.call_date)
            }
          />
        )}
        {!callLog ? (
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <FormControl>
              <FormLabel id="call-type-label">Call Type</FormLabel>
              <RadioGroup
                row
                aria-labelledby="call-type-label"
                name="call_type"
                value={formik.values.call_type}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label="Call Back"
                />
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="Appointment"
                />
              </RadioGroup>
            </FormControl>
            <FormControlLabel
              control={
                <Switch
                  name="is_urgent"
                  checked={formik.values.is_urgent}
                  onChange={formik.handleChange}
                />
              }
              label="Urgent"
            />
          </Stack>
        ) : null}
        <TextField
          required
          multiline
          fullWidth
          name="text"
          type="text"
          label="Call Comment"
          size="small"
          rows={5}
          value={formik.values.text}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.text && Boolean(formik.errors.text)}
          helperText={formik.touched.text && formik.errors.text}
        />
      </ResponsiveDialog>
    </LocalizationProvider>
  )
}

CallLogDialog.propTypes = {
  onSubmit: PropTypes.func
}

export default CallLogDialog
