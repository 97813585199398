import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Divider, Grid, Tab, Typography } from '@mui/material'
import { PropTypes } from 'prop-types'
import React, { useEffect, useState } from 'react'

import { Detail, LoadingPanel, StatusDetail } from '../../../../components'
import { formatUser } from '../utils'

function UserDetail({ user }) {
  const [tab, setTab] = useState('0')
  const [data, setData] = useState(null)

  useEffect(() => {
    setData(formatUser(user))
  }, [user])

  return data === null ? (
    <LoadingPanel />
  ) : (
    <TabContext value={tab}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList
          onChange={(event, newTab) => setTab(newTab)}
          aria-label="user tabs"
        >
          <Tab label="General" value="0" />
        </TabList>
      </Box>
      <TabPanel value="0">
        <Grid container spacing={4}>
          <Grid item md={4} xs={12}>
            <Typography variant="h6">Details</Typography>
            <Divider sx={{ my: 1 }} />
            <Detail title="First Name" value={data.first_name} />
            <Detail title="Last Name" value={data.last_name} />
            <Detail title="E-mail Address" value={data.email} />
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography variant="h6">Status</Typography>
            <Divider sx={{ my: 1 }} />
            <StatusDetail title="Active" active={data.is_active} />
            <StatusDetail title="Staff" active={data.is_staff} />
            <StatusDetail title="Consultant" active={data.is_consultant} />
            <StatusDetail title="Super Admin" active={data.is_superuser} />
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography variant="h6">Meta</Typography>
            <Divider sx={{ my: 1 }} />
            <Detail title="Last Login" value={data.last_login} />
            <Detail title="Created" value={data.created} />
            <Detail title="Updated" value={data.updated} />
          </Grid>
        </Grid>
      </TabPanel>
    </TabContext>
  )
}

UserDetail.propTypes = {
  user: PropTypes.object.isRequired
}

export default UserDetail
