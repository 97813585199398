import ArchiveIcon from '@mui/icons-material/Archive'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CloseIcon from '@mui/icons-material/Close'
import GridViewIcon from '@mui/icons-material/GridView'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import SettingsIcon from '@mui/icons-material/Settings'
import TodayIcon from '@mui/icons-material/Today'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import StaticDatePicker from '@mui/lab/StaticDatePicker'
import {
  Alert,
  Button,
  Collapse,
  Divider,
  Grid,
  Hidden,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
  useMediaQuery
} from '@mui/material'
import { add, format, getDaysInMonth, parse, sub } from 'date-fns'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { DEFAULT_DATE_FORMAT } from '../../app/constants.js'
import ToolbarSearchbar from './ToolbarSeachBar.jsx'

function SchedulerToolbar({
  // events data
  events,
  switchMode,
  initialDate,
  toolbarProps,
  // Mode
  onModeChange,
  onSearchResult,
  // Alert props
  alertProps,
  onAlertCloseButtonClicked,
  // Date
  onDateChange
}) {
  const [searchResult, setSearchResult] = useState()
  const [mode, setMode] = useState(switchMode)
  const [anchorMenuEl, setAnchorMenuEl] = useState(null)
  const [anchorDateEl, setAnchorDateEl] = useState(null)
  const [selectedDate, setSelectedDate] = useState(initialDate || new Date())
  const [daysInMonth, setDaysInMonth] = useState(getDaysInMonth(selectedDate))

  const openMenu = Boolean(anchorMenuEl)
  const openDateSelector = Boolean(anchorDateEl)

  const isTablet = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const commonIconButtonProps = {
    size: 'medium',
    edge: 'start',
    color: 'inherit',
    'aria-label': 'menu'
  }

  const menus = [
    {
      label: 'Read events',
      icon: <PlayCircleOutlineIcon fontSize="small" />
    },
    {
      label: 'Refresh',
      icon: <AutorenewIcon fontSize="small" />
    },
    {
      label: 'Export',
      icon: <ArchiveIcon fontSize="small" />
    },
    {
      label: 'Print',
      icon: <LocalPrintshopIcon fontSize="small" />
    }
  ]

  /**
   * @name handleOpenMenu
   * @description
   * @param event
   * @return void
   */
  // eslint-disable-next-line
  const handleOpenMenu = (event) => {
    setAnchorMenuEl(event.currentTarget)
  }

  /**
   * @name handleCloseMenu
   * @description
   * @return void
   */
  const handleCloseMenu = () => {
    setAnchorMenuEl(null)
  }

  /**
   * @name handleOpenDateSelector
   * @description
   * @param event
   * @return void
   */
  const handleOpenDateSelector = (event) => {
    setAnchorDateEl(event.currentTarget)
  }

  /**
   * @name handleCloseDateSelector
   * @description
   * @return void
   */
  const handleCloseDateSelector = () => {
    setAnchorDateEl(null)
  }

  /**
   * @name handleChangeDate
   * @description
   * @param method
   * @return void
   */
  const handleChangeDate = (method) => {
    if (typeof method !== 'function') return
    let options =
      mode === 'month'
        ? { months: 1 }
        : mode === 'week'
        ? { weeks: 1 }
        : { days: 1 }
    let newDate = method(selectedDate, options)
    setDaysInMonth(getDaysInMonth(newDate))
    setSelectedDate(newDate)
  }

  const handleCloseAlert = (e) => {
    onAlertCloseButtonClicked && onAlertCloseButtonClicked(e)
  }

  useEffect(() => {
    if (mode && onModeChange) {
      onModeChange(mode)
    }
    // eslint-disable-next-line
  }, [mode])

  useEffect(() => {
    onDateChange && onDateChange(daysInMonth, selectedDate)
    // eslint-disable-next-line
  }, [daysInMonth, selectedDate])

  useEffect(() => {
    onSearchResult && onSearchResult(searchResult)
    // eslint-disable-next-line
  }, [searchResult])

  return (
    <Toolbar
      sx={{
        position: 'fixed',
        width: '100%',
        height: '65px',
        py: 1,
        bgcolor: (theme) => theme.palette.background.paper,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`
      }}
    >
      <Grid
        container
        spacing={0}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <Grid item lg={2} md={3} sm={3} xs={1}>
          {toolbarProps.showDatePicker && (
            <Typography component="div" sx={{ display: 'flex' }}>
              <Hidden smDown>
                <IconButton
                  sx={{ ml: 0, mr: -0.1 }}
                  {...commonIconButtonProps}
                  onClick={() => handleChangeDate(sub)}
                >
                  <ChevronLeftIcon />
                </IconButton>
                <Button
                  size="small"
                  id="basic-button"
                  aria-haspopup="true"
                  //endIcon={<TodayIcon />}
                  aria-controls="basic-menu"
                  onClick={handleOpenDateSelector}
                  aria-expanded={openDateSelector ? 'true' : undefined}
                >
                  {format(
                    selectedDate,
                    mode === 'month'
                      ? isTablet
                        ? 'LLLL-yyyy'
                        : 'LLL-yyyy'
                      : 'PPP'
                  )}
                </Button>
                <IconButton
                  sx={{ ml: 0.2 }}
                  {...commonIconButtonProps}
                  onClick={() => handleChangeDate(add)}
                >
                  <ChevronRightIcon />
                </IconButton>
              </Hidden>
              <Hidden smUp>
                <IconButton
                  sx={{ ml: 0, 'aria-label': 'menu' }}
                  {...commonIconButtonProps}
                  size="small"
                  onClick={handleOpenDateSelector}
                >
                  <TodayIcon />
                </IconButton>
              </Hidden>
              <Menu
                id="date-menu"
                anchorEl={anchorDateEl}
                open={openDateSelector}
                onClose={handleCloseDateSelector}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    value={selectedDate}
                    onChange={(newValue) => {
                      setDaysInMonth(getDaysInMonth(newValue))
                      setSelectedDate(newValue)
                      handleCloseDateSelector()
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Menu>
            </Typography>
          )}
        </Grid>
        <Grid item lg={8} md={6} sm={8} xs={10}>
          {toolbarProps?.showSearchBar && (
            <ToolbarSearchbar
              events={events}
              onInputChange={(newValue) => {
                let newDate = newValue?.date
                  ? parse(newValue?.date, DEFAULT_DATE_FORMAT, initialDate)
                  : new Date()
                setDaysInMonth(getDaysInMonth(newDate))
                setSelectedDate(newDate)
                setSearchResult(newValue)
              }}
            />
          )}
        </Grid>
        <Grid item lg={2} md={3} sm={1} xs={1} sx={{ textAlign: 'right' }}>
          <Hidden mdUp>
            <IconButton
              sx={{ mr: 0, 'aria-label': 'menu' }}
              {...commonIconButtonProps}
              size="small"
              onClick={handleOpenDateSelector}
            >
              <GridViewIcon />
            </IconButton>
          </Hidden>
          <Hidden mdDown>
            {toolbarProps?.showSwitchModeButtons && (
              <ToggleButtonGroup
                exclusive
                value={mode}
                size="small"
                color="primary"
                sx={{ mr: 1.3 }}
                aria-label="text button group"
                onChange={(e, newMode) => {
                  setMode(newMode)
                }}
              >
                {['month', 'week', 'day'].map((tb) => (
                  <ToggleButton key={tb} value={tb}>
                    {tb}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            )}
          </Hidden>
          {/*toolbarProps?.showOptions &&
          <IconButton sx={{ ml: 1 }} onClick={handleOpenMenu}{...commonIconButtonProps}>
            <MoreVertIcon />
          </IconButton>*/}
        </Grid>
        {/* <Grid item lg={2} md={2} sm={4} xs={1}>
          <Menu
            id="menu-menu"
            open={openMenu}
            anchorEl={anchorMenuEl}
            onClose={handleCloseMenu}
            onClick={handleCloseMenu}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {menus.map((menu, index) => (
              <MenuItem key={menu.label}>
                <ListItemIcon>{menu.icon}</ListItemIcon>
                <Typography variant="body2">{menu.label}</Typography>
              </MenuItem>
            ))}
            <Divider />
            <MenuItem>
              <ListItemIcon>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="body2">Settings</Typography>
            </MenuItem>
          </Menu>
          <Collapse in={alertProps?.open}>
            <Alert
              color={alertProps?.color}
              severity={alertProps?.severity}
              sx={{ borderRadius: 0, mb: 0 }}
              action={
                alertProps?.showActionButton ? (
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleCloseAlert}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                ) : null
              }
            >
              {alertProps?.message}
            </Alert>
          </Collapse>
        </Grid> */}
      </Grid>
    </Toolbar>
  )
}

SchedulerToolbar.propTypes = {
  initialDate: PropTypes.object.isRequired,
  events: PropTypes.array.isRequired,
  switchMode: PropTypes.string.isRequired,
  alertProps: PropTypes.object,
  toolbarProps: PropTypes.object,
  onDateChange: PropTypes.func.isRequired,
  onModeChange: PropTypes.func.isRequired,
  onSearchResult: PropTypes.func.isRequired,
  onAlertCloseButtonClicked: PropTypes.func.isRequired
}

SchedulerToolbar.defaultProps = {
  alertProps: {
    open: false,
    message: '',
    color: 'info',
    severity: 'info',
    showActionButton: true
  },
  toolbarProps: {
    showSearchBar: true,
    showSwitchModeButtons: true,
    showDatePicker: true,
    showOptions: false
  }
}

export default SchedulerToolbar
