import { format, parseISO } from 'date-fns'
import produce from 'immer'

import { DEFAULT_DATETIME_FORMAT } from '../../../app/constants'

export function formatUser(user) {
  return produce(user, (draft) => {
    draft.created = draft.created
      ? format(parseISO(draft.created), DEFAULT_DATETIME_FORMAT)
      : null
    draft.updated = draft.updated
      ? format(parseISO(draft.updated), DEFAULT_DATETIME_FORMAT)
      : null
    draft.last_login = draft.last_login
      ? format(parseISO(draft.last_login), DEFAULT_DATETIME_FORMAT)
      : null
    return draft
  })
}
