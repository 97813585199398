import { createAsyncThunk } from '@reduxjs/toolkit'
import { normalize } from 'normalizr'

import {
  deleteDelivery,
  fetchDeliveries,
  fetchDelivery,
  patchDelivery,
  postDelivery
} from '../../../../api/delivery'
import DeliverySchema from './schema'

export const loadDeliveries = createAsyncThunk(
  'organizations/deliveries/fetchMany',
  async (queryParams, { rejectWithValue }) => {
    try {
      const response = await fetchDeliveries(queryParams)
      response.normalized = normalize(response.data, [DeliverySchema])
      return response
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const loadDelivery = createAsyncThunk(
  'organizations/deliveries/fetchOne',
  async (deliveryId, { rejectWithValue }) => {
    try {
      const response = await fetchDelivery(deliveryId)
      response.normalized = normalize(response.data, DeliverySchema)
      return response
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const createDelivery = createAsyncThunk(
  'organizations/deliveries/create',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await postDelivery(payload)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const editDelivery = createAsyncThunk(
  'organizations/deliveries/patch',
  async ({ id, ...data }, { rejectWithValue }) => {
    try {
      const response = await patchDelivery(id, data)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const removeDelivery = createAsyncThunk(
  'organizations/deliveries/delete',
  async (deliveryId, { rejectWithValue }) => {
    try {
      await deleteDelivery(deliveryId)
      return deliveryId
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)
