import { Box } from '@mui/material'
import { PropTypes } from 'prop-types'
import React from 'react'

import ActivityStatus from '../ActivityStatus'

function StatusDetail({ title, active }) {
  return <ActivityStatus active={active} text={title} />
}

StatusDetail.propTypes = {
  title: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired
}

export default StatusDetail
