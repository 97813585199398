import { Box, Paper, Typography, useMediaQuery } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

function EventItem({
  event,
  rowId,
  sx,
  boxSx,
  elevation,
  isMonthMode,
  onClick,
  onDragStart
}) {
  const isTablet = useMediaQuery((theme) => theme.breakpoints.up('md'))

  return (
    <Paper
      sx={sx}
      draggable
      onClick={onClick}
      onDragStart={onDragStart}
      elevation={elevation || 0}
      key={`item-d-${event?.id}-${rowId}`}
    >
      <Box sx={boxSx}>
        {isMonthMode && isTablet && (
          <Typography variant="caption" sx={{ fontSize: 10, mr: 1 }}>
            {event?.startHour}
          </Typography>
        )}
        <Typography variant="body2" sx={{ fontSize: 11 }}>
          {event?.label}
        </Typography>
      </Box>
    </Paper>
  )
}

EventItem.propTypes = {
  sx: PropTypes.object,
  boxSx: PropTypes.object,
  elevation: PropTypes.number,
  event: PropTypes.object.isRequired,
  rowId: PropTypes.number,
  isMonthMode: PropTypes.bool,
  onClick: PropTypes.func,
  handleTaskClick: PropTypes.func,
  onCellDragStart: PropTypes.func,
  onDragStart: PropTypes.func
}

export default EventItem
