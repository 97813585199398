import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  alpha,
  useMediaQuery
} from '@mui/material'
import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useApp } from '../../app/context/AppContext'

function AppSelect() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const { currentApp, availableApps, changeApp } = useApp()

  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState(null)
  const isOpen = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Fragment>
      <Button
        size="large"
        variant="text"
        disableElevation
        aria-controls="app-menu"
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        style={{
          color: 'white',
          paddingLeft: 18,
          paddingRight: 18,
          borderRadius: 50,
          display: 'flex',
          alignItems: 'center'
        }}
        sx={{
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.dark,
              theme.palette.mode === 'light' ? 0.75 : 0.2
            ),
          '& .MuiButton-startIcon': {
            mr: isMobile ? 0 : 1,
            ml: isMobile ? 0 : 0
          },
          '& .MuiButton-endIcon': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          },
          '& .MuiButton-endIcon svg': {
            color: 'rgba(255,255,255,0.8)',
            transition: '0.2s',
            transform: 'translateX(0) rotate(90)'
          },
          '&:hover': {
            '& .MuiButton-endIcon svg': {
              right: 0,
              opacity: 1
            }
          }
        }}
        startIcon={
          <Box
            sx={{
              width: 32,
              height: 32,
              borderRadius: '8px',
              bgcolor: currentApp.color
            }}
          >
            {currentApp.icon}
          </Box>
        }
        endIcon={
          <KeyboardArrowDownIcon
            sx={{ position: 'absolute', right: 4, opacity: 0 }}
          />
        }
      >
        {!isMobile && (
          <Typography variant="h5" color="inherit">
            {currentApp.name}
          </Typography>
        )}
      </Button>
      <Menu
        id="app-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        sx={{
          '& div': {
            // this is just an example, you can use vw, etc.
            minWidth: '100px'
          }
        }}
      >
        {availableApps.map((app) => (
          <MenuItem
            key={app.id}
            onClick={() => {
              setAnchorEl(null)
              changeApp(app)
              navigate(app.path, { replace: true })
            }}
          >
            <ListItemIcon>{app.icon}</ListItemIcon>
            <ListItemText>{app.name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  )
}

export default AppSelect
