import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { loadOrganization, loadOrganizations } from '../api'
import {
  createAddress,
  editAddress,
  loadAddress,
  loadAddresses,
  removeAddress
} from './api'

const addressAdapter = createEntityAdapter()

const slice = createSlice({
  name: 'address',
  initialState: addressAdapter.getInitialState(),
  reducers: {},
  extraReducers: {
    [loadAddresses.fulfilled]: (state, action) => {
      const {
        normalized: { entities }
      } = action.payload
      addressAdapter.upsertMany(state, entities.addresses ?? {})
    },
    [loadAddresses.rejected]: (state, action) => {
      console.log(action)
    },
    [loadAddress.fulfilled]: (state, action) => {
      const {
        normalized: { entities }
      } = action.payload
      addressAdapter.upsertMany(state, entities.addresses ?? {})
    },
    [loadAddress.rejected]: (state, action) => {
      console.log(action)
    },
    [createAddress.fulfilled]: (state, action) => {
      addressAdapter.addOne(state, action.payload)
    },
    [createAddress.rejected]: (state, action) => {
      console.log(action)
    },
    [editAddress.fulfilled]: (state, action) => {
      addressAdapter.upsertOne(state, action.payload)
    },
    [editAddress.rejected]: (state, action) => {
      console.log(action)
    },
    [removeAddress.fulfilled]: (state, action) => {
      addressAdapter.removeOne(state, action.payload)
    },
    [removeAddress.rejected]: (state, action) => {
      console.log(action)
    },
    [loadOrganizations.fulfilled]: (state, action) => {
      const {
        normalized: { entities }
      } = action.payload
      addressAdapter.upsertMany(state, entities.addresses ?? {})
    },
    [loadOrganization.fulfilled]: (state, action) => {
      const {
        normalized: { entities }
      } = action.payload
      addressAdapter.upsertMany(state, entities.addresses ?? {})
    }
  }
})

export const selectAddressEntities = (state) => {
  return state.addresses
}

export const { selectById } = addressAdapter.getSelectors(selectAddressEntities)

export const selectAddress = (id) => (state) => selectById(state, id)
export const selectAddresses = (ids) => (state) =>
  ids.map((id) => selectById(state, id))

const reducer = slice.reducer
export default reducer
