/* eslint-disable react/prop-types */
import AddIcon from '@mui/icons-material/Add'
import { Box, Button, IconButton, Link, Tooltip } from '@mui/material'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import EnhancedTable from '../../../../components/EnhancedTable'
import {
  createOrganizationProduct,
  editOrganizationProduct,
  loadOrganizationProducts,
  removeOrganizationProduct
} from '../api'
import OrganizationProductDialog from '../components/OrganizationProductDialog'

const columns = [
  {
    id: 'product',
    label: 'Name',
    minWidth: 170,
    sortable: true,
    cellRenderer: (datum) =>
      datum ? (
        <Link
          component={RouterLink}
          color="text.primary"
          to={`/common-types/products?selected=${datum.id}`}
        >
          {_.get(datum, 'name', 'Unknown')}
        </Link>
      ) : (
        'None'
      )
  },
  {
    id: 'user',
    label: 'User',
    minWidth: 170,
    sortable: true,
    cellRenderer: (datum) =>
      datum ? (
        <Link
          component={RouterLink}
          color="text.primary"
          to={`/users/${datum.id}`}
        >
          {datum != null ? `${datum.first_name} ${datum.last_name}` : 'None'}
        </Link>
      ) : (
        'None'
      )
  }
]

function OrganizationProductsTabView({ organizationId }) {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const table = useRef()

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [focusedId, setFocusedId] = useState(null)

  const getDataFetcher = () => (queryParams) =>
    dispatch(
      loadOrganizationProducts({
        organization: organizationId,
        is_active: true,
        ...queryParams
      })
    ).unwrap()

  const handleSubmit = (values) => {
    if (_.get(values, 'id', null) != null) {
      dispatch(editOrganizationProduct(values))
        .unwrap()
        .then(() => {
          enqueueSnackbar('Update successful', {
            variant: 'success'
          })
          table.current.loadData()
        })
        .catch(() => {
          enqueueSnackbar('Update failed', {
            variant: 'error'
          })
        })
    } else {
      dispatch(createOrganizationProduct(values))
        .unwrap()
        .then(() => {
          enqueueSnackbar('Creation successful', {
            variant: 'success'
          })
          table.current.loadData()
        })
        .catch(() => {
          enqueueSnackbar('Creation failed', {
            variant: 'error'
          })
        })
    }
  }

  const handleDelete = (id) => {
    dispatch(removeOrganizationProduct(id))
      .unwrap()
      .then(() => {
        if (isDialogOpen) {
          setIsDialogOpen(false)
        }
        enqueueSnackbar('Deletion successful', {
          variant: 'success'
        })
        table.current.loadData()
      })
      .catch(() => {
        enqueueSnackbar('Deletion failed', {
          variant: 'error'
        })
      })
  }

  return (
    <Box
      sx={{
        width: '100%',
        overflow: 'hidden'
      }}
    >
      <EnhancedTable
        ref={table}
        title="Products"
        editable
        selectable
        hoverable
        searchable
        columns={columns}
        fetchData={getDataFetcher()}
        actions={[
          <Tooltip key="new" title="Add New">
            <IconButton
              onClick={() => {
                setFocusedId(null)
                setIsDialogOpen(true)
              }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        ]}
        onEdit={(row) => {
          setFocusedId(row.id)
          setIsDialogOpen(true)
        }}
      />
      <OrganizationProductDialog
        organizationId={organizationId}
        open={isDialogOpen}
        productId={focusedId}
        onSubmit={handleSubmit}
        onClose={() => {
          setIsDialogOpen(false)
        }}
        extraActions={
          focusedId != null
            ? [
                <Button
                  key="delete"
                  color="error"
                  onClick={() => handleDelete(focusedId)}
                >
                  Delete
                </Button>
              ]
            : []
        }
      />
    </Box>
  )
}

export default OrganizationProductsTabView
