import { Drawer } from '@mui/material'
import { styled } from '@mui/material/styles'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'

import BottomDrawerBar from './BottomDrawerBar'

const openedMixin = () => ({
  overflowX: 'hidden'
})

const closedMixin = (theme) => ({
  visibility: 'visible!important',
  overflowX: 'hidden',
  height: `calc(${theme.spacing(6)} + 1px)`
})

const ResponsiveDrawer = styled(Drawer)(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))

const DrawerContent = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100%',
  overflow: 'hidden',
  overflowY: 'auto'
}))

function BottomDrawer({
  title,
  open,
  children,
  PaperStyle,
  onToggle,
  onHeightChange
}) {
  const [newHeight, setNewHeight] = useState({})
  const [isResizing, setIsResizing] = useState(false)
  const [lastDownY, setLastDownY] = useState(0)

  let paperHeight = !open ? 'auto' : _.get(newHeight, 'height', 'auto')

  const handleMouseDown = (e) => {
    setIsResizing(true)
    setLastDownY(e.clientY)
  }

  const handleMouseMove = useCallback(
    (e) => {
      // we don't want to do anything if we aren't resizing.
      if (!isResizing) {
        return
      }

      e.preventDefault()

      let offsetBottom =
        document.body.offsetHeight - (e.clientY - document.body.offsetTop)
      let minHeight = 160
      let maxHeight = Math.floor(window.innerHeight * 0.6)
      if (offsetBottom > minHeight && offsetBottom < maxHeight) {
        setNewHeight({ height: offsetBottom })
      } else if (offsetBottom < minHeight) {
        setNewHeight({ height: 160 })
      }
    },
    [isResizing]
  )

  const handleWindowResize = useCallback(() => {
    if (open) {
      let maxHeight = Math.floor(window.innerHeight * 0.6)

      if (newHeight.height > maxHeight) {
        setNewHeight({ height: maxHeight })
      }
    }
  }, [open, newHeight])

  const handleMouseUp = useCallback(() => {
    setIsResizing(false)
  }, [])

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp)
    return () => {
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [handleWindowResize])

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove)
    return () => document.removeEventListener('mousemove', handleMouseMove)
  }, [handleMouseMove])

  useEffect(() => {
    if (onHeightChange) {
      onHeightChange(newHeight ?? 160)
    }
  }, [newHeight])

  useEffect(() => {
    if (open) {
      if (newHeight.height ?? 0 < 160) {
        setNewHeight({ height: 160 })
      }
    }
  }, [open])

  return (
    <ResponsiveDrawer
      variant="permanent"
      anchor="bottom"
      elevation={2}
      open={open}
      ModalProps={{
        keepMounted: true
      }}
      PaperProps={{
        style: {
          position: 'fixed',
          overflow: 'hidden',
          height: paperHeight,
          ...PaperStyle
        }
      }}
    >
      <BottomDrawerBar
        title={title}
        open={open}
        onToggle={onToggle}
        onMouseDown={handleMouseDown}
      />
      {open && <DrawerContent>{children}</DrawerContent>}
    </ResponsiveDrawer>
  )
}

BottomDrawer.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  children: PropTypes.any,
  PaperStyle: PropTypes.object,
  onToggle: PropTypes.func,
  onHeightChange: PropTypes.func
}

export default BottomDrawer
