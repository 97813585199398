import apiClient from '../http-common'

export const DELIVERY_ENDPOINT = '/delivery'

export const fetchDeliveries = (queryParams) =>
  apiClient
    .get(`${DELIVERY_ENDPOINT}/`, { params: queryParams })
    .then((res) => res.data)

export const fetchDelivery = (deliveryId) =>
  apiClient.get(`${DELIVERY_ENDPOINT}/${deliveryId}/`).then((res) => res.data)

export const postDelivery = (data) =>
  apiClient.post(`${DELIVERY_ENDPOINT}/`, data).then((res) => res.data)

export const patchDelivery = (deliveryId, data) =>
  apiClient
    .patch(`${DELIVERY_ENDPOINT}/${deliveryId}/`, data)
    .then((res) => res.data)

export const deleteDelivery = (deliveryId) =>
  apiClient.delete(`${DELIVERY_ENDPOINT}/${deliveryId}/`)
