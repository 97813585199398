import './App.css'

import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import { SnackbarProvider, useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { AppProvider } from './app/context/AppContext'
import { history } from './app/store'
import { selectAuthenticatedUserAccess } from './features/auth/slice'
import AppRoutes from './router/AppRoutes'
import NavigationScroll from './router/NavigationScroll'

function SnackbarCloseButton({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar()

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <CloseIcon />
    </IconButton>
  )
}

function App() {
  const appAccess = useSelector(selectAuthenticatedUserAccess)

  return (
    <BrowserRouter history={history}>
      <AppProvider access={appAccess}>
        <SnackbarProvider
          action={(snackbarKey) => (
            <SnackbarCloseButton snackbarKey={snackbarKey} />
          )}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <NavigationScroll>
            <AppRoutes />
          </NavigationScroll>
        </SnackbarProvider>
      </AppProvider>
    </BrowserRouter>
  )
}

SnackbarCloseButton.propTypes = {
  snackbarKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default App
