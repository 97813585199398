import { CancelOutlined, CheckCircleOutlined } from '@mui/icons-material'
import { Box } from '@mui/material'
import { green, red } from '@mui/material/colors'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

const DEFAULT_ACTIVE_COLOR = green[400]
const DEFAULT_INACTIVE_COLOR = red[400]
const DEFAULT_ACTIVE_TEXT = 'Active'
const DEFAULT_INACTIVE_TEXT = 'Inactive'

const getDefaultActivityValue = (
  active,
  value,
  defaultActive,
  defaultInactive
) => {
  if (value instanceof Object) {
    return active
      ? _.get(value, 'active', defaultActive)
      : _.get(value, 'inactive', defaultInactive)
  } else {
    return active ? value || defaultActive : value || defaultInactive
  }
}

const ActivityStatus = ({ size, active, text, color }) => {
  const [statusText, setStatusText] = useState(null)
  const [statusColor, setStatusColor] = useState(null)

  const StatusIcon = active ? CheckCircleOutlined : CancelOutlined

  useEffect(() => {
    setStatusText(
      getDefaultActivityValue(
        active,
        text,
        DEFAULT_ACTIVE_TEXT,
        DEFAULT_INACTIVE_TEXT
      )
    )
    setStatusColor(
      getDefaultActivityValue(
        active,
        color,
        DEFAULT_ACTIVE_COLOR,
        DEFAULT_INACTIVE_COLOR
      )
    )
  }, [text, active, color])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        color: statusColor
      }}
    >
      <StatusIcon
        fontSize={size || 'small'}
        sx={{
          fill: statusColor,
          mr: 0.5
        }}
      />
      {statusText}
    </Box>
  )
}

ActivityStatus.defaultProps = {
  size: 'small',
  text: { active: DEFAULT_ACTIVE_TEXT, inactive: DEFAULT_INACTIVE_TEXT },
  color: { active: DEFAULT_ACTIVE_COLOR, inactive: DEFAULT_INACTIVE_COLOR }
}

ActivityStatus.propTypes = {
  size: PropTypes.string,
  active: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  color: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
}

export default ActivityStatus
