import { Button, Popover, Stack, useTheme } from '@mui/material'
import { format, parseISO } from 'date-fns'
import produce from 'immer'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DEFAULT_DATE_FORMAT } from '../../../../app/constants'
import { Detail, StatusDetail } from '../../../../components'
import Page from '../../../../components/Page'
import Scheduler from '../../../../components/Scheduler'
import { editCallBack, loadCallBacks } from '../api'
import {
  getCalendarViewProperties,
  selectCallBackEntities,
  setCalendarDate,
  setCalendarMode
} from '../slice'

function CalendarView() {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()

  const { date, mode } = useSelector(getCalendarViewProperties)

  const [eventEl, setEventEl] = useState(null)
  const [event, setEvent] = useState(null)
  const [events, setEvents] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false)

  const callBacks = useSelector(selectCallBackEntities)

  const [state, setState] = useState({
    options: {
      transitionMode: 'zoom', // or fade
      startWeekOn: 'Mon', // or Sun
      defaultMode: mode, // or week | day | timeline
      minWidth: 540,
      maxWidth: 540,
      minHeight: 540,
      maxHeight: 540
    },
    alertProps: {
      open: true,
      color: 'info', // info | success | warning | error
      severity: 'info', // info | success | warning | error
      message:
        '🚀 YA KNOW - ONE OF THESE DAYS... OHHHHH, ONE OF THESE DAYS! 🔥 🔥 🔥',
      showActionButton: true,
      showNotification: true,
      delay: 1500
    },
    toolbarProps: {
      showSearchBar: true,
      showSwitchModeButtons: true,
      showDatePicker: true
    }
  })

  const getDataFetcher = () => (startDate, endDate) => {
    return dispatch(
      loadCallBacks({
        params: {
          call_date__lte: format(endDate, DEFAULT_DATE_FORMAT),
          call_date__gte: format(startDate, DEFAULT_DATE_FORMAT),
          limit: 500
        }
      })
    ).then((res) => {
      return res.results
    })
  }

  useEffect(() => {
    setIsLoading(true)
    dispatch(loadCallBacks({ limit: 1000, ordering: '-call_date' }))
      .unwrap()
      .finally(() => {
        setIsLoading(false)
        setIsInitialLoadComplete(true)
      })
  }, [])

  useEffect(() => {
    if (isInitialLoadComplete) {
      const newEvents = Object.values(callBacks.entities).map(
        (event, index) => {
          return {
            id: event.id,
            index,
            label: event.organization_name,
            groupLabel: event.product_name,
            data: event,
            user: 'N/A',
            color: event.is_complete
              ? theme.palette.success.main
              : event.is_urgent
              ? theme.palette.error.main
              : theme.palette.primary.main,
            startHour: format(parseISO(event.call_date), 'HH:mm aa'),
            endHour: format(parseISO(event.call_date), 'HH:mm aa'),
            date: format(parseISO(event.call_date), DEFAULT_DATE_FORMAT),
            createdAt: format(parseISO(event.created), DEFAULT_DATE_FORMAT),
            createdBy: 'System'
          }
        }
      )

      setEvents(newEvents)
    }
  }, [isLoading, isInitialLoadComplete])

  const handleCellClick = (event, row, day) => {
    console.log('cell click')
  }

  const handleEventClick = (event, item) => {
    setEventEl(event.currentTarget)
    setEvent(item)
  }

  const handleEventPopoverClose = () => {
    setEventEl(null)
  }

  const handleEventsChange = (item) => {
    console.log('events changed')
  }

  const handleAlertCloseButtonClicked = (item) => {
    setState(
      produce(state, (draft) => {
        draft.alertProps.open = false
        return draft
      })
    )
  }

  const handleDateChange = (newDate) => {
    if (newDate instanceof Date) {
      dispatch(setCalendarDate(format(newDate, DEFAULT_DATE_FORMAT)))
    } else {
      dispatch(setCalendarDate(newDate))
    }
  }

  const handleModeChange = (newMode) => {
    dispatch(setCalendarMode(newMode))
    setState(
      produce(state, (draft) => {
        draft.options.defaultMode = newMode
      })
    )
  }

  return (
    <Page loading={isLoading}>
      <Scheduler
        events={events}
        initialDate={date}
        options={state.options}
        fetchData={getDataFetcher()}
        toolbarProps={state.toolbarProps}
        onModeChange={handleModeChange}
        onDateChange={handleDateChange}
        onEventsChange={handleEventsChange}
        onCellClick={handleCellClick}
        onTaskClick={handleEventClick}
        onAlertCloseButtonClicked={handleAlertCloseButtonClicked}
      />
      <Popover
        open={Boolean(eventEl)}
        anchorEl={eventEl}
        sx={{ p: 4 }}
        onClose={handleEventPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Stack sx={{ p: 2 }} spacing={2} direction="column">
          <Detail title="Organization" value={event?.data?.organization_name} />
          <Detail title="Call Date" value={event?.data?.call_date} />
          <Detail title="Call Type" value={event?.data?.call_type_name} />
          <StatusDetail title="Is Complete" active={event?.data?.is_complete} />
          <StatusDetail title="Is Urgent" active={event?.data?.is_urgent} />
        </Stack>
        <Stack spacing={2} direction="row">
          <Button
            color={event?.data?.is_complete === false ? 'success' : 'error'}
            onClick={() => {
              const markingComplete = event?.data?.is_complete === false
              dispatch(
                editCallBack({
                  id: event?.data?.id,
                  is_complete: markingComplete
                })
              )
                .unwrap()
                .then(() => {
                  setEventEl(null)
                  setIsLoading(true)
                  enqueueSnackbar(
                    `Marked ${markingComplete ? 'complete' : 'not complete'}!`,
                    {
                      variant: 'success'
                    }
                  )
                })
                .finally(() => {
                  setIsLoading(false)
                })
            }}
          >
            {event?.data?.is_complete === false
              ? 'Mark Complete'
              : 'Mark Not Complete'}
          </Button>
          <Button
            color="error"
            onClick={() => {
              const markingUrgent = event?.data?.is_urgent === false
              dispatch(
                editCallBack({ id: event?.data?.id, is_urgent: markingUrgent })
              )
                .unwrap()
                .then(() => {
                  setEventEl(null)
                  setIsLoading(true)
                  enqueueSnackbar(
                    `Marked ${markingUrgent ? 'urgent' : 'not urgent'}!`,
                    {
                      variant: 'success'
                    }
                  )
                })
                .finally(() => {
                  setIsLoading(false)
                })
            }}
          >
            {event?.data?.is_urgent === false
              ? 'Mark Urgent'
              : 'Mark Not Urgent'}
          </Button>
        </Stack>
      </Popover>
    </Page>
  )
}

export default CalendarView
