import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { loadOrganization, loadOrganizations } from '../api'
import {
  createContact,
  editContact,
  loadContact,
  loadContacts,
  removeContact
} from './api'

const contactsAdapter = createEntityAdapter()

const slice = createSlice({
  name: 'contacts',
  initialState: contactsAdapter.getInitialState(),
  reducers: {},
  extraReducers: {
    [loadContacts.fulfilled]: (state, action) => {
      const {
        normalized: { entities }
      } = action.payload
      contactsAdapter.upsertMany(state, entities.contacts ?? {})
    },
    [loadContacts.rejected]: (state, action) => {
      console.log(action)
    },
    [loadContact.fulfilled]: (state, action) => {
      const {
        normalized: { entities }
      } = action.payload
      contactsAdapter.upsertMany(state, entities.contacts ?? {})
    },
    [loadContact.rejected]: (state, action) => {
      console.log(action)
    },
    [createContact.fulfilled]: (state, action) => {
      contactsAdapter.addOne(state, action.payload)
    },
    [createContact.rejected]: (state, action) => {
      console.log(action)
    },
    [editContact.fulfilled]: (state, action) => {
      contactsAdapter.upsertOne(state, action.payload)
    },
    [editContact.rejected]: (state, action) => {
      console.log(action)
    },
    [removeContact.fulfilled]: (state, action) => {
      contactsAdapter.removeOne(state, action.payload)
    },
    [removeContact.rejected]: (state, action) => {
      console.log(action)
    },
    [loadOrganizations.fulfilled]: (state, action) => {
      const {
        normalized: { entities }
      } = action.payload
      contactsAdapter.upsertMany(state, entities.contacts ?? {})
    },
    [loadOrganization.fulfilled]: (state, action) => {
      const {
        normalized: { entities }
      } = action.payload
      contactsAdapter.upsertMany(state, entities.contacts ?? {})
    }
  }
})

export const selectContactEntities = (state) => {
  return state.contacts
}

export const { selectById } = contactsAdapter.getSelectors(
  selectContactEntities
)

export const selectContact = (id) => (state) => selectById(state, id)
export const selectContacts = (ids) => (state) =>
  ids.map((id) => selectById(state, id))

const reducer = slice.reducer
export default reducer
