/* eslint-disable react/prop-types */
import AddIcon from '@mui/icons-material/Add'
import { Button, IconButton, Link, Tooltip } from '@mui/material'
import { format, parse, parseISO } from 'date-fns'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import React, { Fragment, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import {
  DEFAULT_DATETIME_FORMAT,
  DEFAULT_DATE_FORMAT
} from '../../../../../app/constants'
import EnhancedTable from '../../../../../components/EnhancedTable'
import {
  createDelivery,
  editDelivery,
  loadDeliveries,
  removeDelivery
} from '../api'
import DeliveryDialog from '../components/DeliveryDialog'

const columns = [
  {
    id: 'delivery_date',
    label: 'Delivery Date',
    minWidth: 170,
    sortable: true,
    cellRenderer: (datum) =>
      format(parse(datum, 'yyyy-MM-dd', new Date()), DEFAULT_DATE_FORMAT)
  },
  {
    id: 'depart_time',
    label: 'Depart Time',
    minWidth: 100,
    sortable: true,
    cellRenderer: (datum) => format(parseISO(datum), DEFAULT_DATETIME_FORMAT)
  },
  {
    id: 'pickup_time',
    label: 'Pickup Time',
    minWidth: 100,
    sortable: true,
    cellRenderer: (datum) => format(parseISO(datum), DEFAULT_DATETIME_FORMAT)
  },
  {
    id: 'product',
    label: 'Product',
    minWidth: 170,
    align: 'right',
    cellRenderer: (datum) =>
      datum ? (
        <Link
          component={RouterLink}
          color="text.primary"
          to={`/common-types/products/${datum.id}`}
        >
          {_.get(datum, 'name', 'Unknown')}
        </Link>
      ) : (
        'None'
      )
  }
]

function DeliveryTabView({ organizationId }) {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const table = useRef()

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [focusedId, setFocusedId] = useState(null)

  const getDataFetcher = () => (queryParams) =>
    dispatch(
      loadDeliveries({ organization: organizationId, ...queryParams })
    ).unwrap()

  const handleSubmit = (values) => {
    if (_.get(values, 'id', null) != null) {
      dispatch(editDelivery(values))
        .unwrap()
        .then(() => {
          enqueueSnackbar('Update successful', {
            variant: 'success'
          })
          table.current.loadData()
        })
        .catch(() => {
          enqueueSnackbar('Update failed', {
            variant: 'error'
          })
        })
    } else {
      dispatch(createDelivery(values))
        .unwrap()
        .then(() => {
          enqueueSnackbar('Creation successful', {
            variant: 'success'
          })
          table.current.loadData()
        })
        .catch(() => {
          enqueueSnackbar('Creation failed', {
            variant: 'error'
          })
        })
    }
  }

  const handleDelete = (id) => {
    dispatch(removeDelivery(id))
      .unwrap()
      .then(() => {
        if (isDialogOpen) {
          setIsDialogOpen(false)
        }
        enqueueSnackbar('Deletion successful', {
          variant: 'success'
        })
        table.current.loadData()
      })
      .catch(() => {
        enqueueSnackbar('Deletion failed', {
          variant: 'error'
        })
      })
  }

  return (
    <Fragment>
      <EnhancedTable
        ref={table}
        title="Delivery Log"
        editable
        selectable
        hoverable
        searchable
        columns={columns}
        fetchData={getDataFetcher()}
        defaultOrder="desc"
        defaultOrderBy="delivery_date"
        actions={[
          <Tooltip key="new" title="Add New">
            <IconButton
              onClick={() => {
                setFocusedId(null)
                setIsDialogOpen(true)
              }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        ]}
        onEdit={(row) => {
          setFocusedId(row.id)
          setIsDialogOpen(true)
        }}
      />
      <DeliveryDialog
        organizationId={organizationId}
        open={isDialogOpen}
        deliveryId={focusedId}
        onSubmit={handleSubmit}
        onClose={() => {
          setIsDialogOpen(false)
        }}
        extraActions={
          focusedId != null
            ? [
                <Button
                  key="delete"
                  color="error"
                  onClick={() => handleDelete(focusedId)}
                >
                  Delete
                </Button>
              ]
            : []
        }
      />
    </Fragment>
  )
}

export default DeliveryTabView
