import {
  createEntityAdapter,
  createSelector,
  createSlice
} from '@reduxjs/toolkit'

import {
  createOrganization,
  createOrganizationProduct,
  editOrganization,
  editOrganizationProduct,
  loadOrganization,
  loadOrganizationProduct,
  loadOrganizationProducts,
  loadOrganizations,
  removeOrganization,
  removeOrganizationProduct
} from './api'

const organizationsAdapter = createEntityAdapter()
const organizationProductsAdapter = createEntityAdapter()

const slice = createSlice({
  name: 'organizations',
  initialState: organizationsAdapter.getInitialState({
    view: {
      search: null,
      page: 0,
      order: 'asc',
      orderBy: 'id',
      filters: {
        leadSource: -1,
        type: -1,
        isActive: 1,
        user: -1
      }
    },
    products: organizationProductsAdapter.getInitialState()
  }),
  reducers: {
    setPage(state, action) {
      state.view.page = action.payload
    },
    setSearch(state, action) {
      state.view.search = action.payload
    },
    setOrder(state, action) {
      state.view.order = action.payload
    },
    setOrderBy(state, action) {
      state.view.orderBy = action.payload
    },
    setIsActiveFilter(state, action) {
      state.view.filters.isActive = action.payload
    },
    setTypeFilter(state, action) {
      state.view.filters.type = action.payload
    },
    setLeadSourceFilter(state, action) {
      state.view.filters.leadSource = action.payload
    },
    setUserFilter(state, action) {
      state.view.filters.user = action.payload
    }
  },
  extraReducers: {
    [loadOrganizations.fulfilled]: (state, action) => {
      const {
        normalized: { entities }
      } = action.payload
      organizationsAdapter.upsertMany(state, entities.organizations ?? {})
    },
    [loadOrganizations.rejected]: (state, action) => {
      console.log(action)
    },
    [loadOrganization.fulfilled]: (state, action) => {
      const {
        normalized: { entities }
      } = action.payload
      const key = Object.keys(entities.organizations)[0]
      organizationsAdapter.upsertOne(state, entities.organizations[key])
    },
    [loadOrganization.rejected]: (state, action) => {
      console.log(action)
    },
    [createOrganization.fulfilled]: (state, action) => {
      organizationsAdapter.addOne(state, action.payload)
    },
    [createOrganization.rejected]: (state, action) => {
      console.log(action)
    },
    [editOrganization.fulfilled]: (state, action) => {
      const {
        normalized: { entities }
      } = action.payload
      organizationsAdapter.upsertOne(
        state,
        Object.values(entities.organizations)[0]
      )
    },
    [editOrganization.rejected]: (state, action) => {
      console.log(action)
    },
    [removeOrganization.fulfilled]: (state, action) => {
      organizationsAdapter.removeOne(state, action.payload)
    },
    [removeOrganization.rejected]: (state, action) => {
      console.log(action)
    },
    [loadOrganizationProducts.fulfilled]: (state, action) => {
      const {
        normalized: { entities }
      } = action.payload
      organizationProductsAdapter.upsertMany(
        state.products,
        entities.organizationProducts ?? {}
      )
    },
    [loadOrganizationProducts.rejected]: (state, action) => {
      console.log(action)
    },
    [loadOrganizationProduct.fulfilled]: (state, action) => {
      const {
        normalized: { entities }
      } = action.payload
      const key = Object.keys(entities.organizations.products)[0]
      organizationProductsAdapter.upsertOne(
        state.products,
        entities.organizationProducts[key]
      )
    },
    [loadOrganizationProduct.rejected]: (state, action) => {
      console.log(action)
    },
    [createOrganizationProduct.fulfilled]: (state, action) => {
      organizationProductsAdapter.addOne(state.products, action.payload)
    },
    [createOrganizationProduct.rejected]: (state, action) => {
      console.log(action)
    },
    [editOrganizationProduct.fulfilled]: (state, action) => {
      organizationProductsAdapter.upsertOne(state.products, action.payload)
    },
    [editOrganizationProduct.rejected]: (state, action) => {
      console.log(action)
    },
    [removeOrganizationProduct.fulfilled]: (state, action) => {
      organizationProductsAdapter.removeOne(state.products, action.payload)
    },
    [removeOrganizationProduct.rejected]: (state, action) => {
      console.log(action)
    }
  }
})

export const selectOrganizationEntities = (state) => state.organizations

export const { selectById } = organizationsAdapter.getSelectors(
  selectOrganizationEntities
)

export const selectOrganization = (id) => (state) => selectById(state, id)

export const getOrganizationsViewProperties = createSelector(
  selectOrganizationEntities,
  (state) => state.view
)

export const selectOrganizationProductEntities = (state) =>
  state.organizations.products

export const { selectById: selectOrganizationProductById } =
  organizationProductsAdapter.getSelectors(selectOrganizationProductEntities)

export const selectOrganizationProduct = (id) => (state) =>
  selectOrganizationProductById(state, id)

export const {
  setPage: setOrganizationsPage,
  setSearch: setOrganizationsSearch,
  setOrder: setOrganizationsOrder,
  setOrderBy: setOrganizationsOrderBy,
  setIsActiveFilter: setOrganizationIsActiveFilter,
  setTypeFilter: setOrganizationTypeFilter,
  setLeadSourceFilter: setOrganizationLeadSourceFilter,
  setUserFilter: setOrganizationUserFilter
} = slice.actions

export const getOrganizationsByType = (state, organizationType) =>
  Object.values(state.organizations).filter(
    (o) => o.org_type === organizationType
  )

const reducer = slice.reducer
export default reducer
