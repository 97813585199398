import DragHandleRoundedIcon from '@mui/icons-material/DragHandleRounded'
import PropTypes from 'prop-types'
import React from 'react'

function Dragger({ onMouseDown }) {
  return (
    <div
      id="dragger"
      style={{
        width: '100%',
        height: '5px',
        display: 'flex',
        alignItems: 'top',
        justifyContent: 'center',
        cursor: 'ns-resize',
        padding: '4px 0 0',
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        zIndex: '100'
      }}
      onMouseDown={(event) => {
        onMouseDown(event)
      }}
    >
      {open && <DragHandleRoundedIcon sx={{ position: 'absolute', top: 0 }} />}
    </div>
  )
}

Dragger.propTypes = {
  onMouseDown: PropTypes.func
}

export default Dragger
