import {
  createEntityAdapter,
  createSelector,
  createSlice
} from '@reduxjs/toolkit'

import { loadOrganization, loadOrganizations } from '../organizations/api'
import {
  createCommonType,
  editCommonType,
  loadCommonType,
  loadCommonTypes,
  removeCommonType
} from './api'

const commonTypesAdapter = createEntityAdapter()

const slice = createSlice({
  name: 'common-types',
  initialState: commonTypesAdapter.getInitialState({
    view: {
      category: 'Contact Prefixes',
      search: {},
      page: {},
      order: {},
      orderBy: {}
    }
  }),
  reducers: {
    setCategory(state, action) {
      state.view.category = action.payload
    },
    setPage(state, action) {
      const { pageKey, value } = action.payload
      state.view.page[pageKey] = value
    },
    setSearch(state, action) {
      const { pageKey, value } = action.payload
      state.view.search[pageKey] = value
    },
    setOrder(state, action) {
      const { pageKey, value } = action.payload
      state.view.order[pageKey] = value
    },
    setOrderBy(state, action) {
      const { pageKey, value } = action.payload
      state.view.orderBy[pageKey] = value
    }
  },
  extraReducers: {
    [loadCommonTypes.fulfilled]: (state, action) => {
      const {
        normalized: { entities }
      } = action.payload
      commonTypesAdapter.upsertMany(state, entities.commonTypes ?? {})
    },
    [loadCommonTypes.rejected]: (state, action) => {
      console.log(action)
    },
    [loadCommonType.fulfilled]: (state, action) => {
      commonTypesAdapter.upsertOne(state, action.payload.data)
    },
    [loadCommonType.rejected]: (state, action) => {
      console.log(action)
    },
    [createCommonType.fulfilled]: (state, action) => {
      commonTypesAdapter.addOne(state, action.payload)
    },
    [createCommonType.rejected]: (state, action) => {
      console.log(action)
    },
    [editCommonType.fulfilled]: (state, action) => {
      commonTypesAdapter.upsertOne(state, action.payload)
    },
    [editCommonType.rejected]: (state, action) => {
      console.log(action)
    },
    [removeCommonType.fulfilled]: (state, action) => {
      commonTypesAdapter.removeOne(state, action.payload)
    },
    [removeCommonType.rejected]: (state, action) => {
      console.log(action)
    },
    [loadOrganizations.fulfilled]: (state, action) => {
      const {
        normalized: { entities }
      } = action.payload
      commonTypesAdapter.upsertMany(state, entities.commonTypes ?? {})
    },
    [loadOrganization.fulfilled]: (state, action) => {
      const {
        normalized: { entities }
      } = action.payload
      commonTypesAdapter.upsertMany(state, entities.commonTypes ?? {})
    }
  }
})

export const selectCommonTypeEntities = (state) => state.commonTypes

export const { selectById } = commonTypesAdapter.getSelectors(
  selectCommonTypeEntities
)

export const selectCommonType = (id) => (state) => selectById(state, id)

const selectCommonTypeByCategory = (category) =>
  createSelector(selectCommonTypeEntities, (state) =>
    Object.values(state.entities).reduce((p, c) => {
      if (c.category === category) {
        p[c.id] = c
      }
      return p
    }, {})
  )

export const selectOrganizationTypes =
  selectCommonTypeByCategory('Organization Types')

export const selectLeadSources = selectCommonTypeByCategory('Lead Sources')

export const selectProducts = selectCommonTypeByCategory('Products')

export const selectPrefixes = selectCommonTypeByCategory('Contact Prefixes')

export const getCommonTypesViewProperties = createSelector(
  selectCommonTypeEntities,
  (state) => state.view
)

export const {
  setPage: setCommonTypesPage,
  setSearch: setCommonTypesSearch,
  setOrder: setCommonTypesOrder,
  setOrderBy: setCommonTypesOrderBy,
  setCategory: setCommonTypesCategory
} = slice.actions

const reducer = slice.reducer
export default reducer
