import { CancelOutlined, CheckCircleOutlined } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import { Chip, IconButton, Link, Stack, Tooltip } from '@mui/material'
import { green, red } from '@mui/material/colors'
import { format, parseISO } from 'date-fns'
import _ from 'lodash'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import { DEFAULT_DATETIME_FORMAT } from '../../../../app/constants'
import EnhancedTable from '../../../../components/EnhancedTable'
import { selectAuthenticatedUser } from '../../../auth/slice'
import { loadCallBacks } from '../../calendar/api'
import { loadUsers } from '../../users/api'
import { selectUserEntities } from '../../users/slice'
import { CallBackDialog } from '../call-backs/components'
import {
  getOrganizationsViewProperties,
  setOrganizationUserFilter
} from '../slice'

const columns = [
  {
    id: 'call_date',
    label: 'Date',
    style: {
      minWidth: '200px'
    },
    sortable: true,
    cellRenderer: (datum) => format(parseISO(datum), DEFAULT_DATETIME_FORMAT)
  },
  {
    id: 'organization',
    label: 'Organization',
    style: {
      minWidth: '200px'
    },
    cellRenderer: (datum, row, rowIndex) =>
      datum ? (
        <Link
          component={RouterLink}
          color="text.primary"
          to={`/organizations/${datum}`}
        >
          {_.get(row, 'organization_name', 'Unknown')}
        </Link>
      ) : (
        'None'
      )
  },
  {
    id: 'product',
    label: 'Product',
    style: {
      minWidth: '200px'
    },
    cellRenderer: (datum) =>
      datum ? (
        <Link
          component={RouterLink}
          color="text.primary"
          to={`/common-types/products?selected=${datum.id}`}
        >
          {_.get(datum, 'name', 'Unknown')}
        </Link>
      ) : (
        'None'
      )
  },
  {
    id: 'call_type',
    label: 'Type',
    style: {
      minWidth: '170px'
    },
    sortable: true,
    cellRenderer: (datum, row) => {
      return (
        <Stack direction="row" spacing={1}>
          <Chip size="small" label={row.call_type_name} />
          {row.is_urgent && <Chip size="small" color="error" label="Urgent" />}
          {row.is_complete && (
            <Chip size="small" color="success" label="Complete" />
          )}
        </Stack>
      )
    }
  },
  {
    id: 'is_complete',
    label: 'Status',
    style: {
      minWidth: '100px'
    },
    sortable: true,
    align: 'center',
    cellRenderer: (datum) => {
      const StatusIcon = datum ? CheckCircleOutlined : CancelOutlined
      return (
        <StatusIcon
          fontSize="small"
          sx={{
            fill: datum ? green[400] : red[400],
            mr: 0.5
          }}
        />
      )
    }
  }
]

function OrganizationCallBacksView() {
  const dispatch = useDispatch()

  const table = useRef()

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [focusedId, setFocusedId] = useState(null)
  const user = useSelector(selectAuthenticatedUser)
  const users = useSelector(selectUserEntities)

  useEffect(() => {
    dispatch(loadUsers())
  }, [])

  const getDataFetcher = () => (queryParams) =>
    dispatch(loadCallBacks({ ...queryParams })).unwrap()

  const { search, page, order, orderBy, filters } = useSelector(
    getOrganizationsViewProperties
  )

  return (
    <Fragment>
      <EnhancedTable
        ref={table}
        title="Call Backs"
        editable
        selectable
        hoverable
        searchable
        columns={columns}
        fetchData={getDataFetcher()}
        defaultOrderBy="call_date"
        defaultOrder="asc"
        onEdit={(row) => {
          setFocusedId(row.id)
          setIsDialogOpen(true)
        }}
        actions={[
          <Tooltip key="new" title="Add New">
            <IconButton
              onClick={() => {
                setFocusedId(null)
                setIsDialogOpen(true)
              }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        ]}
        filters={[
          {
            id: 'is_complete',
            group: 'Call Back Filters',
            label: 'Status',
            type: 'select',
            getParamValue: (option) => (option === 0 ? null : option === 1),
            options: [
              { label: 'All', value: 0 },
              { label: 'Complete', value: 1 },
              { label: 'Incomplete', value: 2 }
            ],
            defaultValue: 2
          },
          {
            id: 'is_urgent',
            group: 'Call Back Filters',
            label: 'Urgent',
            type: 'select',
            getParamValue: (option) => (option === 0 ? null : option === 1),
            options: [
              { label: 'All', value: 0 },
              { label: 'Yes', value: 1 },
              { label: 'No', value: 2 }
            ],
            defaultValue: 0
          },
          {
            id: 'user',
            group: 'Call Back Filters',
            label: 'User',
            type: 'select',
            getParamValue: (option) => (option === -1 ? null : option),
            onChange: (value) => dispatch(setOrganizationUserFilter(value)),
            options: [{ label: 'All', value: -1 }].concat(
              Object.values(users.entities).map((t) => ({
                label: `${t.first_name} ${t.last_name}`,
                value: t.id
              }))
            ),
            defaultValue: filters.user
          }
        ]}
      />
      <CallBackDialog
        open={isDialogOpen}
        callBackId={focusedId}
        onSubmit={() => {
          if (table?.current) {
            table.current.loadData()
          }
        }}
        onClose={() => setIsDialogOpen(false)}
      />
    </Fragment>
  )
}

export default OrganizationCallBacksView
