import apiClient from '../http-common'

export const COMMONTYPE_ENDPOINT = '/common-type'

export const fetchCommonTypes = (queryParams) =>
  apiClient
    .get(`${COMMONTYPE_ENDPOINT}/`, { params: queryParams })
    .then((res) => res.data)

export const fetchCommonType = (commonTypeId) =>
  apiClient
    .get(`${COMMONTYPE_ENDPOINT}/${commonTypeId}/`)
    .then((res) => res.data)

export const postCommonType = (data) =>
  apiClient.post(`${COMMONTYPE_ENDPOINT}/`, data).then((res) => res.data)

export const patchCommonType = (commonTypeId, data) =>
  apiClient
    .patch(`${COMMONTYPE_ENDPOINT}/${commonTypeId}/`, data)
    .then((res) => res.data)

export const deleteCommonType = (commonTypeId) =>
  apiClient.delete(`${COMMONTYPE_ENDPOINT}/${commonTypeId}/`)
