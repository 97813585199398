import AddIcon from '@mui/icons-material/Add'
import { Button, IconButton, Tooltip } from '@mui/material'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useSearchParams } from 'react-router-dom'

import { useApp } from '../../../../../app/context/AppContext'
import { BottomDrawer, EnhancedTable, Page } from '../../../../../components'
import { selectAuthenticatedUserHasPermissions } from '../../../../auth/slice'
import { createGroup, editGroup, loadGroups, removeGroup } from '../api'
import { GroupDetail, GroupDialog } from '../components'
import {
  getGroupsViewProperties,
  selectGroup,
  setGroupsOrder,
  setGroupsOrderBy,
  setGroupsPage,
  setGroupsSearch
} from '../slice'

const columns = [
  {
    id: 'id',
    label: 'ID',
    sortable: true,
    style: { width: '100px' },
    cellRenderer: (datum, row, rowIndex) => {
      return (
        <Button component={Link} to={`/users/groups/${row.id}/`}>
          {datum}
        </Button>
      )
    }
  },
  {
    id: 'name',
    label: 'Name',
    sortable: true
  }
]

function GroupsView() {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [searchParams, setSearchParams] = useSearchParams({})
  const { isSideBarOpen, sideBarWidth } = useApp()

  const table = useRef()

  const [isLoading, setIsLoading] = useState(true)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [focusedId, setFocusedId] = useState(null)
  const [selectedIds, setSelectedIds] = useState([])
  const [isBottomDrawerOpen, setIsBottomDrawerOpen] = useState(false)
  const [bottomDrawerHeight, setBottomDrawerHeight] = useState(null)

  const [canEdit, canDelete] = useSelector(
    selectAuthenticatedUserHasPermissions(['change_group', 'delete_group'])
  )

  const { search, page, order, orderBy } = useSelector(getGroupsViewProperties)

  const selectedGroup = useSelector(
    selectGroup(selectedIds.length == 1 ? selectedIds[0] : null)
  )

  const getDataFetcher = () => (queryParams) => {
    return dispatch(loadGroups(queryParams)).unwrap()
  }

  const handleSubmit = (values) => {
    if (_.get(values, 'id', null) != null) {
      dispatch(editGroup(values))
        .unwrap()
        .then((e) => {
          enqueueSnackbar('Update successful', {
            variant: 'success'
          })
          table.current.loadData()
        })
        .catch((err) => {
          enqueueSnackbar('Update failed', {
            variant: 'error'
          })
        })
    } else {
      dispatch(createGroup(values))
        .unwrap()
        .then((result) => {
          enqueueSnackbar('Creation successful', {
            variant: 'success'
          })
          table.current.loadData()
        })
        .catch((err) => {
          enqueueSnackbar('Creation failed', {
            variant: 'error'
          })
        })
    }

    setSelectedIds([])
  }

  const handleDelete = (id) => {
    setSelectedIds([])
    dispatch(removeGroup(id))
      .unwrap()
      .then((res) => {
        if (isDialogOpen) {
          setIsDialogOpen(false)
        }
        enqueueSnackbar('Deletion successful', {
          variant: 'success'
        })
        table.current.loadData()
      })
      .catch((err) => {
        enqueueSnackbar('Deletion failed', {
          variant: 'error'
        })
      })
  }

  useEffect(() => {
    const searchObject = { ...searchParams }

    if (isLoading) {
      const urlSearch = searchParams.get('search')
      const urlSelected = searchParams.get('selected') || []
      const urlPage = searchParams.get('page')
      const urlOrder = searchParams.get('order')
      const urlOrderBy = searchParams.get('orderBy')

      if (urlSearch) {
        dispatch(setGroupsSearch(urlSearch))
      }

      if (urlSelected.length > 0) {
        setSelectedIds(
          searchParams
            .get('selected')
            .split(',')
            .map((p) => parseInt(p))
        )
      }

      if (urlPage) {
        dispatch(setGroupsPage(parseInt(urlPage)))
      }

      if (urlOrder) {
        dispatch(setGroupsOrder(urlOrder))
      }

      if (urlOrderBy) {
        dispatch(setGroupsOrderBy(urlOrderBy))
      }

      setIsLoading(false)
    }

    if (selectedIds.length > 0) {
      searchObject.selected = selectedIds.join(',')
    } else {
      delete searchObject.selected
    }

    if (!_.isEmpty(search)) {
      searchObject.search = search
    } else {
      delete searchObject.search
    }

    if (page > 0) {
      searchObject.page = page
    } else {
      delete searchObject.page
    }

    if (!_.isEmpty(order)) {
      searchObject.order = order
    } else {
      delete searchObject.order
    }

    if (!_.isEmpty(orderBy)) {
      searchObject.orderBy = orderBy
    } else {
      delete searchObject.orderBy
    }

    setSearchParams(searchObject)
  }, [search, page, order, orderBy, selectedIds])

  return (
    <Page loading={isLoading}>
      <div
        style={{
          display: 'block',
          width: '100%',
          marginBottom: isBottomDrawerOpen ? `${bottomDrawerHeight}px` : '0px'
        }}
      >
        <EnhancedTable
          ref={table}
          selectable
          editable={canEdit}
          hoverable
          searchable
          deletable={canDelete}
          title="Groups"
          columns={columns}
          fetchData={getDataFetcher()}
          defaultOrder={order || 'asc'}
          defaultOrderBy={orderBy || 'id'}
          initialSearch={search}
          initialPage={page}
          initialSelected={selectedIds}
          actions={[
            <Tooltip key="new" title="Add New">
              <IconButton
                onClick={() => {
                  setFocusedId(null)
                  setIsDialogOpen(true)
                }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          ]}
          onEdit={(row) => {
            setFocusedId(row.id)
            setIsDialogOpen(true)
          }}
          onDelete={(ids) => ids.forEach((id) => handleDelete(id))}
          onSelectedChange={(selected) => {
            setSelectedIds(selected)
          }}
          onSearch={(value) => dispatch(setGroupsSearch(value))}
          onSortChange={(newOrder, newOrderBy) => {
            dispatch(setGroupsOrder(newOrder))
            dispatch(setGroupsOrderBy(newOrderBy))
          }}
          onPageChange={(value) => dispatch(setGroupsPage(value))}
        />
        <GroupDialog
          open={isDialogOpen}
          groupId={focusedId}
          onSubmit={handleSubmit}
          onClose={() => {
            setIsDialogOpen(false)
            setFocusedId(null)
          }}
          extraActions={
            focusedId != null
              ? [
                  <Button
                    key="delete"
                    color="error"
                    onClick={() => handleDelete(focusedId)}
                  >
                    Delete
                  </Button>
                ]
              : []
          }
        />
      </div>
      <BottomDrawer
        open={isBottomDrawerOpen}
        title={
          selectedGroup
            ? 'Group Details'
            : selectedIds.length > 0
            ? `Selected: ${selectedIds.join(', ')}`
            : 'Select a Group'
        }
        onToggle={() => setIsBottomDrawerOpen(!isBottomDrawerOpen)}
        PaperStyle={{
          marginLeft: isSideBarOpen ? `${sideBarWidth}px` : 0
        }}
        onHeightChange={(height) => {
          setBottomDrawerHeight(height)
        }}
      >
        {selectedGroup && <GroupDetail group={selectedGroup} />}
      </BottomDrawer>
    </Page>
  )
}

export default GroupsView
