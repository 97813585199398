import { format, parseISO } from 'date-fns'
import produce from 'immer'

import {
  DEFAULT_DATETIME_FORMAT,
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT
} from '../../../app/constants'

/**
 *
 * BITWISE FUNCTIONS AND UTILITY CLASSES
 *
 */

export const DayOfWeekFlag = {
  SUNDAY: 1,
  MONDAY: 2,
  TUESDAY: 4,
  WEDNESDAY: 8,
  THURSDAY: 16,
  FRIDAY: 32,
  SATURDAY: 64
}

export const BusinessDaysOfWeek =
  DayOfWeekFlag.MONDAY |
  DayOfWeekFlag.TUESDAY |
  DayOfWeekFlag.WEDNESDAY |
  DayOfWeekFlag.THURSDAY |
  DayOfWeekFlag.FRIDAY

export function unpackBits(bits, flags, withKeys = false) {
  return Object.keys(flags)
    .map((flagKey) => {
      const flag = flags[flagKey]
      if (bits & flag) {
        if (withKeys) {
          return { key: flagKey, value: flag }
        }

        return flag
      }

      return null
    })
    .filter((x) => x != null)
}

export function packBits(value) {
  if (Array.isArray(value)) {
    return value.reduce((previousValue, currentValue, currentIndex) => {
      if (Number.isInteger(currentValue)) {
        return (previousValue |= currentValue)
      } else {
        return previousValue
      }
    }, 0)
  } else {
    return value
  }
}

/**
 *
 * DATETIME FUNCTIONS AND UTILITY CLASSES
 *
 */

export function formatDateTime(
  inputString,
  outputFormat = DEFAULT_DATETIME_FORMAT
) {
  if (inputString) {
    return format(parseISO(inputString), outputFormat)
  } else {
    return null
  }
}

export function formatDate(inputString, outputFormat = DEFAULT_DATE_FORMAT) {
  if (inputString) {
    return format(inputString, outputFormat)
  } else {
    return null
  }
}

export function formatTime(inputString, outputFormat = DEFAULT_TIME_FORMAT) {
  if (inputString) {
    return format(inputString, outputFormat)
  } else {
    return null
  }
}

export function timeToDate(inputString) {
  if (inputString) {
    const now = new Date()
    const [hours, minutes, seconds] = inputString.split(':')
    now.setHours(hours)
    now.setMinutes(minutes)
    now.setSeconds(seconds)
    return now
  }
  return null
}

export function formatOrganization(organization) {
  return produce(organization, (draft) => {
    draft.created = draft.created
      ? format(parseISO(draft.created), DEFAULT_DATETIME_FORMAT)
      : null
    draft.updated = draft.updated
      ? format(parseISO(draft.updated), DEFAULT_DATETIME_FORMAT)
      : null
    return draft
  })
}
