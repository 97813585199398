import { Add } from '@mui/icons-material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tooltip,
  Typography
} from '@mui/material'
import { PropTypes } from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Detail, EnhancedTable, LoadingPanel } from '../../../../../components'
import { loadUsers } from '../../api'
import { UsersTableColumns } from '../../components/UsersTable'
import { selectUser } from '../../slice'

function GroupDetail({ group }) {
  const dispatch = useDispatch()

  const [tab, setTab] = useState('0')
  const [data, setData] = useState(null)
  const [focusedId, setFocusedId] = useState(null)
  const [selectedIds, setSelectedIds] = useState([])

  const table = useRef()

  const selectedUser = useSelector(
    selectUser(selectedIds.length == 1 ? selectedIds[0] : null)
  )

  const getDataFetcher = () => (queryParams) => {
    return dispatch(loadUsers({ groups: group?.id, ...queryParams })).unwrap()
  }

  useEffect(() => {
    setData(group)
  }, [group])

  return data === null ? (
    <LoadingPanel />
  ) : (
    <TabContext value={tab}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList
          onChange={(event, newTab) => setTab(newTab)}
          aria-label="group tabs"
        >
          <Tab label="General" value="0" />
          <Tab label="Users" value="1" />
        </TabList>
      </Box>
      <TabPanel value="0">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h6">Details</Typography>
            <Divider sx={{ my: 1 }} />
            <Detail title="Name" value={data.name} />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value="1" sx={{ p: 0 }}>
        <EnhancedTable
          ref={table}
          selectable
          editable
          hoverable
          searchable
          title="Users"
          columns={UsersTableColumns}
          fetchData={getDataFetcher()}
          initialSelected={selectedIds}
          actions={[
            <Tooltip key="new" title="Add New">
              <IconButton
                onClick={() => {
                  setFocusedId(null)
                }}
              >
                <Add />
              </IconButton>
            </Tooltip>
          ]}
          onEdit={(row) => {
            setFocusedId(row.id)
          }}
          onSelectedChange={(selected) => {
            setSelectedIds(selected)
          }}
        />
      </TabPanel>
    </TabContext>
  )
}

GroupDetail.propTypes = {
  group: PropTypes.object.isRequired
}

export default GroupDetail
