import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, IconButton, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import React from 'react'

import Dragger from '../Dragger'

const BottomDrawerBarContainer = styled(Box)(({ theme, open }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.background.paper,
  padding: '0px 16px 0px 16px',
  cursor: open ? 'default' : 'pointer',
  borderBottom: `1px solid ${theme.palette.divider}`,
  ':hover': {
    backgroundColor: open
      ? theme.palette.background.paper
      : theme.palette.action.hover
  }
}))

function BottomDrawerBar({ open, title, onToggle, onMouseDown }) {
  return (
    <BottomDrawerBarContainer
      open={open}
      onClick={() => {
        if (!open) {
          onToggle()
        }
      }}
    >
      {open && <Dragger onMouseDown={onMouseDown} />}
      {title && <Typography variant="h5">{title}</Typography>}
      <IconButton onClick={onToggle}>
        {open ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </IconButton>
    </BottomDrawerBarContainer>
  )
}

BottomDrawerBar.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  onMouseDown: PropTypes.func.isRequired
}

export default BottomDrawerBar
