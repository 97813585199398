import { createAsyncThunk } from '@reduxjs/toolkit'
import { normalize } from 'normalizr'

import {
  deleteContact,
  fetchContact,
  fetchContacts,
  patchContact,
  postContact
} from '../../../../api/contact'
import ContactSchema from './schema'

export const loadContacts = createAsyncThunk(
  'organizations/contacts/fetchMany',
  async (queryParams, { rejectWithValue }) => {
    try {
      const response = await fetchContacts(queryParams)
      response.normalized = normalize(response.data, [ContactSchema])
      return response
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const loadContact = createAsyncThunk(
  'organizations/contacts/fetchOne',
  async (contactId, { rejectWithValue }) => {
    try {
      const response = await fetchContact(contactId)
      response.normalized = normalize(response.data, ContactSchema)
      return response
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const createContact = createAsyncThunk(
  'organizations/contacts/create',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await postContact(payload)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const editContact = createAsyncThunk(
  'organizations/contacts/patch',
  async ({ id, ...data }, { rejectWithValue }) => {
    try {
      const response = await patchContact(id, data)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const removeContact = createAsyncThunk(
  'organizations/contacts/delete',
  async (contactId, { rejectWithValue }) => {
    try {
      await deleteContact(contactId)
      return contactId
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)
