import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import {
  Button,
  MenuItem,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material'
import DialogContentText from '@mui/material/DialogContentText'
import { format } from 'date-fns'
import { Field, Form, Formik } from 'formik'
import { Select, TextField } from 'formik-mui'
import { TimePicker } from 'formik-mui-lab'
import produce from 'immer'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import { API_TIME_FORMAT } from '../../../../../app/constants'
import { selectGlobalsData } from '../../../../../app/globals/slice'
import { PrefixAutocomplete } from '../../../../../components'
import ResponsiveDialog from '../../../../../components/ResponsiveDialog'
import {
  BusinessDaysOfWeek,
  DayOfWeekFlag,
  formatTime,
  packBits,
  timeToDate,
  unpackBits
} from '../../utils'
import { createContact, editContact } from '../api'
import { selectContact } from '../slice'

function ContactDialog({ open, contactId, onSubmit, onClose, ...props }) {
  const dispatch = useDispatch()

  const { enqueueSnackbar } = useSnackbar()

  const homeExchangeRef = useRef(null)
  const homeExtensionRef = useRef(null)
  const cellExchangeRef = useRef(null)
  const cellExtensionRef = useRef(null)
  const workExchangeRef = useRef(null)
  const workExtensionRef = useRef(null)

  const contact = useSelector(selectContact(contactId))
  const globals = useSelector(selectGlobalsData)

  const [initialValues, setInitialValues] = useState({})

  const now = format(new Date(), 'yyyy-MM-dd')

  const handleSubmit = (values) => {
    if (!open) {
      return
    }
    const isCreating = contactId == null

    const payload = produce(values, (draft) => {
      if (!isCreating) {
        draft.id = contactId
      }

      draft.days_of_week = packBits(draft.days_of_week)
      console.log('post', draft.availability_start)
      draft.availability_start = formatTime(
        draft.availability_start,
        API_TIME_FORMAT
      )
      draft.availability_end = formatTime(
        draft.availability_end,
        API_TIME_FORMAT
      )

      return draft
    })

    if (isCreating) {
      dispatch(createContact(payload))
        .unwrap()
        .then(() => {
          enqueueSnackbar('Contact created successfully', {
            variant: 'success'
          })
        })
        .catch(({ data }) => {
          try {
            const errorString = Object.values(data)[0]
            if (errorString) {
              enqueueSnackbar(`Contact creation failed: ${errorString}`, {
                variant: 'error'
              })
            }
          } catch (e) {
            console.log('error', e)
          }
        })
    } else {
      dispatch(editContact(payload))
        .unwrap()
        .then(() => {
          enqueueSnackbar('Contact modified successfully', {
            variant: 'success'
          })
        })
        .catch(({ data }) => {
          const errorString = Object.values(data)[0]
          enqueueSnackbar(`Contact modification failed: ${errorString}`, {
            variant: 'error'
          })
        })
    }

    if (onSubmit) {
      onSubmit(payload)
    }

    onClose()
  }

  useEffect(
    () =>
      setInitialValues({
        prefix: contact?.prefix ?? '',
        first_name: contact?.first_name ?? '',
        last_name: contact?.last_name ?? '',
        suffix: contact?.suffix ?? '',
        email: contact?.email ?? '',
        job_title: contact?.job_title ?? '',
        availability_start: timeToDate(contact?.availability_start) ?? '',
        availability_end: timeToDate(contact?.availability_end) ?? '',
        time_zone: contact?.time_zone ?? '',
        days_of_week: contact?.days_of_week
          ? unpackBits(contact.days_of_week, DayOfWeekFlag)
          : unpackBits(BusinessDaysOfWeek, DayOfWeekFlag),
        address1: contact?.address1 ?? '',
        address2: contact?.address2 ?? '',
        city: contact?.city ?? '',
        state: contact?.state ?? '',
        zip_code: contact?.zip_code ?? '',
        country: contact?.country ?? '',
        home_phone_area_code: contact?.home_phone_area_code ?? '',
        home_phone_exchange: contact?.home_phone_exchange ?? '',
        home_phone_extension: contact?.home_phone_extension ?? '',
        work_phone_area_code: contact?.work_phone_area_code ?? '',
        work_phone_exchange: contact?.work_phone_exchange ?? '',
        work_phone_extension: contact?.work_phone_extension ?? '',
        cell_phone_area_code: contact?.cell_phone_area_code ?? '',
        cell_phone_exchange: contact?.cell_phone_exchange ?? '',
        cell_phone_extension: contact?.cell_phone_extension ?? '',
        notes: contact?.notes ?? ''
      }),
    [contact]
  )

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={yup.object({
        prefix: yup.string('Enter prefix'),
        first_name: yup.string('Enter first name'),
        last_name: yup.string('Enter last name'),
        email: yup.string('Enter e-mail address'),
        suffix: yup.string('Enter suffix'),
        job_title: yup.string('Enter job title'),
        availability_start: yup.string('Enter start availability'),
        availability_end: yup.string('Enter end availability'),
        time_zone: yup.string('Enter time zone'),
        days_of_week: yup
          .number()
          .min(0, 'Enter valid day of week choice')
          .max(127, 'Enter valid day of week choice'),
        home_phone_area_code: yup
          .string('Enter area code (home)')
          .max(3, 'Area code cannot exceed 3 characters'),
        home_phone_exchange: yup
          .string('Enter exchange (home)')
          .max(7, 'Exchange cannot exceed 7 characters'),
        home_phone_extension: yup
          .string('Enter extensiom (home)')
          .max(10, 'Extension cannot exceed 10 characters'),
        work_phone_area_code: yup
          .string('Enter area code (work)')
          .max(3, 'Area code cannot exceed 3 characters'),
        work_phone_exchange: yup
          .string('Enter exchange (work)')
          .max(7, 'Exchange cannot exceed 7 characters'),
        work_phone_extension: yup
          .string('Enter extensiom (work)')
          .max(10, 'Extension cannot exceed 10 characters'),
        cell_phone_area_code: yup
          .string('Enter area code (cell)')
          .max(3, 'Area code cannot exceed 3 characters'),
        cell_phone_exchange: yup
          .string('Enter exchange (cell)')
          .max(7, 'Exchange cannot exceed 7 characters'),
        cell_phone_extension: yup
          .string('Enter extensiom (cell)')
          .max(10, 'Extension cannot exceed 10 characters'),
        address1: yup.string('Enter street address'),
        address2: yup.string('Enter street address (2)'),
        city: yup.string('Enter city'),
        state: yup.string('Enter state'),
        zip_code: yup.string('Enter ZIP code'),
        country: yup.string('Enter country'),
        notes: yup
          .string('Enter notes')
          .max(512, 'Notes cannot exceed 512 characters')
      })}
      onSubmit={handleSubmit}
    >
      {({ values, submitForm, setFieldValue, handleChange, resetForm }) => (
        <ResponsiveDialog
          title={`${contactId ? 'Edit' : 'New'} Contact`}
          open={open}
          onClose={() => {
            resetForm()
            onClose()
          }}
          onSubmit={() => {
            submitForm()
          }}
          {...props}
        >
          <Form>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DialogContentText>
                <Typography sx={{ mb: 2 }}>
                  To configure this contact, please enter all required details
                  here.
                </Typography>
                <Button
                  onClick={() => {
                    const resetValues = {
                      prefix: '',
                      first_name: '',
                      last_name: '',
                      suffix: '',
                      email: '',
                      job_title: '',
                      availability_start: '',
                      availability_end: '',
                      time_zone: '',
                      days_of_week: unpackBits(
                        BusinessDaysOfWeek,
                        DayOfWeekFlag
                      ),
                      address1: '',
                      address2: '',
                      city: '',
                      state: '',
                      zip_code: '',
                      country: '',
                      home_phone_area_code: '',
                      home_phone_exchange: '',
                      home_phone_extension: '',
                      work_phone_area_code: '',
                      work_phone_exchange: '',
                      work_phone_extension: '',
                      cell_phone_area_code: '',
                      cell_phone_exchange: '',
                      cell_phone_extension: '',
                      notes: ''
                    }

                    Object.keys(resetValues).forEach((v, i) => {
                      setFieldValue(v, resetValues[v])
                    })
                  }}
                >
                  Clear
                </Button>
              </DialogContentText>
              <Stack spacing={2}>
                <Typography variant="h4">General</Typography>
                <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
                  <Field
                    fullWidth
                    size="large"
                    name="prefix"
                    label="Prefix"
                    value={values.prefix}
                    onChange={(e) => {
                      setFieldValue('prefix', e.target.value)
                    }}
                    component={PrefixAutocomplete}
                  />
                  <Field
                    component={TextField}
                    fullWidth
                    name="first_name"
                    label="First Name"
                  />
                  <Field
                    component={TextField}
                    fullWidth
                    name="last_name"
                    label="Last Name"
                  />
                  <Field
                    component={TextField}
                    fullWidth
                    name="suffix"
                    label="Suffix"
                  />
                </Stack>
                <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
                  <Field
                    component={TextField}
                    fullWidth
                    name="email"
                    label="E-mail"
                  />
                  <Field
                    component={TextField}
                    fullWidth
                    name="job_title"
                    label="Job Title"
                  />
                </Stack>
                <Stack spacing={2}>
                  <Typography variant="h4">Address</Typography>
                  <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
                    <Field
                      component={TextField}
                      fullWidth
                      name="address1"
                      label="Address Line 1"
                    />
                    <Field
                      component={TextField}
                      fullWidth
                      name="address2"
                      label="Address Line 2"
                    />
                  </Stack>
                  <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
                    <Field
                      component={TextField}
                      fullWidth
                      name="city"
                      label="City"
                    />
                    <Field
                      component={TextField}
                      fullWidth
                      name="state"
                      label="State"
                    />
                    <Field
                      component={TextField}
                      fullWidth
                      name="zip_code"
                      label="ZIP"
                    />
                  </Stack>
                  <Field
                    component={TextField}
                    fullWidth
                    name="country"
                    label="Country"
                  />
                </Stack>
                <Typography variant="h4">Availability</Typography>
                <Stack
                  spacing={2}
                  direction={{ xs: 'column', md: 'row' }}
                  sx={{ '& > .MuiFormControl-root': { width: '100%' } }}
                >
                  <Field
                    required
                    fullWidth
                    component={TimePicker}
                    name="availability_start"
                    label="Availability Start"
                  />
                  <Field
                    required
                    fullWidth
                    component={TimePicker}
                    name="availability_end"
                    label="Availability End"
                  />
                  <Field
                    fullWidth
                    component={Select}
                    name="time_zone"
                    label="Time Zone"
                  >
                    {globals &&
                      globals.time_zones
                        .filter((tz) => tz.includes('US/'))
                        .map((tz) => (
                          <MenuItem key={tz} value={tz}>
                            {tz}
                          </MenuItem>
                        ))}
                  </Field>
                </Stack>
                <ToggleButtonGroup
                  value={values.days_of_week}
                  onChange={(e, dow) => {
                    setFieldValue('days_of_week', dow)
                  }}
                >
                  {Object.keys(DayOfWeekFlag).map((key) => (
                    <ToggleButton
                      key={key}
                      value={DayOfWeekFlag[key]}
                      sx={{ width: '100%' }}
                    >
                      <span>{key.substring(0, 2)}</span>
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
                <Typography variant="h4">Cell Phone</Typography>
                <Stack spacing={2} direction="row">
                  <Field
                    component={TextField}
                    maxLength={3}
                    inputProps={{
                      maxLength: 3
                    }}
                    onChange={(e) => {
                      if (e.target.value.length == 3) {
                        cellExchangeRef.current.focus()
                      }
                      handleChange(e)
                    }}
                    name="cell_phone_area_code"
                    label="Area Code"
                  />
                  <Field
                    component={TextField}
                    fullWidth
                    maxLength={7}
                    inputProps={{
                      ref: (f) => (cellExchangeRef.current = f),
                      maxLength: 7
                    }}
                    onChange={(e) => {
                      if (e.target.value.length == 7) {
                        cellExtensionRef.current.focus()
                      }
                      handleChange(e)
                    }}
                    name="cell_phone_exchange"
                    label="Exchange"
                  />
                  <Field
                    component={TextField}
                    maxLength={10}
                    inputProps={{
                      ref: (f) => (cellExtensionRef.current = f),
                      maxLength: 10
                    }}
                    name="cell_phone_extension"
                    label="Extension"
                  />
                </Stack>
                <Typography variant="h4">Work Phone</Typography>
                <Stack spacing={2} direction="row">
                  <Field
                    component={TextField}
                    maxLength={3}
                    inputProps={{
                      maxLength: 3
                    }}
                    onChange={(e) => {
                      if (e.target.value.length == 3) {
                        workExchangeRef.current.focus()
                      }
                      handleChange(e)
                    }}
                    name="work_phone_area_code"
                    label="Area Code"
                  />
                  <Field
                    component={TextField}
                    maxLength={7}
                    inputProps={{
                      ref: (f) => (workExchangeRef.current = f),
                      maxLength: 7
                    }}
                    onChange={(e) => {
                      if (e.target.value.length == 7) {
                        workExtensionRef.current.focus()
                      }
                      handleChange(e)
                    }}
                    fullWidth
                    name="work_phone_exchange"
                    label="Exchange"
                  />
                  <Field
                    component={TextField}
                    maxLength={10}
                    inputProps={{
                      ref: (f) => (workExtensionRef.current = f),
                      maxLength: 10
                    }}
                    name="work_phone_extension"
                    label="Extension"
                  />
                </Stack>
                <Typography variant="h4">Home Phone</Typography>
                <Stack spacing={2} direction="row">
                  <Field
                    component={TextField}
                    maxLength={3}
                    inputProps={{
                      maxLength: 3
                    }}
                    onChange={(e) => {
                      if (e.target.value.length == 3) {
                        homeExchangeRef.current.focus()
                      }
                      handleChange(e)
                    }}
                    name="home_phone_area_code"
                    label="Area Code"
                  />
                  <Field
                    component={TextField}
                    maxLength={7}
                    inputProps={{
                      ref: (f) => (homeExchangeRef.current = f),
                      maxLength: 7
                    }}
                    onChange={(e) => {
                      if (e.target.value.length == 7) {
                        homeExtensionRef.current.focus()
                      }
                      handleChange(e)
                    }}
                    fullWidth
                    name="home_phone_exchange"
                    label="Exchange"
                  />
                  <Field
                    component={TextField}
                    maxLength={10}
                    inputProps={{
                      ref: (f) => (homeExtensionRef.current = f),
                      maxLength: 10
                    }}
                    name="home_phone_extension"
                    label="Extension"
                  />
                </Stack>

                <Typography variant="h4">Other</Typography>
                <Field
                  fullWidth
                  multiline
                  component={TextField}
                  name="notes"
                  label="Notes"
                  rows={5}
                />
              </Stack>
            </LocalizationProvider>
          </Form>
        </ResponsiveDialog>
      )}
    </Formik>
  )
}

ContactDialog.propTypes = {
  open: PropTypes.bool,
  contactId: PropTypes.number,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
}

export default ContactDialog
