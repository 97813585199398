import { Stack } from '@mui/material'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'

import Detail from './Detail'

function Address({ street, streetExtra, city, state, country, zip }) {
  return (
    <Fragment>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Detail title="Street" value={street} nullText="-" />
        <Detail title="Line 2" value={streetExtra} nullText="-" />
      </Stack>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Detail title="City" value={city} nullText="-" />
        <Detail title="State" value={state} nullText="-" />
        <Detail title="ZIP" value={zip} nullText="-" />
        <Detail title="Country" value={country} nullText="-" />
      </Stack>
    </Fragment>
  )
}

Address.propTypes = {
  street: PropTypes.string,
  streetExtra: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string,
  zip: PropTypes.string
}

export default Address
