import {
  createEntityAdapter,
  createSelector,
  createSlice
} from '@reduxjs/toolkit'

import {
  createDelivery,
  editDelivery,
  loadDeliveries,
  loadDelivery,
  removeDelivery
} from './api'

const deliveriesAdapter = createEntityAdapter()

const slice = createSlice({
  name: 'deliveries',
  initialState: deliveriesAdapter.getInitialState({
    view: {
      search: null,
      page: 0,
      order: 'asc',
      orderBy: 'id'
    }
  }),
  reducers: {
    setPage(state, action) {
      state.view.page = action.payload
    },
    setSearch(state, action) {
      state.view.search = action.payload
    },
    setOrder(state, action) {
      state.view.order = action.payload
    },
    setOrderBy(state, action) {
      state.view.orderBy = action.payload
    }
  },
  extraReducers: {
    [loadDeliveries.fulfilled]: (state, action) => {
      const {
        normalized: { entities }
      } = action.payload
      deliveriesAdapter.upsertMany(state, entities.deliveries ?? {})
    },
    [loadDeliveries.rejected]: (state, action) => {
      console.log(action)
    },
    [loadDelivery.fulfilled]: (state, action) => {
      const {
        normalized: { entities }
      } = action.payload
      deliveriesAdapter.upsertMany(state, entities.deliveries ?? {})
    },
    [loadDelivery.rejected]: (state, action) => {
      console.log(action)
    },
    [createDelivery.fulfilled]: (state, action) => {
      deliveriesAdapter.addOne(state, action.payload)
    },
    [createDelivery.rejected]: (state, action) => {
      console.log(action)
    },
    [editDelivery.fulfilled]: (state, action) => {
      deliveriesAdapter.upsertOne(state, action.payload)
    },
    [editDelivery.rejected]: (state, action) => {
      console.log(action)
    },
    [removeDelivery.fulfilled]: (state, action) => {
      deliveriesAdapter.removeOne(state, action.payload)
    },
    [removeDelivery.rejected]: (state, action) => {
      console.log(action)
    }
  }
})

export const selectDeliveryEntities = (state) => {
  return state.deliveries
}

export const { selectById } = deliveriesAdapter.getSelectors(
  selectDeliveryEntities
)

export const selectDelivery = (id) => (state) => selectById(state, id)

export const getDeliveriesViewProperties = createSelector(
  selectDeliveryEntities,
  (state) => state.view
)

export const {
  setPage: setDeliveriesPage,
  setSearch: setDeliveriesSearch,
  setOrder: setDeliveriesOrder,
  setOrderBy: setDeliveriesOrderBy
} = slice.actions

const reducer = slice.reducer
export default reducer
