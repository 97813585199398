import React from 'react'

import { ActivityStatus } from '../../../../components'
import CommonTypesBaseView from './CommonTypesBaseView'

const columns = [
  {
    id: 'name',
    label: 'Name',
    sortable: true
  },
  {
    id: 'is_active',
    label: 'Active',
    sortable: true,
    cellRenderer: (datum) => <ActivityStatus active={Boolean(datum)} />
  }
]

function ProductsView() {
  return (
    <CommonTypesBaseView
      title="Products"
      category="Products"
      columns={columns}
    />
  )
}

export default ProductsView
