/* eslint-disable react/prop-types */
import { Autocomplete, TextField } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { loadUsers } from '../features/apps/users/api'
import { selectUsers } from '../features/apps/users/slice'

function UserAutocomplete({
  name,
  value,
  size,
  error,
  helperText,
  required,
  fullWidth,
  disabled,
  onChange,
  onBlur,
  sx = []
}) {
  const dispatch = useDispatch()

  const [options, setOptions] = useState([])

  const users = useSelector(selectUsers())

  const selectedOption =
    options.find((v) => {
      return v.value == value
    }) || null

  useEffect(() => {
    setOptions(
      Object.values(users).map((row) => {
        const label = `${row.first_name} ${row.last_name}`
        return {
          label,
          value: row.id
        }
      })
    )
  }, [users])

  useEffect(() => {
    dispatch(loadUsers())
  }, [])

  return (
    <Autocomplete
      disablePortal
      value={selectedOption}
      disabled={disabled}
      options={options}
      isOptionEqualToValue={(option, value) => {
        return option.value === value.value
      }}
      getOptionLabel={(option) => {
        return option ? option.label : ''
      }}
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      onBlur={onBlur}
      onChange={(e, opt) => {
        e.target = {
          id: `${name}-autocomplete-input`,
          name,
          value: opt ? opt.value ?? '' : ''
        }
        if (onChange) {
          onChange(e)
        }
      }}
      renderInput={(params) => {
        return (
          <TextField
            id={`${name}-autocomplete-input`}
            {...params}
            required={required || false}
            fullWidth={fullWidth || false}
            name={name}
            size={size || 'small'}
            label="User"
            error={error}
            helperText={helperText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>{params.InputProps.endAdornment}</Fragment>
              )
            }}
          />
        )
      }}
    />
  )
}

export default UserAutocomplete
