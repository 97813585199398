import { Autocomplete, Box, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import { format, parse } from 'date-fns'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { DEFAULT_DATE_FORMAT } from '../../app/constants.js'

const StyledAutoComplete = styled(Autocomplete)(({ theme }) => ({
  color: 'inherit',
  width: '94%',
  display: 'inline-flex',
  margin: theme.spacing(0.5, 1.5),
  transition: theme.transitions.create('width')
}))

function ToolbarSearchbar(props) {
  const { events, onInputChange } = props

  const [value, setValue] = useState(null)
  const [inputValue, setInputValue] = useState('')

  const handleOnChange = (event, newValue) => {
    setValue(newValue)
    onInputChange && onInputChange(newValue)
  }

  return (
    <StyledAutoComplete
      value={value}
      id="scheduler-autocomplete"
      inputValue={inputValue}
      sx={{ display: 'inline-flex' }}
      onChange={handleOnChange}
      options={events?.sort(
        (a, b) => -b.groupLabel.localeCompare(a.groupLabel)
      )}
      groupBy={(option) => option?.groupLabel}
      getOptionLabel={(option) =>
        option &&
        `${option?.groupLabel} | (${option?.startHour ?? ''} - ${
          option?.endHour ?? ''
        })`
      }
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
        onInputChange(newInputValue)
      }}
      renderOption={(props, option) => (
        <Box component="li" sx={{ fontSize: 12 }} {...props}>
          {format(
            parse(option?.date, DEFAULT_DATE_FORMAT, new Date()),
            'dd-MMMM-yyyy'
          )}
          ({option?.startHour ?? ''} - {option?.endHour ?? ''})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label="Search..."
          InputProps={{ ...params.InputProps }}
        />
      )}
    />
  )
}

ToolbarSearchbar.propTypes = {
  events: PropTypes.array,
  onInputChange: PropTypes.func
}

ToolbarSearchbar.defaultProps = {}

export default ToolbarSearchbar
