import SearchIcon from '@mui/icons-material/Search'
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

function SearchField({ value, onChange, onSearch, InputProps = {}, ...props }) {
  const [search, setSearch] = useState(value || '')

  useEffect(() => {
    setSearch(value)
  }, [value])

  useEffect(() => {
    if (value !== search) {
      onChange(search)
    }
  }, [search])

  return (
    <FormControl {...props}>
      <InputLabel htmlFor="search">Search</InputLabel>
      <OutlinedInput
        id="search"
        type="text"
        label="Search"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        sx={{ width: '100%' }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={() => onSearch(search)}>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
        {...InputProps}
      />
    </FormControl>
  )
}

SearchField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  InputProps: PropTypes.object
}

export default SearchField
