import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import PrintIcon from '@mui/icons-material/Print'
import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import FileSaver from 'file-saver'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom'

import { fetchOrganizationPdf } from '../../../../api/organization'
import Page from '../../../../components/Page'
import { loadCommonTypes } from '../../common-types/api'
import { editOrganization, loadOrganization, removeOrganization } from '../api'
import OrganizationDetail from '../components/OrganizationDetail'
import { selectOrganization } from '../slice'

function OrganizationsDetailView() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const organization = useSelector(selectOrganization(id))

  const handleDelete = () =>
    dispatch(removeOrganization(id))
      .unwrap()
      .then(() => {
        enqueueSnackbar('Deletion successful', {
          variant: 'success'
        })
        navigate('/organizations', { replace: true })
      })
      .catch((err) =>
        enqueueSnackbar('Deletion failed', {
          variant: 'error'
        })
      )

  useEffect(() => {
    setIsLoading(true)
    dispatch(loadOrganization(id))
      .unwrap()
      .then(() => dispatch(loadCommonTypes({ limit: 500 })))
      .catch((err) => {
        console.log(err)
      })
      .finally(() => setIsLoading(false))
  }, [])

  const handlePrint = () => {
    fetchOrganizationPdf(id).then((data) =>
      FileSaver.saveAs(new Blob([data]), `org-summary-${id}.pdf`)
    )
  }

  return (
    <Page loading={isLoading}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          pl: { sm: 2 },
          py: 1.5,
          pr: { xs: 1, sm: 1 },
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`
        }}
      >
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link
            component={RouterLink}
            underline="hover"
            color="inherit"
            to="/organizations/"
          >
            Organizations
          </Link>
          {organization && (
            <Typography color="text.primary">{organization.name}</Typography>
          )}
        </Breadcrumbs>
        <Stack direction="row" spacing={2}>
          <Tooltip title={organization?.is_active ? 'Deactivate' : 'Activate'}>
            <IconButton
              onClick={() =>
                dispatch(
                  editOrganization({
                    id,
                    is_active: !organization.is_active
                  })
                )
                  .unwrap()
                  .then(() => {
                    enqueueSnackbar(
                      `${
                        organization?.is_active ? 'Deactivation' : 'Activation'
                      } successful`,
                      {
                        variant: 'success'
                      }
                    )
                  })
              }
            >
              <PowerSettingsNewIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Print">
            <IconButton onClick={handlePrint}>
              <PrintIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <Button color="error" onClick={handleDelete}>
              Delete
            </Button>
          </Tooltip>
        </Stack>
      </Box>
      {organization && <OrganizationDetail organizationId={id} />}
    </Page>
  )
}

OrganizationsDetailView.propTypes = {}

export default OrganizationsDetailView
