import { useMediaQuery } from '@mui/material'
import { styled } from '@mui/system'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect } from 'react'

import { useApp } from '../../app/context/AppContext'
import SideBar from './SideBar'
import TopBar from './TopBar'

const MainContainer = styled('main', {
  shouldForwardProp: (prop) =>
    prop !== 'open' &&
    prop !== 'sideBarWidth' &&
    prop !== 'showTopBar' &&
    prop !== 'mobile'
})(({ theme, open, sideBarWidth, showTopBar, mobile }) => {
  let { mainContent } = theme.typography

  if (!showTopBar) {
    mainContent.marginTop = 0
  } else {
    mainContent.marginTop = '64px'
  }

  return {
    display: 'flex',
    flexDirection: 'column',
    ...mainContent,
    ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: mobile ? 0 : `${sideBarWidth}px`,
      width: mobile ? '100%' : `calc(100% - ${sideBarWidth}px)`
    })
  }
})

function Main({ showTopBar, showSideBar, children }) {
  const { isSideBarOpen, sideBarWidth, toggleSideBar, currentApp } = useApp()

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  useEffect(() => {
    if (showSideBar) {
      toggleSideBar(isDesktop)
    } else {
      toggleSideBar(false)
    }
  }, [isDesktop, showSideBar])

  return (
    <Fragment>
      {showTopBar && (
        <TopBar
          showMenuButton={!isDesktop && showSideBar}
          onClickMenuButton={() => toggleSideBar()}
          showAppsButton={currentApp != null}
        />
      )}
      {showSideBar && (
        <SideBar
          open={isSideBarOpen}
          width={`${sideBarWidth}px`}
          items={currentApp.sideBar ?? null}
        />
      )}
      <MainContainer
        open={isSideBarOpen}
        mobile={!isDesktop}
        sideBarWidth={sideBarWidth}
        showTopBar={showTopBar}
      >
        {children}
      </MainContainer>
    </Fragment>
  )
}

Main.propTypes = {
  showTopBar: PropTypes.bool,
  showSideBar: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.object
  ])
}

Main.defaultProps = {
  showTopBar: true,
  showSideBar: false
}

export default Main
