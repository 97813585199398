import { createSelector, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

import tokenService from '../../services/token'
import { loadAuthenticatedUser } from '../apps/users/api'
import { login, refreshToken } from './api'

const initialState = {
  isAuthenticated: tokenService.accessToken != null,
  userId: null,
  canAccess: []
}

const slice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setIsAuthenticated(state, action) {
      state.isAuthenticated = Boolean(action.payload)
    }
  },
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      const { access, refresh } = action.payload.data
      tokenService.accessToken = access
      tokenService.refreshToken = refresh
    },
    [login.rejected]: (state) => {
      state.isAuthenticated = false
      tokenService.logout()
    },
    [refreshToken.fulfilled]: (state, action) => {
      const { access } = action.payload.data
      tokenService.accessToken = access
      state.isAuthenticated = true
    },
    [refreshToken.rejected]: (state) => {
      state.isAuthenticated = false
      tokenService.logout()
    },
    [loadAuthenticatedUser.fulfilled]: (state, action) => {
      const {
        id,
        groups,
        is_superuser,
        is_calendar_user,
        is_users_user,
        is_organizations_user,
        is_types_user
      } = action.payload

      if (
        is_superuser ||
        Boolean(Object.values(groups).find((g) => g.name === 'administrators'))
      ) {
        state.canAccess = ['calendar', 'users', 'organizations', 'common-types']
      } else {
        if (is_calendar_user) {
          state.canAccess.push('calendar')
        }
        if (is_users_user) {
          state.canAccess.push('users')
        }
        if (is_organizations_user) {
          state.canAccess.push('organizations')
        }
        if (is_types_user) {
          state.canAccess.push('common-types')
        }
      }

      state.userId = id
      state.isAuthenticated = true
    },
    [loadAuthenticatedUser.rejected]: (state, action) => {
      state.isAuthenticated = false
      state.userId = null
      tokenService.logout()
    }
  }
})

export const selectIsAuthenticated = (state) => state.auth.isAuthenticated

export const selectAuthenticatedUser = (state) =>
  state.users.entities[state.auth.userId]

export const selectAuthenticatedUserAccess = (state) => state.auth.canAccess

export const selectAuthenticatedUserPermissions = createSelector(
  selectAuthenticatedUser,
  (user) => {
    if (user) {
      return user.permissions
    }

    return []
  }
)

export const selectAuthenticatedUserHasPermissions = (permissionNames) =>
  createSelector(
    [selectAuthenticatedUser, selectAuthenticatedUserPermissions],
    (user, permissions) => {
      if (!user) {
        return new Array(permissionNames.length).fill(false)
      }

      if (user.is_superuser) {
        return new Array(permissionNames.length).fill(true)
      }

      return permissionNames.map((name) => permissions.includes(name))
    }
  )

export const { setIsAuthenticated } = slice.actions

const reducer = slice.reducer
export default reducer
