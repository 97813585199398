/* eslint-disable react/prop-types */
import { Box } from '@mui/material'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import React, { Fragment, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import {
  DEFAULT_DATETIME_FORMAT,
  DEFAULT_DATE_FORMAT
} from '../../../../../app/constants'
import EnhancedTable from '../../../../../components/EnhancedTable'
import QueryBuilder from '../components/QueryBuilder'

function MailMergeView({ organizationId }) {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  return (
    <Box sx={{ p: 2 }}>
      <QueryBuilder />
    </Box>
  )
}

export default MailMergeView
