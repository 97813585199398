import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'

import { loadGlobals } from '../app/globals/api'
import { selectGlobals } from '../app/globals/slice'
import routes from '../app/routes'
import { LoadingPanel } from '../components'
import Main from '../components/layouts/Main'
import { loadAuthenticatedUser } from '../features/apps/users/api'
import {
  selectAuthenticatedUser,
  selectAuthenticatedUserAccess,
  selectIsAuthenticated,
  setIsAuthenticated
} from '../features/auth/slice'
import tokenService from '../services/token'
import PageNotFoundView from '../views/PageNotFoundView'

export default function AppRoutes() {
  const dispatch = useDispatch()

  const isAuthenticated = useSelector(selectIsAuthenticated)
  const userAccess = useSelector(selectAuthenticatedUserAccess)
  const authenticatedUser = useSelector(selectAuthenticatedUser)
  const globals = useSelector(selectGlobals)
  const state = useSelector((state) => state)

  const [isAuthenticating, setIsAuthenticating] = useState(
    !isAuthenticated && authenticatedUser != null
  )

  const generateRedirect = useCallback(
    (route) => {
      if (typeof route === 'function') {
        return route(state)
      } else {
        return route
      }
    },
    [state]
  )

  useEffect(() => {
    if (isAuthenticated && authenticatedUser == null) {
      setIsAuthenticating(true)
      dispatch(loadGlobals()).then(() =>
        dispatch(loadAuthenticatedUser())
          .catch((err) => {
            console.log(err)
            dispatch(setIsAuthenticated(false))
            tokenService.logout()
          })
          .finally(() => setIsAuthenticating(false))
      )
    }
  }, [isAuthenticated])

  return isAuthenticating ? (
    <LoadingPanel />
  ) : (
    <Routes>
      {routes
        .filter((r) => r.app == null || userAccess.includes(r.app))
        .map((r) => {
          return (
            <Route
              exact
              name={r.name}
              key={r.path}
              path={r.path}
              element={
                ((isAuthenticated && r.visibility === 'private') ||
                  (!isAuthenticated && r.visibility === 'public') ||
                  r.visibility == null) &&
                r.redirect == null ? (
                  <Main showTopBar={r.showTopBar} showSideBar={r.showSideBar}>
                    {r.main()}
                  </Main>
                ) : (
                  <Navigate
                    to={{
                      pathname:
                        r.redirect != null
                          ? generateRedirect(r.redirect)
                          : r.visibility === 'private'
                          ? '/login'
                          : '/'
                    }}
                  />
                )
              }
            />
          )
        })}
      <Route exact path="*" element={<PageNotFoundView />} />
    </Routes>
  )
}
