import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { Stack, Typography } from '@mui/material'
import DialogContentText from '@mui/material/DialogContentText'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import produce from 'immer'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import ResponsiveDialog from '../../../../../components/ResponsiveDialog'
import { createAddress, editAddress } from '../api'
import { selectAddress } from '../slice'

function AddressDialog({ open, addressId, onSubmit, onClose, ...props }) {
  const dispatch = useDispatch()

  const { enqueueSnackbar } = useSnackbar()

  const workExchangeRef = useRef(null)
  const workExtensionRef = useRef(null)
  const faxExchangeRef = useRef(null)
  const faxExtensionRef = useRef(null)

  const address = useSelector(selectAddress(addressId))

  const [initialValues, setInitialValues] = useState({})

  const handleSubmit = (values) => {
    const isCreating = addressId == null

    const payload = produce(values, (draft) => {
      if (!isCreating) {
        draft.id = addressId
      }

      return draft
    })

    if (isCreating) {
      dispatch(createAddress(payload))
        .unwrap()
        .then(() => {
          enqueueSnackbar('Address created successfully', {
            variant: 'success'
          })
        })
        .catch(({ data }) => {
          const errorString = Object.values(data)[0]
          enqueueSnackbar(`Address creation failed: ${errorString}`, {
            variant: 'error'
          })
        })
    } else {
      dispatch(editAddress(payload))
        .unwrap()
        .then(() => {
          enqueueSnackbar('Address modified successfully', {
            variant: 'success'
          })
        })
        .catch(({ data }) => {
          const errorString = Object.values(data)[0]
          enqueueSnackbar(`Address modification failed: ${errorString}`, {
            variant: 'error'
          })
        })
    }

    if (onSubmit) {
      onSubmit(payload)
    }

    onClose()
  }

  const handleClose = () => {
    onClose()
  }

  useEffect(
    () =>
      setInitialValues({
        address1: address?.address1 ?? '',
        address2: address?.address2 ?? '',
        city: address?.city ?? '',
        state: address?.state ?? '',
        zip_code: address?.zip_code ?? '',
        country: address?.country ?? '',
        work_phone_area_code: address?.work_phone_area_code ?? '',
        work_phone_exchange: address?.work_phone_exchange ?? '',
        work_phone_extension: address?.work_phone_extension ?? '',
        fax_area_code: address?.fax_area_code ?? '',
        fax_exchange: address?.fax_exchange ?? '',
        fax_extension: address?.fax_extension ?? ''
      }),
    [address]
  )

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={yup.object({
        address1: yup.string('Enter street address'),
        address2: yup.string('Enter street address (2)'),
        city: yup.string('Enter city'),
        state: yup.string('Enter state'),
        zip_code: yup.string('Enter ZIP code'),
        country: yup.string('Enter country'),
        work_phone_area_code: yup
          .string('Enter area code (work)')
          .max(3, 'Exchange cannot exceed 3 characters'),
        work_phone_exchange: yup
          .string('Enter exchange (work)')
          .max(7, 'Exchange cannot exceed 7 characters'),
        work_phone_extension: yup
          .string('Enter extensiom (work)')
          .max(10, 'Extension cannot exceed 10 characters'),
        fax_area_code: yup
          .string('Enter area code (fax)')
          .max(3, 'Exchange cannot exceed 3 characters'),
        fax_exchange: yup
          .string('Enter exchange (fax)')
          .max(7, 'Exchange cannot exceed 7 characters'),
        fax_extension: yup
          .string('Enter extensiom (fax)')
          .max(10, 'Extension cannot exceed 10 characters')
      })}
      onSubmit={handleSubmit}
    >
      {({ values, submitForm, setFieldValue, handleChange }) => (
        <Form>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ResponsiveDialog
              title={`${addressId ? 'Edit' : 'New'} Address`}
              open={open}
              onClose={handleClose}
              onSubmit={() => {
                submitForm()
              }}
              {...props}
            >
              <DialogContentText>
                To configure this address, please enter all required details
                here.
              </DialogContentText>
              <Stack spacing={2}>
                <Typography variant="h4">General</Typography>
                <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
                  <Field
                    component={TextField}
                    fullWidth
                    name="address1"
                    label="Address Line 1"
                  />
                  <Field
                    component={TextField}
                    required
                    fullWidth
                    name="address2"
                    label="Address Line 2"
                  />
                </Stack>
                <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
                  <Field
                    component={TextField}
                    required
                    fullWidth
                    name="city"
                    label="City"
                  />
                  <Field
                    component={TextField}
                    fullWidth
                    name="state"
                    label="State"
                  />
                  <Field
                    component={TextField}
                    fullWidth
                    name="zip_code"
                    label="ZIP"
                  />
                </Stack>
                <Field
                  component={TextField}
                  fullWidth
                  name="country"
                  label="Country"
                />
              </Stack>
              <Typography variant="h4">Contact</Typography>
              <Stack
                spacing={2}
                direction="row"
                sx={{ '& > .MuiFormControl-root': { width: '100%' } }}
              >
                <Field
                  component={TextField}
                  fullWidth
                  maxLength={3}
                  inputProps={{
                    maxLength: 3
                  }}
                  onChange={(e) => {
                    if (e.target.value.length == 3) {
                      workExchangeRef.current.focus()
                    }
                    handleChange(e)
                  }}
                  name="work_phone_area_code"
                  label="Work Area Code"
                />
                <Field
                  component={TextField}
                  fullWidth
                  maxLength={7}
                  inputProps={{
                    ref: (f) => (workExchangeRef.current = f),
                    maxLength: 7
                  }}
                  onChange={(e) => {
                    if (e.target.value.length == 7) {
                      workExtensionRef.current.focus()
                    }
                    handleChange(e)
                  }}
                  name="work_phone_exchange"
                  label="Work Exchange"
                />
                <Field
                  component={TextField}
                  fullWidth
                  maxLength={10}
                  inputProps={{
                    ref: (f) => (workExtensionRef.current = f),
                    maxLength: 10
                  }}
                  name="work_phone_extension"
                  label="Work Extension"
                />
              </Stack>
              <Stack
                spacing={2}
                direction="row"
                sx={{ '& > .MuiFormControl-root': { width: '100%' } }}
              >
                <Field
                  component={TextField}
                  fullWidth
                  maxLength={3}
                  inputProps={{
                    maxLength: 3
                  }}
                  onChange={(e) => {
                    if (e.target.value.length == 3) {
                      faxExchangeRef.current.focus()
                    }
                    handleChange(e)
                  }}
                  name="fax_area_code"
                  label="Fax Area Code"
                />
                <Field
                  component={TextField}
                  fullWidth
                  maxLength={7}
                  inputProps={{
                    maxLength: 7,
                    ref: (f) => (faxExchangeRef.current = f)
                  }}
                  onChange={(e) => {
                    if (e.target.value.length == 7) {
                      faxExtensionRef.current.focus()
                    }
                    handleChange(e)
                  }}
                  name="fax_exchange"
                  label="Fax Exchange"
                />
                <Field
                  component={TextField}
                  fullWidth
                  maxLength={10}
                  inputProps={{
                    ref: (f) => (faxExtensionRef.current = f),
                    maxLength: 10
                  }}
                  Inpu
                  name="fax_extension"
                  label="Fax Extension"
                />
              </Stack>
            </ResponsiveDialog>
          </LocalizationProvider>
        </Form>
      )}
    </Formik>
  )
}

AddressDialog.propTypes = {
  open: PropTypes.bool,
  addressId: PropTypes.number,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
}

export default AddressDialog
