/* eslint-disable react/prop-types */
import AddIcon from '@mui/icons-material/Add'
import { Button, IconButton, Link, Tooltip } from '@mui/material'
import { format, parseISO } from 'date-fns'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import React, { Fragment, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import { DEFAULT_DATETIME_FORMAT } from '../../../../../app/constants'
import EnhancedTable from '../../../../../components/EnhancedTable'
import { createCallBack } from '../../../calendar/api'
import { createComment, editComment, loadComments, removeComment } from '../api'
import { CallLogDialog } from '../components'

const columns = [
  {
    id: 'updated',
    label: 'Date',
    minWidth: 170,
    sortable: true,
    cellRenderer: (datum) => format(parseISO(datum), DEFAULT_DATETIME_FORMAT)
  },
  {
    id: 'text',
    label: 'Comment'
  },
  {
    id: 'product',
    label: 'Product',
    minWidth: 170,
    cellRenderer: (datum) =>
      datum ? (
        <Link
          component={RouterLink}
          color="text.primary"
          to={`/common-types/products/${datum.id}`}
        >
          {_.get(datum, 'name', 'Unknown')}
        </Link>
      ) : (
        'None'
      )
  },
  {
    id: 'user',
    label: 'User',
    minWidth: 170,
    cellRenderer: (datum, row) => {
      if (row.user == null) {
        if (row.user_name == null) {
          return 'None'
        }
        return row.user_name
      }
      const userNames = []
      if (datum.first_name) {
        userNames.push(datum.first_name)
      }
      if (datum.last_name) {
        userNames.push(datum.last_name)
      }
      return (
        <Link
          component={RouterLink}
          color="text.primary"
          to={`/users/${datum.id}`}
        >
          {userNames.join(' ')}
        </Link>
      )
    }
  }
]

function CallLogTabView({ organizationId }) {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const table = useRef()

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [focusedId, setFocusedId] = useState(null)

  const getDataFetcher = () => (queryParams) =>
    dispatch(
      loadComments({ organization: organizationId, ...queryParams })
    ).unwrap()

  const handleSubmit = (values) => {
    if (_.get(values, 'id', null) != null) {
      dispatch(editComment(values))
        .unwrap()
        .then(() => {
          enqueueSnackbar('Update successful', {
            variant: 'success'
          })
          table.current.loadData()
        })
        .catch((err) => {
          console.log(err)
          enqueueSnackbar('Update failed', {
            variant: 'error'
          })
        })
    } else {
      const {
        organization,
        user,
        product,
        call_date,
        call_time,
        text,
        is_urgent,
        call_type
      } = values
      const commentValues = {
        organization,
        user,
        product,
        call_time,
        text
      }
      const callBackValues = {
        organization,
        user,
        product,
        call_date,
        call_type,
        text,
        is_urgent
      }
      dispatch(createComment(values))
        .unwrap()
        .then(() => {
          enqueueSnackbar('Creation successful', {
            variant: 'success'
          })
          table.current.loadData()
        })
        .catch((err) => {
          console.log(err)
          enqueueSnackbar('Creation failed', {
            variant: 'error'
          })
        })
        .then(() => {
          dispatch(createCallBack(callBackValues))
            .then(() => {
              enqueueSnackbar('Call back creation success', {
                variant: 'success'
              })
            })
            .catch((err) => {
              console.log(err)
              enqueueSnackbar('Call back creation failed', {
                variant: 'error'
              })
            })
        })
    }
  }

  const handleDelete = (id) => {
    dispatch(removeComment(id))
      .unwrap()
      .then(() => {
        if (isDialogOpen) {
          setIsDialogOpen(false)
        }
        enqueueSnackbar('Deletion successful', {
          variant: 'success'
        })
        table.current.loadData()
      })
      .catch(() => {
        enqueueSnackbar('Deletion failed', {
          variant: 'error'
        })
      })
  }

  return (
    <Fragment>
      <EnhancedTable
        ref={table}
        title="Call Comments"
        editable
        selectable
        hoverable
        searchable
        columns={columns}
        fetchData={getDataFetcher()}
        defaultOrderBy="updated"
        defaultOrder="desc"
        actions={[
          <Tooltip key="new" title="Add New">
            <IconButton
              onClick={() => {
                setFocusedId(null)
                setIsDialogOpen(true)
              }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        ]}
        onEdit={(row) => {
          setFocusedId(row.id)
          setIsDialogOpen(true)
        }}
      />
      <CallLogDialog
        organizationId={organizationId}
        open={isDialogOpen}
        callLogId={focusedId}
        onSubmit={handleSubmit}
        onClose={() => {
          setIsDialogOpen(false)
          setFocusedId(null)
        }}
        extraActions={
          focusedId != null
            ? [
                <Button
                  key="delete"
                  color="error"
                  onClick={() => handleDelete(focusedId)}
                >
                  Delete
                </Button>
              ]
            : []
        }
      />
    </Fragment>
  )
}

export default CallLogTabView
