import { createAsyncThunk } from '@reduxjs/toolkit'
import { normalize } from 'normalizr'

import {
  deleteGroup,
  fetchGroup,
  fetchGroups,
  patchGroup,
  postGroup
} from '../../../../api/group'
import GroupSchema from './schema'

export const loadGroups = createAsyncThunk(
  'groups/fetchMany',
  async (queryParams, { rejectWithValue }) => {
    try {
      const response = await fetchGroups(queryParams)
      response.normalized = normalize(response.data, [GroupSchema])
      return response
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const loadGroup = createAsyncThunk(
  'groups/fetchOne',
  async (groupId, { rejectWithValue }) => {
    try {
      const response = await fetchGroup(groupId)
      response.normalized = normalize(response.data, GroupSchema)
      return response
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const createGroup = createAsyncThunk(
  'groups/create',
  async (data, { rejectWithValue }) => {
    try {
      const response = await postGroup(data)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const editGroup = createAsyncThunk(
  'groups/edit',
  async ({ id, ...data }, { rejectWithValue }) => {
    try {
      const response = await patchGroup(id, data)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const removeGroup = createAsyncThunk(
  'groups/delete',
  async (groupId, { rejectWithValue }) => {
    try {
      await deleteGroup(groupId)
      return groupId
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)
