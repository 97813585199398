import { Edit } from '@mui/icons-material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tab,
  Typography,
  useMediaQuery
} from '@mui/material'
import { format, parse } from 'date-fns'
import { PropTypes } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSearchParams } from 'react-router-dom'

import {
  Address,
  Contact,
  Detail,
  LoadingPanel,
  StatusDetail
} from '../../../../components'
import { loadCommonTypes } from '../../common-types/api'
import { selectCommonType } from '../../common-types/slice'
import { AddressDialog } from '../addresses/components'
import { selectAddresses } from '../addresses/slice'
import { editOrganization } from '../api'
import { CallLogTabView } from '../comments/views'
import { ContactDialog } from '../contacts/components'
import { selectContacts } from '../contacts/slice'
import { DeliveryTabView } from '../deliveries/views'
import { selectOrganization } from '../slice'
import { formatOrganization } from '../utils'
import { OrganizationProductsTabView } from '../views'
import OrganizationDialog from './OrganizationDialog'

function OrganizationDetail({ organizationId }) {
  const dispatch = useDispatch()

  const [data, setData] = useState(null)
  const [tab, setTab] = useState('0')
  const [selectedContactId, setSelectedContactId] = useState(null)
  const [isContactDialogOpen, setIsContactDialogOpen] = useState(false)
  const [selectedAddressId, setSelectedAddressId] = useState(null)
  const [isAddressDialogOpen, setIsAddressDialogOpen] = useState(false)
  const [isOrganizationDialogOpen, setIsOrganizationDialogOpen] =
    useState(false)

  const organization = useSelector(selectOrganization(organizationId))
  const leadSource = useSelector(selectCommonType(data?.lead_source))
  const organizationType = useSelector(selectCommonType(data?.org_type))
  const addresses = useSelector(selectAddresses(organization?.addresses))
  const contacts = useSelector(selectContacts(organization?.contacts))

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('lg'))

  useEffect(() => {
    dispatch(loadCommonTypes()).unwrap()
  }, [])

  useEffect(() => {
    setData(formatOrganization(organization))
  }, [organization])

  const getPhoneNumber = (record, key, suffix = '_phone') => {
    const areaCode = record[`${key}${suffix}_area_code`]
    var exchange = record[`${key}${suffix}_exchange`]
    const extension = record[`${key}${suffix}_extension`]

    if (areaCode === 'NULL' || exchange === 'NULL') {
      return null
    }

    if (exchange != null) {
      if (exchange.length === 7) {
        exchange = exchange.substring(0, 3) + '-' + exchange.substring(3)
      }
    } else {
      exchange = '-'
    }

    var phoneNumber = `(${areaCode}) ${exchange}`

    if (extension !== 'NULL') {
      return phoneNumber + ` +${extension}`
    }

    return phoneNumber
  }

  const renderMeta = () => (
    <Grid item lg={3} sm={6} xs={12}>
      <Typography variant="h6">Meta</Typography>
      <Divider sx={{ my: 1 }} />
      <Detail title="Created" value={data.created} />
      <Detail title="Updated" value={data.updated} />
    </Grid>
  )

  const renderAddresses = () =>
    addresses.map((a) => (
      <Grid key={a.id} item lg={6} xs={12}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="h6">Address</Typography>
          <IconButton
            size="small"
            onClick={() => {
              setSelectedAddressId(a.id)
              setIsAddressDialogOpen(true)
            }}
          >
            <Edit />
          </IconButton>
        </Stack>
        <Divider sx={{ my: 1 }} />
        <Address
          street={a.address1}
          streetExtra={a.address2}
          city={a.city}
          state={a.state}
          country={a.country}
          zip={a.zip_code}
        />
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Detail title="Work" value={getPhoneNumber(a, 'work')} nullText="-" />
          <Detail
            title="Fax"
            value={getPhoneNumber(a, 'fax', '')}
            nullText="-"
          />
        </Stack>
      </Grid>
    ))

  const renderContacts = () => {
    var t = new Date()
    return contacts.map((a) => {
      return (
        <Grid key={a.id} item lg={6} xs={12} sx={{ mb: 4 }}>
          <Contact
            contactName={a.contact_type_name}
            prefix={a.prefix}
            firstName={a.first_name}
            lastName={a.last_name}
            suffix={a.suffix}
            jobTitle={a.job_title}
            email={a.email}
            street={a.address1}
            streetExtra={a.address2}
            city={a.city}
            state={a.state}
            country={a.country}
            zip={a.zip_code}
            homePhone={getPhoneNumber(a, 'home')}
            workPhone={getPhoneNumber(a, 'work')}
            cellPhone={getPhoneNumber(a, 'cell')}
            availabilityFrom={
              a.availability_start
                ? format(
                    parse(
                      a.availability_start,
                      'hh:mm:ss',
                      new Date(
                        t.getUTCFullYear(),
                        t.getUTCMonth(),
                        t.getUTCDay()
                      )
                    ),
                    'H:mm a'
                  )
                : null
            }
            availabilityTo={
              a.availability_end
                ? format(
                    parse(
                      a.availability_end,
                      'hh:mm:ss',
                      new Date(
                        t.getUTCFullYear(),
                        t.getUTCMonth(),
                        t.getUTCDay()
                      )
                    ),
                    'H:mm a'
                  )
                : null
            }
            timeZone={a.time_zone}
            notes={a.notes}
            onEdit={() => {
              setSelectedContactId(a.id)
              setIsContactDialogOpen(true)
            }}
          />
        </Grid>
      )
    })
  }

  return data === null ? (
    <LoadingPanel />
  ) : (
    <TabContext value={tab}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList
          onChange={(event, newTab) => setTab(newTab)}
          aria-label="basic tabs example"
        >
          <Tab label="General" value="0" />
          <Tab label="Delivery" value="1" />
          <Tab label="Call Log" value="2" />
          <Tab label="Products" value="3" />
        </TabList>
      </Box>
      <TabPanel value="0">
        <Grid container spacing={4}>
          <Grid item lg={3} sm={6} xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
            >
              <Typography variant="h6">
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={2}
                  alignItems="center"
                >
                  <span>Details</span>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setIsOrganizationDialogOpen(true)
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Stack>
              </Typography>
              <StatusDetail title="Active" active={organization.is_active} />
              <OrganizationDialog
                open={isOrganizationDialogOpen}
                organizationId={organizationId}
                onSubmit={(values) => {
                  console.log(values)
                  dispatch(editOrganization({ id: organizationId, ...values }))
                }}
                onClose={() => {
                  setIsOrganizationDialogOpen(false)
                }}
              />
            </Stack>
            <Divider sx={{ my: 1 }} />
            <Detail title="Name" value={data.name} />
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Detail
                title="Type"
                value={organizationType?.name}
                to={{
                  pathname: '/common-types/organization-types/',
                  search: `?${createSearchParams({
                    selected: organizationType.id
                  })}`
                }}
              />
              <Detail
                title="Lead Source"
                value={leadSource?.name}
                to={{
                  pathname: '/common-types/lead-sources/',
                  search: `?${createSearchParams({
                    selected: leadSource.id
                  })}`
                }}
              />
            </Stack>
            <Detail title="Group Size" value={data?.group_size ?? 0} />
          </Grid>
          {isTablet && renderMeta()}
          {renderAddresses()}
          <AddressDialog
            open={isAddressDialogOpen}
            addressId={selectedAddressId}
            onClose={() => {
              setIsAddressDialogOpen(false)
              setSelectedAddressId(null)
            }}
          />
          {isDesktop && renderMeta()}
          <Grid container item columnSpacing={4}>
            <Grid item xs={12}>
              <Typography variant="h5">Contacts</Typography>
              <Divider sx={{ my: 1 }} />
            </Grid>
            {renderContacts()}
            <ContactDialog
              open={isContactDialogOpen && selectedContactId != null}
              contactId={selectedContactId}
              onClose={() => {
                setIsContactDialogOpen(false)
                setSelectedContactId(null)
              }}
            />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value="1" sx={{ p: 0 }}>
        <DeliveryTabView organizationId={organization.id} />
      </TabPanel>
      <TabPanel value="2" sx={{ p: 0 }}>
        <CallLogTabView organizationId={organization.id} />
      </TabPanel>
      <TabPanel value="3" sx={{ p: 0 }}>
        <OrganizationProductsTabView organizationId={organization.id} />
      </TabPanel>
    </TabContext>
  )
}

OrganizationDetail.propTypes = {
  organizationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired
}

export default OrganizationDetail
