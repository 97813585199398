import {
  createEntityAdapter,
  createSelector,
  createSlice
} from '@reduxjs/toolkit'
import { format, parse } from 'date-fns'
import produce from 'immer'

import { DEFAULT_DATE_FORMAT } from '../../../app/constants'
import {
  createCallBack,
  editCallBack,
  loadCallBack,
  loadCallBacks,
  removeCallBack
} from './api'

const callBacksAdapter = createEntityAdapter()

const slice = createSlice({
  name: 'call-backs',
  initialState: callBacksAdapter.getInitialState({
    view: {
      date: format(new Date(), DEFAULT_DATE_FORMAT),
      mode: 'month'
    }
  }),
  reducers: {
    setDate(state, action) {
      const { payload } = action
      if (payload instanceof Date) {
        state.view.date = format(payload, DEFAULT_DATE_FORMAT)
      } else {
        state.view.date = payload
      }
    },
    setMode(state, action) {
      state.view.mode = action.payload
    }
  },
  extraReducers: {
    [loadCallBacks.fulfilled]: (state, action) => {
      const {
        normalized: { entities }
      } = action.payload
      callBacksAdapter.upsertMany(state, entities.callBacks ?? {})
    },
    [loadCallBacks.rejected]: (state, action) => {
      console.log(action)
    },
    [loadCallBack.fulfilled]: (state, action) => {
      callBacksAdapter.upsertOne(state, action.payload)
    },
    [loadCallBack.rejected]: (state, action) => {
      console.log(action)
    },
    [createCallBack.fulfilled]: (state, action) => {
      callBacksAdapter.addOne(state, action.payload)
    },
    [createCallBack.rejected]: (state, action) => {
      console.log(action)
    },
    [editCallBack.fulfilled]: (state, action) => {
      callBacksAdapter.upsertOne(state, action.payload)
    },
    [editCallBack.rejected]: (state, action) => {
      console.log(action)
    },
    [removeCallBack.fulfilled]: (state, action) => {
      callBacksAdapter.removeOne(state, action.payload)
    },
    [removeCallBack.rejected]: (state, action) => {
      console.log(action)
    }
  }
})

export const selectCallBackEntities = (state) => state.callBacks

export const { selectById } = callBacksAdapter.getSelectors(
  selectCallBackEntities
)

export const selectCallBack = (id) => (state) => selectById(state, id)

export const getCalendarViewProperties = createSelector(
  selectCallBackEntities,
  (state) =>
    produce(state, (draft) => {
      draft.view.date = parse(draft.view.date, DEFAULT_DATE_FORMAT, new Date())
      return draft
    })
)

export const { setMode: setCalendarMode, setDate: setCalendarDate } =
  slice.actions

const reducer = slice.reducer
export default reducer
