import apiClient from '../http-common'

export const ORGANIZATION_ENDPOINT = '/organization'

export const fetchOrganizations = (queryParams) =>
  apiClient
    .get(`${ORGANIZATION_ENDPOINT}/`, { params: queryParams })
    .then((res) => res.data)

export const fetchOrganization = (organizationId) =>
  apiClient
    .get(`${ORGANIZATION_ENDPOINT}/${organizationId}/`)
    .then((res) => res.data)

export const fetchOrganizationPdf = (organizationId) =>
  apiClient
    .get(`${ORGANIZATION_ENDPOINT}/${organizationId}/pdf/`)
    .then((res) => res.data)

export const postOrganization = (data) =>
  apiClient.post(`${ORGANIZATION_ENDPOINT}/`, data).then((res) => res.data)

export const patchOrganization = (organizationId, data) =>
  apiClient
    .patch(`${ORGANIZATION_ENDPOINT}/${organizationId}/`, data)
    .then((res) => res.data)

export const deleteOrganization = (organizationId) =>
  apiClient.delete(`${ORGANIZATION_ENDPOINT}/${organizationId}/`)

export const ORGANIZATION_PRODUCT_ENDPOINT = '/organization-product'

export const fetchOrganizationProducts = (queryParams) =>
  apiClient
    .get(`${ORGANIZATION_PRODUCT_ENDPOINT}/`, { params: queryParams })
    .then((res) => res.data)

export const fetchOrganizationProduct = (organizationProductId) =>
  apiClient
    .get(`${ORGANIZATION_PRODUCT_ENDPOINT}/${organizationProductId}/`)
    .then((res) => res.data)

export const postOrganizationProduct = (data) =>
  apiClient
    .post(`${ORGANIZATION_PRODUCT_ENDPOINT}/`, data)
    .then((res) => res.data)

export const patchOrganizationProduct = (organizationProductId, data) =>
  apiClient
    .patch(`${ORGANIZATION_PRODUCT_ENDPOINT}/${organizationProductId}/`, data)
    .then((res) => res.data)

export const deleteOrganizationProduct = (organizationProductId) =>
  apiClient.delete(`${ORGANIZATION_PRODUCT_ENDPOINT}/${organizationProductId}/`)
