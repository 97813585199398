import apiClient from '../http-common'

export const USER_ENDPOINT = '/user'

export const fetchUsers = (queryParams) =>
  apiClient
    .get(`${USER_ENDPOINT}/`, { params: queryParams })
    .then((res) => res.data)

export const fetchUser = (userId) =>
  apiClient.get(`${USER_ENDPOINT}/${userId}/`).then((res) => res.data)

export const postUser = (data) =>
  apiClient.post(`${USER_ENDPOINT}/`, data).then((res) => res.data)

export const patchUser = (userId, data) =>
  apiClient.patch(`${USER_ENDPOINT}/${userId}/`, data).then((res) => res.data)

export const putUserPassword = (userId, newPassword) =>
  apiClient
    .put(`${USER_ENDPOINT}/${userId}/password/`, { password: newPassword })
    .then((res) => res.data)

export const deleteUser = (userId) =>
  apiClient.delete(`${USER_ENDPOINT}/${userId}/`)

export const fetchAuthenticatedUser = () =>
  apiClient.get(`${USER_ENDPOINT}/me/`).then((res) => res.data)
