import { createAsyncThunk } from '@reduxjs/toolkit'

import { fetchGlobals } from '../../api/globals'

export const loadGlobals = createAsyncThunk(
  'globals/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchGlobals()
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)
