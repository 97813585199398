import { Button, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

import Page from '../components/Page'

function PageNotFoundView() {
  return (
    <Page
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        minHeight: '100vh'
      }}
    >
      <div style={{ marginBottom: '32px' }}>
        <Typography variant="h2" color={(theme) => theme.palette.primary.main}>
          404
        </Typography>
        <Typography variant="h6">
          The page or resource you requested does not exist
        </Typography>
      </div>
      <Button component={Link} to="/">
        Home
      </Button>
    </Page>
  )
}

export default PageNotFoundView
