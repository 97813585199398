import apiClient from '../http-common'

export const COMMENT_ENDPOINT = '/comment'

export const fetchComments = (queryParams) =>
  apiClient
    .get(`${COMMENT_ENDPOINT}/`, { params: queryParams })
    .then((res) => res.data)

export const fetchComment = (commentId) =>
  apiClient.get(`${COMMENT_ENDPOINT}/${commentId}/`).then((res) => res.data)

export const postComment = (data) =>
  apiClient.post(`${COMMENT_ENDPOINT}/`, data).then((res) => res.data)

export const patchComment = (commentId, data) =>
  apiClient
    .patch(`${COMMENT_ENDPOINT}/${commentId}/`, data)
    .then((res) => res.data)

export const deleteComment = (commentId) =>
  apiClient.delete(`${COMMENT_ENDPOINT}/${commentId}/`)
