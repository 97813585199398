const ACCESS_TOKEN_KEY = 'cc.auth.token.access'
const REFRESH_TOKEN_KEY = 'cc.auth.token.refresh'
const USER_KEY = 'cc.auth.user'

class TokenService {
  clearAll() {
    localStorage.clear()
  }

  clearTokens() {
    localStorage.removeItem(ACCESS_TOKEN_KEY)
    localStorage.removeItem(REFRESH_TOKEN_KEY)
  }

  logout() {
    this.clearTokens()
    localStorage.removeItem(USER_KEY)
  }

  parseTokens(payload) {
    this.token = payload.token
  }

  get isAuthenticated() {
    return this.accessToken != null
  }

  get user() {
    return localStorage.getItem(USER_KEY)
  }

  set user(value) {
    if (value === null) {
      localStorage.removeItem(USER_KEY)
    } else {
      localStorage.setItem(USER_KEY, value)
    }
  }

  get accessToken() {
    return localStorage.getItem(ACCESS_TOKEN_KEY)
  }

  set accessToken(value) {
    if (value === null) {
      localStorage.removeItem(ACCESS_TOKEN_KEY)
    } else {
      localStorage.setItem(ACCESS_TOKEN_KEY, value)
    }
  }

  get refreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_KEY)
  }

  set refreshToken(value) {
    if (value === null) {
      localStorage.removeItem(REFRESH_TOKEN_KEY)
    } else {
      localStorage.setItem(REFRESH_TOKEN_KEY, value)
    }
  }
}

const tokenService = new TokenService()

export default tokenService
