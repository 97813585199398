import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import React from 'react'

function MenuDivider({ variant, text }) {
  const cssRule =
    variant === 'center'
      ? ':before, :after'
      : variant === 'left'
      ? ':after'
      : ':before'
  return (
    <Box
      component="section"
      sx={{
        '>span': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          margin: '1.65em 0 -.1em .4em',
          color: (theme) => theme.palette.grey[400],
          padding: '0 0.35em',
          position: 'relative',
          top: '-1.2em',
          [cssRule]: {
            content: '""',
            borderTop: (theme) => `1px solid ${theme.palette.grey[400]}`,
            background: 'transparent',
            flex: 1,
            marginLeft: '0.5em'
          }
        }
      }}
    >
      <span>{text}</span>
    </Box>
  )
}

MenuDivider.propTypes = {
  variant: PropTypes.oneOf('left, center, right'),
  text: PropTypes.string.isRequired
}

MenuDivider.defaultProps = {
  variant: 'left'
}

export default MenuDivider
