/* eslint-disable no-unused-vars */
import AddIcon from '@mui/icons-material/Add'
import {
  Button,
  Chip,
  IconButton,
  Stack,
  Tooltip,
  useMediaQuery
} from '@mui/material'
import { green, red } from '@mui/material/colors'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import { useApp } from '../../../../app/context/AppContext'
import { ActivityStatus } from '../../../../components'
import BottomDrawer from '../../../../components/BottomDrawer'
import EnhancedTable from '../../../../components/EnhancedTable'
import Page from '../../../../components/Page'
import {
  selectAuthenticatedUser,
  selectAuthenticatedUserHasPermissions
} from '../../../auth/slice'
import { loadCommonTypes } from '../../common-types/api'
import {
  selectLeadSources,
  selectOrganizationTypes
} from '../../common-types/slice'
import { loadUsers } from '../../users/api'
import { selectUserEntities } from '../../users/slice'
import {
  createOrganization,
  editOrganization,
  loadOrganizations,
  removeOrganization
} from '../api'
import { OrganizationDetail, OrganizationDialog } from '../components'
import {
  getOrganizationsViewProperties,
  setOrganizationIsActiveFilter,
  setOrganizationLeadSourceFilter,
  setOrganizationTypeFilter,
  setOrganizationUserFilter,
  setOrganizationsOrder,
  setOrganizationsOrderBy,
  setOrganizationsPage,
  setOrganizationsSearch
} from '../slice'

const columns = [
  {
    id: 'id',
    label: 'ID',
    sortable: true,
    cellRenderer: (datum, row, rowIndex) => {
      return (
        <Button component={Link} to={`/organizations/${row.id}/`}>
          {datum}
        </Button>
      )
    }
  },
  {
    id: 'name',
    label: 'Name',
    sortable: true,
    cellRenderer: (datum, row, rowIndex) => {
      return (
        <Button component={Link} to={`/organizations/${row.id}/`}>
          {datum}
        </Button>
      )
    }
  },
  {
    id: 'cities',
    label: 'Cities',
    sortable: false,
    cellRenderer: (datum, row) => {
      return (
        <Stack direction="row" spacing={1}>
          {datum.map((d, i) => {
            if (d[0] == '') {
              return null
            }
            return (
              <Chip
                key={`${row.id}-cityState-${i}`}
                size="small"
                label={`${d[0]}, ${d[1]}`}
              />
            )
          })}
        </Stack>
      )
    }
  },
  {
    id: 'is_active',
    label: 'Active',
    sortable: true,
    style: { width: '100px' },
    cellRenderer: (datum, row) => {
      return (
        <ActivityStatus
          size="small"
          active={row.is_active}
          activeColor={green[400]}
          inactiveColor={red[400]}
        />
      )
    }
  }
]

function OrganizationsView() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams({})
  const { enqueueSnackbar } = useSnackbar()
  const { isSideBarOpen, sideBarWidth } = useApp()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const table = useRef()

  const [isLoading, setIsLoading] = useState(true)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [focusedId, setFocusedId] = useState(null)
  const [selectedIds, setSelectedIds] = useState([])
  const [isBottomDrawerOpen, setIsBottomDrawerOpen] = useState(false)
  const [bottomDrawerHeight, setBottomDrawerHeight] = useState(null)
  const user = useSelector(selectAuthenticatedUser)

  const organizationTypes = useSelector(selectOrganizationTypes)
  const leadSources = useSelector(selectLeadSources)
  const users = useSelector(selectUserEntities)

  const [canEdit, canDelete] = useSelector(
    selectAuthenticatedUserHasPermissions([
      'change_organization',
      'delete_organization'
    ])
  )

  const { search, page, order, orderBy, filters } = useSelector(
    getOrganizationsViewProperties
  )

  const handleSubmit = (values) => {
    if (_.get(values, 'id', null) != null) {
      const { id, ...patchValues } = values
      dispatch(editOrganization({ id, ...patchValues }))
        .unwrap()
        .then((e) => {
          enqueueSnackbar('Update successful', {
            variant: 'success'
          })
          table.current.loadData()
        })
        .catch((err) => {
          enqueueSnackbar('Update failed', {
            variant: 'error'
          })
        })
    } else {
      dispatch(createOrganization(values))
        .unwrap()
        .then((res) => {
          enqueueSnackbar('Creation successful', {
            variant: 'success'
          })
          const {
            data: { id: newId }
          } = res
          navigate(`/organizations/${newId}`, { replace: true })
        })
        .catch((err) => {
          console.log(err)
          enqueueSnackbar('Creation failed', {
            variant: 'error'
          })
        })
    }

    setSelectedIds([])
  }

  const handleDelete = (input) => {
    const deleteSingle = (id) =>
      dispatch(removeOrganization(id))
        .unwrap()
        .then((res) => {
          if (isDialogOpen) {
            setIsDialogOpen(false)
          }
          enqueueSnackbar('Deletion successful', {
            variant: 'success'
          })
          table.current.loadData()
        })
        .catch((err) => {
          enqueueSnackbar('Deletion failed', {
            variant: 'error'
          })
        })

    if (Array.isArray(input)) {
      input.forEach((id) => deleteSingle(id))
    } else {
      deleteSingle(input)
    }
  }

  const getDataFetcher = () => (queryParams) =>
    dispatch(loadOrganizations(queryParams)).unwrap()

  useEffect(() => {
    dispatch(loadCommonTypes({ limit: 500 }))
    dispatch(loadUsers())
  }, [])

  useEffect(() => {
    if (isLoading) {
      const urlSearch = searchParams.get('search')
      const urlSelected = searchParams.get('selected') || []
      const urlPage = searchParams.get('page')
      const urlOrder = searchParams.get('order')
      const urlOrderBy = searchParams.get('orderBy')

      if (urlSearch) {
        dispatch(setOrganizationsSearch(urlSearch))
      }

      if (urlSelected.length > 0) {
        setSelectedIds(
          searchParams
            .get('selected')
            .split(',')
            .map((p) => parseInt(p))
        )
      }

      if (urlPage) {
        dispatch(setOrganizationsPage(parseInt(urlPage)))
      }

      if (urlOrder) {
        dispatch(setOrganizationsOrder(urlOrder))
      }

      if (urlOrderBy) {
        dispatch(setOrganizationsOrderBy(urlOrderBy))
      }

      setIsLoading(false)
    } else {
      const searchObject = { ...searchParams }

      if (selectedIds.length > 0) {
        searchObject.selected = selectedIds.join(',')
      } else {
        delete searchObject.selected
      }

      if (!_.isEmpty(search)) {
        searchObject.search = search
      } else {
        delete searchObject.search
      }

      if (page > 0) {
        searchObject.page = page
      } else {
        delete searchObject.page
      }

      if (!_.isEmpty(order)) {
        searchObject.order = order
      } else {
        delete searchObject.order
      }

      if (!_.isEmpty(orderBy)) {
        searchObject.orderBy = orderBy
      } else {
        delete searchObject.orderBy
      }

      setSearchParams(searchObject)
    }
  }, [searchParams, search, page, order, orderBy, selectedIds])

  console.log(users)

  return (
    <Page loading={isLoading}>
      <div
        style={{
          display: 'block',
          width: '100%',
          marginBottom: isBottomDrawerOpen ? `${bottomDrawerHeight}px` : '0px'
        }}
      >
        <EnhancedTable
          ref={table}
          editable={canEdit}
          selectable
          hoverable
          searchable
          deletable={canDelete}
          title="Organizations"
          columns={columns}
          filters={[
            {
              id: 'is_active',
              group: 'Organization Filters',
              label: 'Status',
              type: 'select',
              getParamValue: (option) => (option === 0 ? null : option === 1),
              onChange: (value) =>
                dispatch(setOrganizationIsActiveFilter(value)),
              options: [
                { label: 'All', value: 0 },
                { label: 'Active', value: 1 },
                { label: 'Inactive', value: 2 }
              ],
              defaultValue: filters.isActive
            },
            {
              id: 'org_type',
              group: 'Organization Filters',
              label: 'Type',
              type: 'select',
              getParamValue: (option) => (option === -1 ? null : option),
              onChange: (value) => dispatch(setOrganizationTypeFilter(value)),
              options: [{ label: 'All', value: -1 }].concat(
                Object.values(organizationTypes).map((t) => ({
                  label: t.name,
                  value: t.id
                }))
              ),
              defaultValue: filters.type
            },
            {
              id: 'lead_source',
              group: 'Organization Filters',
              label: 'Source',
              type: 'select',
              getParamValue: (option) => (option === -1 ? null : option),
              onChange: (value) =>
                dispatch(setOrganizationLeadSourceFilter(value)),
              options: [{ label: 'All', value: -1 }].concat(
                Object.values(leadSources).map((t) => ({
                  label: t.name,
                  value: t.id
                }))
              ),
              defaultValue: filters.leadSource
            },
            {
              id: 'products__user',
              group: 'Organization Filters',
              label: 'User',
              type: 'select',
              getParamValue: (option) => (option === -1 ? null : option),
              onChange: (value) => dispatch(setOrganizationUserFilter(value)),
              options: [{ label: 'All', value: -1 }].concat(
                Object.values(users.entities).map((t) => ({
                  label: `${t.first_name} ${t.last_name}`,
                  value: t.id
                }))
              ),
              defaultValue: filters.user
            }
          ]}
          fetchData={getDataFetcher()}
          defaultOrder={order || 'asc'}
          defaultOrderBy={orderBy || 'id'}
          defaultRowsPerPage={50}
          initialSearch={search}
          initialPage={page}
          initialSelected={selectedIds}
          onDelete={handleDelete}
          onEdit={(row) => {
            setFocusedId(row.id)
            setIsDialogOpen(true)
          }}
          actions={[
            <Tooltip key="new" title="Add New">
              <IconButton
                onClick={() => {
                  setFocusedId(null)
                  setIsDialogOpen(true)
                }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          ]}
          onSelectedChange={(selected) => {
            setFocusedId(selected.length === 1 ? selected[0] : null)
            setSelectedIds(selected)
          }}
          onSearch={(value) => {
            setFocusedId(null)
            dispatch(setOrganizationsSearch(value))
          }}
          onSortChange={(newOrder, newOrderBy) => {
            dispatch(setOrganizationsOrder(newOrder))
            dispatch(setOrganizationsOrderBy(newOrderBy))
          }}
          onPageChange={(value) => dispatch(setOrganizationsPage(value))}
        />
        <OrganizationDialog
          open={isDialogOpen}
          organizationId={focusedId}
          onSubmit={handleSubmit}
          onClose={() => {
            setIsDialogOpen(false)
          }}
          extraActions={
            focusedId != null
              ? [
                  <Button
                    key="delete"
                    color="error"
                    onClick={() => handleDelete(focusedId)}
                  >
                    Delete
                  </Button>
                ]
              : []
          }
        />
      </div>
      {isDesktop && (
        <BottomDrawer
          open={isBottomDrawerOpen}
          title={
            focusedId != null
              ? 'Organization Details'
              : selectedIds.length > 0
              ? `Selected: ${selectedIds.join(', ')}`
              : 'Select an Organization'
          }
          onToggle={() => setIsBottomDrawerOpen(!isBottomDrawerOpen)}
          PaperStyle={{
            marginLeft: isSideBarOpen ? `${sideBarWidth}px` : 0
          }}
          onHeightChange={(height) => {
            setBottomDrawerHeight(height)
          }}
        >
          {focusedId && <OrganizationDetail organizationId={focusedId} />}
        </BottomDrawer>
      )}
    </Page>
  )
}

export default OrganizationsView
