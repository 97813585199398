import KeyIcon from '@mui/icons-material/Key'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import PrintIcon from '@mui/icons-material/Print'
import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom'

import Page from '../../../../components/Page'
import { selectAuthenticatedUserHasPermissions } from '../../../auth/slice'
import { editUser, editUserPassword, loadUser, removeUser } from '../api'
import SetPasswordDialog from '../components/SetPasswordDialog'
import UserDetail from '../components/UserDetail'
import { selectUser } from '../slice'

function UserDetailView() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const user = useSelector(selectUser(id))

  const [isLoading, setIsLoading] = useState(false)
  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false)
  const [canEdit, canDelete] = useSelector(
    selectAuthenticatedUserHasPermissions(['change_user', 'delete_user'])
  )

  const handleDelete = () =>
    dispatch(removeUser(id))
      .unwrap()
      .then(() => {
        enqueueSnackbar('Deletion successful', {
          variant: 'success'
        })
        navigate('/users', { replace: true })
      })
      .catch((err) =>
        enqueueSnackbar('Deletion failed', {
          variant: 'error'
        })
      )

  const handleSetPassword = (values) =>
    dispatch(editUserPassword({ id, password: values.new_password }))
      .unwrap()
      .then(() => {
        enqueueSnackbar('Password changed successful', {
          variant: 'success'
        })
      })

  useEffect(() => {
    setIsLoading(true)
    dispatch(loadUser(id))
      .unwrap()
      .catch((err) => {
        console.log(err)
      })
      .finally(() => setIsLoading(false))
  }, [])

  return (
    <Page loading={isLoading}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          pl: { sm: 2 },
          py: 1.5,
          pr: { xs: 1, sm: 1 },
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`
        }}
      >
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link
            component={RouterLink}
            underline="hover"
            color="inherit"
            to="/users/"
          >
            Users
          </Link>
          {user && (
            <Typography color="text.primary">{user.id || 'Unknown'}</Typography>
          )}
        </Breadcrumbs>
        <Stack direction="row" spacing={2}>
          {canEdit && (
            <Tooltip title={user?.is_active ? 'Deactivate' : 'Activate'}>
              <IconButton
                onClick={() =>
                  dispatch(
                    editUser({
                      id,
                      is_active: !user.is_active
                    })
                  )
                    .unwrap()
                    .then(() => {
                      enqueueSnackbar(
                        `${
                          user?.is_active ? 'Deactivation' : 'Activation'
                        } successful`,
                        {
                          variant: 'success'
                        }
                      )
                    })
                }
              >
                <PowerSettingsNewIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Set Password">
            <IconButton onClick={() => setIsPasswordDialogOpen(true)}>
              <KeyIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Print">
            <IconButton>
              <PrintIcon />
            </IconButton>
          </Tooltip>
          {canDelete && (
            <Tooltip title="Delete">
              <Button color="error" onClick={handleDelete}>
                Delete
              </Button>
            </Tooltip>
          )}
        </Stack>
      </Box>
      {user && <UserDetail user={user} />}
      <SetPasswordDialog
        userId={id}
        open={isPasswordDialogOpen}
        onSubmit={handleSetPassword}
        onClose={() => setIsPasswordDialogOpen(false)}
      />
    </Page>
  )
}

UserDetailView.propTypes = {}

export default UserDetailView
