import BugReportIcon from '@mui/icons-material/BugReport'
import HelpIcon from '@mui/icons-material/Help'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import MenuIcon from '@mui/icons-material/Menu'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import {
  AppBar,
  Avatar,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Switch,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Box } from '@mui/system'
import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { useApp } from '../../app/context/AppContext'
import { selectAuthenticatedUser } from '../../features/auth/slice'
import tokenService from '../../services/token'
import AppSelect from './AppSelect'

function TopBar({ showMenuButton, onClickMenuButton, showAppsButton }) {
  const theme = useTheme()
  const { toggleColorMode } = useApp()
  const navigate = useNavigate()

  const user = useSelector(selectAuthenticatedUser)

  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const [profileMenu, setProfileMenu] = useState(null)
  const openProfileMenu = Boolean(profileMenu)
  const handleProfileClick = (event) => {
    setProfileMenu(event.currentTarget)
  }
  const handleProfileClose = () => {
    setProfileMenu(null)
  }

  const [helpMenu, setHelpMenu] = useState(null)
  const openHelpMenu = Boolean(helpMenu)
  const handleHelpClick = (event) => {
    setHelpMenu(event.currentTarget)
  }
  const handleHelpClose = () => {
    setHelpMenu(null)
  }

  const menuButton = showMenuButton ? (
    <IconButton
      size="large"
      edge="start"
      color="inherit"
      aria-label="menu"
      onClick={onClickMenuButton}
    >
      <MenuIcon />
    </IconButton>
  ) : null

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {menuButton}
        <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              mr: !isTablet ? 3 : 0
            }}
          >
            <img
              style={{
                padding: '16px',
                maxHeight: '64px',
                filter: 'invert(1)'
              }}
              alt="Logo"
              src="/content/logo/intouch-logo-black.png"
            />
          </Box>
        </Link>
        {isTablet && <div style={{ flexGrow: 1 }} />}
        {showAppsButton ? <AppSelect /> : null}
        <div style={{ flexGrow: 1 }} />

        {tokenService.isAuthenticated ? (
          <Fragment>
            <Tooltip title="Get help">
              <IconButton
                color="inherit"
                onClick={handleHelpClick}
                sx={{ mx: '4px' }}
              >
                <HelpIcon />
              </IconButton>
            </Tooltip>
            <Menu
              id="help-menu"
              anchorEl={helpMenu}
              open={openHelpMenu}
              onClose={handleHelpClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
              sx={{
                '& div': {
                  // this is just an example, you can use vw, etc.
                  minWidth: '100px'
                }
              }}
            >
              <MenuItem>
                <ListItemIcon>
                  <BugReportIcon />
                </ListItemIcon>
                <ListItemText>Report a Bug</ListItemText>
              </MenuItem>
            </Menu>
            <IconButton
              sx={{
                mx: '4px',
                padding: '6px'
              }}
              aria-controls="user-menu"
              aria-haspopup="true"
              aria-expanded={openProfileMenu ? 'true' : undefined}
              onClick={handleProfileClick}
            >
              <Avatar sx={{ width: 32, height: 32 }} />
            </IconButton>
            <Menu
              id="user-menu"
              anchorEl={profileMenu}
              open={openProfileMenu}
              onClose={handleProfileClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
              sx={{
                '& div': {
                  minWidth: '100px'
                }
              }}
            >
              <ListItem
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column'
                }}
              >
                <Typography variant="h6">
                  {user.first_name} {user.last_name}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Tooltip title="Settings">
                    <IconButton color="inherit" onClick={handleHelpClick}>
                      <SettingsRoundedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Logout">
                    <IconButton
                      color="inherit"
                      onClick={() => navigate('/logout', { replace: true })}
                    >
                      <LogoutRoundedIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </ListItem>
              <Divider />
              <ListItem>
                <FormGroup>
                  <FormControlLabel
                    sx={{
                      ml: 0
                    }}
                    control={
                      <Switch
                        sx={{ mt: '8px', mb: '8px' }}
                        size="small"
                        checked={theme.palette.mode === 'dark'}
                        onChange={toggleColorMode}
                        name="themeMode"
                      />
                    }
                    label="Dark Mode"
                  />
                </FormGroup>
              </ListItem>
            </Menu>
          </Fragment>
        ) : null}
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  showMenuButton: PropTypes.bool,
  onClickMenuButton: PropTypes.func,
  showAppsButton: PropTypes.bool
}

export default TopBar
