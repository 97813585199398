import { Edit } from '@mui/icons-material'
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Tooltip
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

import Address from './Address'
import Detail from './Detail'

function Contact({
  contactName,
  prefix,
  firstName,
  lastName,
  suffix,
  jobTitle,
  email,
  street,
  streetExtra,
  city,
  state,
  country,
  zip,
  homePhone,
  workPhone,
  cellPhone,
  availabilityFrom,
  availabilityTo,
  timeZone,
  notes,
  onEdit
}) {
  const editAction = onEdit ? (
    <Tooltip title="Edit">
      <IconButton onClick={onEdit}>
        <Edit />
      </IconButton>
    </Tooltip>
  ) : null

  return (
    <Card sx={{ px: 2, pb: 2, pt: 1 }}>
      <CardHeader title={contactName} sx={{ p: 0 }} action={editAction} />
      <CardContent>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Detail title="Prefix" value={prefix} nullText="-" />
          <Detail title="First Name" value={firstName} nullText="-" />
          <Detail title="Last Name" value={lastName} nullText="-" />
          <Detail title="Suffix" value={suffix} nullText="-" />
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Detail title="Job Title" value={jobTitle} nullText="-" />
          <Detail title="E-mail" value={email} nullText="-" />
        </Stack>
        <Address
          street={street}
          streetExtra={streetExtra}
          city={city}
          state={state}
          country={country}
          zip={zip}
        />
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Detail title="Home Phone" value={homePhone} nullText="-" />
          <Detail title="Work Phone" value={workPhone} nullText="-" />
          <Detail title="Cell Phone" value={cellPhone} nullText="-" />
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Detail
            title="Availability From"
            value={availabilityFrom}
            nullText="-"
          />
          <Detail title="Availability To" value={availabilityTo} nullText="-" />
          <Detail title="Time Zone" value={timeZone} nullText="-" />
        </Stack>
        <Stack direction="row">
          <Detail title="Notes" value={notes} nullText="-" />
        </Stack>
      </CardContent>
    </Card>
  )
}

Contact.propTypes = {
  contactName: PropTypes.string,
  prefix: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  suffix: PropTypes.string,
  jobTitle: PropTypes.string,
  email: PropTypes.string,
  street: PropTypes.string,
  streetExtra: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string,
  zip: PropTypes.string,
  homePhone: PropTypes.string,
  workPhone: PropTypes.string,
  cellPhone: PropTypes.string,
  availabilityFrom: PropTypes.string,
  availabilityTo: PropTypes.string,
  timeZone: PropTypes.string,
  notes: PropTypes.string,
  onEdit: PropTypes.func
}

export default Contact
