import PropTypes from 'prop-types'
import React, { Fragment } from 'react'

import LoadingPanel from './LoadingPanel'

function Page({ loading, ...props }) {
  const { children } = props

  return loading ? <LoadingPanel /> : <Fragment>{children}</Fragment>
}

Page.propTypes = {
  loading: PropTypes.bool,
  sx: PropTypes.any,
  children: PropTypes.any
}

Page.defaultProps = {
  loading: false
}

export default Page
