import apiClient from '../http-common'

export const CONTACT_ENDPOINT = '/contact'

export const fetchContacts = (queryParams) =>
  apiClient
    .get(`${CONTACT_ENDPOINT}/`, { params: queryParams })
    .then((res) => res.data)

export const fetchContact = (contactId) =>
  apiClient.get(`${CONTACT_ENDPOINT}/${contactId}/`).then((res) => res.data)

export const postContact = (data) =>
  apiClient.post(`${CONTACT_ENDPOINT}/`, data).then((res) => res.data)

export const patchContact = (contactId, data) =>
  apiClient
    .patch(`${CONTACT_ENDPOINT}/${contactId}/`, data)
    .then((res) => res.data)

export const deleteContact = (contactId) =>
  apiClient.delete(`${CONTACT_ENDPOINT}/${contactId}/`)
