import { format } from 'date-fns'
import produce from 'immer'

import { DEFAULT_DATE_FORMAT } from '../app/constants'

export function deriveDefaultValue(obj) {
  if (obj.defaultValue) {
    return obj.defaultValue
  }
  switch (typeof obj === 'object' ? obj.type : obj) {
    case 'bool':
      return false
    case 'string':
      return ''
    case 'number':
      return 0
    case 'date':
      return format(new Date(), DEFAULT_DATE_FORMAT)
    case 'time':
      return format(new Date(), 'hh:mm A')
    case 'datetime':
      return format(new Date(), 'yyyy-MM-dd, hh:mm A')
    case 'select':
      return 0
    default:
      return null
  }
}

export function reduceByKeyWithDefault(
  arr,
  key,
  defaultKey,
  removeKey = true,
  newKeys = []
) {
  return arr.reduce((r, e, i) => {
    const targetKeyValue = e[key]
    const newElement = produce(e, (mutableDraft) => {
      if (!mutableDraft.index) {
        mutableDraft.index = i
      }
      if (newKeys) {
        newKeys.forEach((newData) => {
          const newKeyValue = newData.value
          mutableDraft[newData.key] =
            typeof newKeyValue === 'function'
              ? newKeyValue(mutableDraft)
              : newKeyValue === 'index'
              ? i
              : newKeyValue
        })
      }
      if (removeKey) {
        delete mutableDraft[key]
      }
    })

    if (!targetKeyValue) {
      r[defaultKey] = r[defaultKey] || []
      r[defaultKey].push(newElement)
    } else {
      r[targetKeyValue] = r[targetKeyValue] || []
      r[targetKeyValue].push(newElement)
    }

    return r
  }, Object.create(null))
}

export function parseFiltersToActive(filters) {
  return filters.map((f, i) => {
    return { index: i, value: deriveDefaultValue(f) }
  })
}

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}
