import DeleteIcon from '@mui/icons-material/Delete'
import FilterListIcon from '@mui/icons-material/FilterList'
import RefreshIcon from '@mui/icons-material/Refresh'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterMoment from '@mui/lab/AdapterMoment'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TextField,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material'
import produce from 'immer'
import PropTypes from 'prop-types'
import React, { Fragment, useCallback, useEffect, useState } from 'react'

import { deriveDefaultValue, reduceByKeyWithDefault } from '../../utils/table'
import SearchField from '../SearchField'

const GROUP_KEY = 'group'
const DEFAULT_GROUP = 'Other'

function renderFilterItem(group, filter, index, onChange) {
  const { id, value, label, type, options } = filter
  switch (type) {
    case 'bool':
      return (
        <FormControlLabel
          label={label}
          control={
            <Checkbox
              name={id}
              size="small"
              checked={value}
              onChange={(e) => onChange(group, index, e.target.checked)}
            />
          }
        />
      )
    case 'string':
      return (
        <TextField
          fullWidth
          name={id}
          label={label}
          size="small"
          type="text"
          value={value}
          onChange={(e) => onChange(group, index, e.target.value)}
        />
      )
    case 'number':
      return (
        <TextField
          fullWidth
          name={id}
          label={label}
          size="small"
          type="number"
          value={value}
          onChange={(e) => onChange(group, index, e.target.value)}
        />
      )
    case 'select':
      if (!options) {
        return null
      }
      return (
        <FormControl fullWidth size="small">
          <InputLabel id={`filter-select-${id}=label`}>{label}</InputLabel>
          <Select
            labelId={`filter-select-${id}-label`}
            id={`filter-select-${id}`}
            value={value}
            label={label}
            onChange={(e) => onChange(group, index, e.target.value)}
          >
            {options.map((opt, index) => (
              <MenuItem key={index} value={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    default:
      return null
  }
}

const EnhancedTableToolbar = ({
  title,
  editable = false,
  searchable = false,
  dateRange = false,
  deletable = false,
  selected = [],
  filters = [],
  actions = [],
  search = '',
  startDate,
  endDate,
  allowsRefresh,
  onRefreshClick,
  onDateRangeChange,
  onApplyFilters,
  onClearFilters,
  onSearch,
  onDelete
}) => {
  const [filterMenuElement, setFilterMenuElement] = useState(null)
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false)
  const [filtersByGroup, setFiltersByCategory] = useState({})
  const [start, setStart] = useState(startDate)
  const [end, setEnd] = useState(endDate)
  const [searchText, setSearchText] = useState(search)

  const numSelected = selected.length

  useEffect(() => {
    setFiltersByCategory(
      reduceByKeyWithDefault(filters, GROUP_KEY, DEFAULT_GROUP, false, [
        { key: 'value', value: deriveDefaultValue },
        { key: 'defaultValue', value: deriveDefaultValue },
        { key: 'touched', value: false }
      ])
    )
  }, [filters])

  const setFilterValue = (group, index, value) => {
    const isDifferent = value !== filtersByGroup[group][index].defaultValue

    setFiltersByCategory(
      produce(filtersByGroup, (draft) => {
        draft[group][index].value = value
        draft[group][index].touched = isDifferent
      })
    )

    return filtersByGroup[group][index].index
  }

  const handleSearchChange = (value) => {
    setSearchText(value)
  }

  const handleSearch = () => {
    if (onSearch) {
      onSearch(searchText)
    }
  }

  const handleSearchKeyDown = (e) => {
    if (e.keyCode === 13) {
      onSearch(searchText)
    }
  }

  const handleStartChange = (value) => {
    setStart(value)
  }

  const handleEndChange = (value) => {
    setEnd(value)
  }

  const handleFilterButtonClick = useCallback((event) => {
    setFilterMenuElement(event.currentTarget)
    setIsFilterMenuOpen(true)
  }, [])

  const handleFilterMenuClose = useCallback(() => {
    setIsFilterMenuOpen(false)
    setFilterMenuElement(null)
  }, [])

  const handleFilterChange = useCallback(
    (group, index, value) => {
      return setFilterValue(group, index, value)
    },
    [filtersByGroup]
  )

  const handleApplyFilters = useCallback(() => {
    const changes = []

    Object.keys(filtersByGroup).forEach((key) => {
      filtersByGroup[key].forEach((filter) => {
        if (filter.onChange) {
          filter.onChange(filter.value)
        }
        changes.push({ index: filter.index, value: filter.value })
      })
    })

    if (onApplyFilters) {
      onApplyFilters(changes)
    }

    handleFilterMenuClose()
  }, [filtersByGroup])

  const handleClearFilters = useCallback(() => {
    setFiltersByCategory(
      reduceByKeyWithDefault(filters, GROUP_KEY, DEFAULT_GROUP, false, [
        { key: 'value', value: deriveDefaultValue }
      ])
    )

    if (onClearFilters) {
      onClearFilters()
    } else if (onApplyFilters) {
      onApplyFilters([])
    }

    handleFilterMenuClose()
  }, [filters])

  useEffect(() => {
    if (dateRange && onDateRangeChange) {
      onDateRangeChange(start, end)
    }
  }, [start, end])

  return (
    <Toolbar
      sx={{
        bgcolor: (theme) => theme.palette.background.paper,
        pl: { sm: 2 },
        py: { md: 1, sm: 2, xs: 2 },
        pr: { xs: 1, sm: 1 },
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`
      }}
    >
      <Grid container spacing={1} sx={{ alignItems: 'center' }}>
        <Grid
          item
          lg
          md={6}
          sm={6}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: { sm: 'start', xs: 'center' },
            textAlign: { sm: 'left', xs: 'center' },
            mb: { sm: 0, xs: 1 }
          }}
        >
          <span style={{ marginRight: '8px' }}>
            {numSelected > 1 ? (
              <Typography variant="h4">{numSelected} selected</Typography>
            ) : (
              title && <Typography variant="h4">{title}</Typography>
            )}
          </span>
          {actions}
        </Grid>

        {searchable && (
          <Grid item lg md={6} sm={6} xs>
            <SearchField
              fullWidth
              size="small"
              value={searchText}
              onChange={handleSearchChange}
              onKeyDown={handleSearchKeyDown}
              onSearch={handleSearch}
            />
          </Grid>
        )}

        {dateRange && (
          <Grid
            item
            lg={4}
            md={6}
            sm={12}
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              mr: 1
            }}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Start"
                size="small"
                value={start}
                onChange={(newDate) => handleStartChange(newDate)}
                renderInput={(params) => <TextField size="small" {...params} />}
                sx={{ width: '100%', mr: 1 }}
              />
              <DatePicker
                label="End"
                size="small"
                value={end}
                onChange={(newDate) => handleEndChange(newDate)}
                renderInput={(params) => <TextField size="small" {...params} />}
                sx={{ width: '100%' }}
              />
            </LocalizationProvider>
          </Grid>
        )}

        {allowsRefresh && onRefreshClick && (
          <Grid item>
            <Tooltip title="Refresh">
              <IconButton
                onClick={onRefreshClick}
                sx={{
                  mr: 1
                }}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}

        {numSelected > 0 && onDelete != null && deletable ? (
          <Grid item>
            <Tooltip title="Delete">
              <IconButton
                onClick={() => onDelete(selected)}
                sx={{
                  mr: 1
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        ) : (
          filters.length > 0 && (
            <Grid item>
              <Tooltip title="Filter">
                <IconButton
                  onClick={handleFilterButtonClick}
                  sx={{
                    mr: 1
                  }}
                >
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
              <Popover
                sx={{
                  '& .MuiPaper-root': {
                    maxWidth: '480px',
                    px: 3,
                    py: 2,
                    '& h6': { mb: 2 },
                    '& .MuiGrid-container': { mb: 1 }
                  }
                }}
                open={isFilterMenuOpen}
                anchorEl={filterMenuElement}
                onClose={handleFilterMenuClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 0 }}>
                  Filters
                </Typography>
                {Object.keys(filtersByGroup).map((group) => {
                  const filtersByType = reduceByKeyWithDefault(
                    filtersByGroup[group],
                    'type',
                    'unknown',
                    false,
                    [{ key: 'groupIndex', value: 'index' }]
                  )
                  return (
                    <Fragment key={group}>
                      <Typography
                        variant="h5"
                        sx={{ fontWeight: 'bold', mb: 2 }}
                      >
                        {group}
                      </Typography>
                      <Grid container>
                        {Object.keys(filtersByType).map((typeKey) =>
                          filtersByType[typeKey].map((filter) => (
                            <Grid
                              key={filter.id}
                              item
                              xs={typeKey == 'bool' ? 6 : 12}
                              sx={{ p: 1 }}
                            >
                              {renderFilterItem(
                                group,
                                filter,
                                filter.groupIndex,
                                handleFilterChange
                              )}
                            </Grid>
                          ))
                        )}
                      </Grid>
                    </Fragment>
                  )
                })}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Button onClick={() => handleClearFilters()}>
                    Clear Filters
                  </Button>
                  <Button color="success" onClick={() => handleApplyFilters()}>
                    Apply
                  </Button>
                </Box>
              </Popover>
            </Grid>
          )
        )}
        {/* <Grid item>{actions}</Grid> */}
      </Grid>
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  title: PropTypes.string,
  editable: PropTypes.bool,
  searchable: PropTypes.bool,
  dateRange: PropTypes.bool,
  deletable: PropTypes.bool,
  selected: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  filters: PropTypes.arrayOf(PropTypes.object),
  actions: PropTypes.arrayOf(PropTypes.element),
  search: PropTypes.string,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  allowsRefresh: PropTypes.bool,
  onRefreshClick: PropTypes.func,
  onDateRangeChange: PropTypes.func,
  onApplyFilters: PropTypes.func,
  onClearFilters: PropTypes.func,
  onSearch: PropTypes.func,
  onDelete: PropTypes.func
}

export default EnhancedTableToolbar
