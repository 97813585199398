import { TextField } from '@mui/material'
import DialogContentText from '@mui/material/DialogContentText'
import { useFormik } from 'formik'
import produce from 'immer'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as yup from 'yup'

import ResponsiveDialog from '../../../../../components/ResponsiveDialog'
import { selectGroup } from '../slice'

function GroupDialog({ groupId, open, onClose, onSubmit, ...props }) {
  const [initialValues, setInitialValues] = useState({})

  const group = useSelector(selectGroup(groupId))

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: yup.object().shape({
      name: yup.string('Enter type name').required('Name is required')
    }),
    onSubmit: (values) => {
      handleSubmit(values)
    }
  })

  const handleSubmit = (values) => {
    onSubmit(
      produce(values, (draft) => {
        if (groupId != null) {
          draft.id = groupId
        }
        return draft
      })
    )
    formik.resetForm()
    onClose()
  }

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  useEffect(
    () =>
      setInitialValues({
        name: group?.name ?? ''
      }),
    [group]
  )

  return (
    <ResponsiveDialog
      title={`${groupId ? 'Edit' : 'New'} Group`}
      open={open}
      onClose={handleClose}
      onSubmit={() => formik.submitForm()}
      {...props}
    >
      <DialogContentText>
        Please enter all required details here.
      </DialogContentText>
      <TextField
        required
        fullWidth
        name="name"
        type="text"
        label="Name"
        size="small"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
      />
    </ResponsiveDialog>
  )
}

GroupDialog.propTypes = {
  groupId: PropTypes.number,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default GroupDialog
