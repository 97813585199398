import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded'
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded'
import ContactsRoundedIcon from '@mui/icons-material/ContactsRounded'
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded'
import GroupRoundedIcon from '@mui/icons-material/GroupRounded'
import Inventory2RoundedIcon from '@mui/icons-material/Inventory2Rounded'
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded'
import MailIcon from '@mui/icons-material/MailRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import PhoneCallbackRoundedIcon from '@mui/icons-material/PhoneCallbackRounded'
import SourceRoundedIcon from '@mui/icons-material/SourceRounded'
import { CssBaseline, StyledEngineProvider } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import PropTypes from 'prop-types'
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useLocation } from 'react-router-dom'

import appService from '../../services/app'
import { getAppFromPath } from '../../utils/app'
import generateTheme from '../theme'

const Apps = [
  {
    id: 'organizations',
    name: 'Organizations',
    path: '/organizations',
    icon: <BusinessRoundedIcon />,
    color: '#C0B621',
    sideBar: [
      {
        section: 'Data',
        items: [
          {
            icon: <BusinessRoundedIcon />,
            name: 'Organizations',
            route: '/organizations'
          },
          {
            icon: <PhoneCallbackRoundedIcon />,
            name: 'Call Backs',
            route: '/organizations/call-backs'
          }
        ]
      }
    ]
  },
  {
    id: 'calendar',
    name: 'Calendar',
    path: '/calendar',
    icon: <CalendarTodayRoundedIcon />,
    color: '#AD2626'
  },
  {
    id: 'common-types',
    name: 'Common Types',
    path: '/common-types',
    icon: <ListAltRoundedIcon />,
    color: '#5D1583',
    sideBar: [
      {
        section: 'Categories',
        items: [
          {
            icon: <ContactsRoundedIcon />,
            name: 'Contact Prefixes',
            route: '/common-types/contact-prefixes'
          },
          {
            icon: <SourceRoundedIcon />,
            name: 'Lead Sources',
            route: '/common-types/lead-sources'
          },
          {
            icon: <ContactsRoundedIcon />,
            name: 'Organization Contacts',
            route: '/common-types/organization-contacts'
          },
          {
            icon: <BusinessRoundedIcon />,
            name: 'Organization Types',
            route: '/common-types/organization-types'
          },
          {
            icon: <Inventory2RoundedIcon />,
            name: 'Products',
            route: '/common-types/products'
          }
        ]
      }
    ]
  },
  {
    id: 'users',
    name: 'Users',
    path: '/users',
    icon: <GroupRoundedIcon />,
    color: '#26AD26',
    sideBar: [
      {
        section: 'Users & Groups',
        items: [
          {
            icon: <PersonRoundedIcon />,
            name: 'Users',
            route: '/users'
          },
          {
            icon: <GroupRoundedIcon />,
            name: 'Groups',
            route: '/users/groups'
          }
        ]
      }
    ]
  }
]

const AppContext = createContext()

export function AppProvider({ access, children }) {
  const location = useLocation()
  const rootPathName = `/${location.pathname.split('/')[1]}`

  const [availableApps, setAvailableApps] = useState(access)

  const [colorMode, setColorMode] = useState(appService.colorMode || 'light')

  const [currentApp, setCurrentApp] = useState(
    getAppFromPath(Apps, rootPathName)
  )

  const [sideBarWidth, setSideBarWidth] = useState(240)
  const [isSideBarOpen, setIsSideBarOpen] = useState(true)

  useEffect(() => {
    if (rootPathName == '/apps') {
      setCurrentApp(null)
    }
  }, [location.pathname])

  useEffect(() => {
    setAvailableApps(
      Apps.filter(
        (app) =>
          (currentApp == null || app.id !== currentApp.id) &&
          access.includes(app.id)
      )
    )
  }, [access, currentApp])

  const value = useMemo(
    () => ({
      colorMode,
      isSideBarOpen,
      sideBarWidth,
      availableApps,
      currentApp,
      changeApp: (app) => setCurrentApp(app),
      setSideBarWidth: (width) => setSideBarWidth(width),
      toggleSideBar: (open) =>
        setIsSideBarOpen(open != null ? open : !isSideBarOpen),
      toggleColorMode: () => {
        setColorMode((prevMode) => {
          const mode = prevMode === 'light' ? 'dark' : 'light'
          appService.colorMode = mode
          return mode
        })
      }
    }),
    [currentApp, availableApps, isSideBarOpen, colorMode, sideBarWidth]
  )

  AppProvider.propTypes = {
    access: PropTypes.array,
    children: PropTypes.object
  }

  const theme = useMemo(
    () => generateTheme({ borderRadius: 4, mode: colorMode }),
    [colorMode]
  )

  return (
    <AppContext.Provider value={value}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </AppContext.Provider>
  )
}

export function useApp() {
  const context = useContext(AppContext)
  if (context === undefined) {
    throw new Error('useApp must be used within a AppProvider')
  }
  return context
}

export default AppContext
