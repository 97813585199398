import {
  Box,
  Button,
  Divider,
  Paper,
  TextField,
  Typography,
  alpha,
  useMediaQuery
} from '@mui/material'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import * as yup from 'yup'

import { IntouchIcon } from '../../../icons'

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required')
})

function LoginForm({ error, onSubmit }) {
  const onMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmit(values.email, values.password)
    }
  })

  return (
    <Paper
      elevation={5}
      sx={{
        p: 2,
        width: '90%',
        maxWidth: '400px',
        textAlign: 'center',
        backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.5),
        backdropFilter: 'saturate(180%) blur(20px)'
      }}
    >
      <Box
        sx={{
          display: 'inline-flex',
          maxWidth: '96px',
          fill: 'white'
        }}
      >
        <IntouchIcon />
      </Box>
      <Typography
        variant="h2"
        color="white"
        sx={{ mt: 2, mb: 3, textTransform: 'uppercase' }}
      >
        Login
      </Typography>
      <Paper
        elevation={3}
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 4,
          '& > .MuiFormControl-root': { width: '100%' },
          '& > :not(style)': { m: 1 }
        }}
      >
        <TextField
          name="email"
          type="email"
          label="E-mail Address"
          size={onMobile ? 'medium' : 'small'}
          fullWidth={onMobile}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={
            error || (formik.touched.email && Boolean(formik.errors.email))
          }
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          name="password"
          type="password"
          label="Password"
          size={onMobile ? 'medium' : 'small'}
          fullWidth={onMobile}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={
            error ||
            (formik.touched.password && Boolean(formik.errors.password))
          }
          helperText={formik.touched.password && formik.errors.password}
        />
        <Button
          color="primary"
          variant="contained"
          fullWidth={onMobile}
          type="submit"
          size={onMobile ? 'medium' : 'small'}
          sx={{ p: 1, minWidth: '25ch' }}
        >
          Submit
        </Button>
        <Button variant="text">Forgot Password?</Button>
      </Paper>
    </Paper>
  )
}

LoginForm.propTypes = {
  error: PropTypes.bool,
  onSubmit: PropTypes.func
}

export default LoginForm
