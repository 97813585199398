import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { FormControlLabel, Stack, Typography } from '@mui/material'
import DialogContentText from '@mui/material/DialogContentText'
import { format, formatISO } from 'date-fns'
import { Field, Form, Formik } from 'formik'
import { Switch } from 'formik-mui'
import { DateTimePicker } from 'formik-mui-lab'
import produce from 'immer'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import { API_TIME_FORMAT } from '../../../../../app/constants'
import ProductAutocomplete from '../../../../../components/ProductAutocomplete'
import ResponsiveDialog from '../../../../../components/ResponsiveDialog'
import { createCallBack, editCallBack } from '../../../calendar/api'
import { selectCallBack } from '../../../calendar/slice'
import { loadCommonTypes } from '../../../common-types/api'
import { formatTime, packBits } from '../../utils'

function CallBackDialog({ open, callBackId, onSubmit, onClose, ...props }) {
  const dispatch = useDispatch()

  const { enqueueSnackbar } = useSnackbar()

  const callBack = useSelector(selectCallBack(callBackId))

  const [initialValues, setInitialValues] = useState({})

  const handleSubmit = (values) => {
    const isCreating = callBackId == null

    const payload = produce(values, (draft) => {
      if (!isCreating) {
        draft.id = callBackId
      }

      draft.days_of_week = packBits(draft.days_of_week)
      draft.availability_start = formatTime(
        draft.availability_start,
        API_TIME_FORMAT
      )
      draft.availability_end = formatTime(
        draft.availability_end,
        API_TIME_FORMAT
      )

      return draft
    })

    if (isCreating) {
      dispatch(createCallBack(payload))
        .unwrap()
        .then(() => {
          enqueueSnackbar('Call back created successfully', {
            variant: 'success'
          })
        })
        .catch(({ data }) => {
          const errorString = Object.values(data)[0]
          enqueueSnackbar(`Call back creation failed: ${errorString}`, {
            variant: 'error'
          })
        })
    } else {
      dispatch(editCallBack(payload))
        .unwrap()
        .then(() => {
          enqueueSnackbar('Call back modified successfully', {
            variant: 'success'
          })
        })
        .catch(({ data }) => {
          const errorString = Object.values(data)[0]
          enqueueSnackbar(`Call back modification failed: ${errorString}`, {
            variant: 'error'
          })
        })
        .finally(() => {
          if (onSubmit) {
            onSubmit(payload)
          }
        })
    }

    onClose()
  }

  const handleClose = () => {
    onClose()
  }

  useEffect(() => {
    dispatch(loadCommonTypes()).unwrap()
  }, [])

  useEffect(
    () =>
      setInitialValues({
        product: callBack?.product || '',
        call_date: callBack?.call_date || formatISO(new Date()),
        is_urgent: callBack?.is_urgent || false,
        is_complete: callBack?.is_complete || false,
        call_type: 0
      }),
    [callBack]
  )

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={yup.object({
        product: yup.number('Select product').required('Product is required'),
        call_date: yup
          .string('Enter call time')
          .required('Call time is required'),
        ix_urgent: yup.boolean(),
        is_complete: yup.boolean(),
        call_type: yup.number().required('Call type is required')
      })}
      onSubmit={handleSubmit}
    >
      {({ values, submitForm, setFieldValue }) => (
        <Form>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ResponsiveDialog
              title={`${callBackId ? 'Edit' : 'New'} Call Back`}
              open={open}
              onClose={handleClose}
              onSubmit={() => {
                submitForm()
              }}
              {...props}
            >
              <DialogContentText>
                To configure this call back, please enter all required details
                here.
              </DialogContentText>
              <Stack spacing={2}>
                <Typography variant="h4">General</Typography>
                <Stack spacing={2} direction="column">
                  <Field
                    fullWidth
                    name="product"
                    label="Product"
                    value={values.product}
                    inputProps={{
                      fullWidth: true
                    }}
                    component={ProductAutocomplete}
                  />
                  <Field
                    required
                    fullWidth
                    component={DateTimePicker}
                    name="call_date"
                    label="Call Time"
                  />
                  <FormControlLabel
                    label="Is Urgent"
                    control={
                      <Field
                        type="checkbox"
                        name="is_urgent"
                        component={Switch}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Is Complete"
                    control={
                      <Field
                        type="checkbox"
                        name="is_complete"
                        component={Switch}
                      />
                    }
                  />
                </Stack>
              </Stack>
            </ResponsiveDialog>
          </LocalizationProvider>
        </Form>
      )}
    </Formik>
  )
}

CallBackDialog.propTypes = {
  open: PropTypes.bool,
  callBackId: PropTypes.number,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
}

export default CallBackDialog
