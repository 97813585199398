/* eslint-disable react/prop-types */
import { Add } from '@mui/icons-material'
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import produce from 'immer'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { loadCommonTypes } from '../../../common-types/api'
import {
  selectLeadSources,
  selectOrganizationTypes
} from '../../../common-types/slice'

/*

FilterGroup {
    operand: 'OR', 'AND', 'NOR', 'NAND',
    children: [
        Filter {
            target: 'organization',
            field: ['type', 'is_active', 'source', 'group_size'],
            operand: ['EQ', 'NEQ', 'GTE', 'LTE', 'GT', 'LT'],
            value: <input>
        },
        ...
    ]
}

*/

const AvailableFilterGroupOperands = ['OR', 'AND', 'NOR', 'NAND']
const AvailableFilterOperands = ['EQ', 'NEQ']
const AvailableFilterNumberOperands = ['EQ', 'NEQ', 'LTE', 'GTE', 'LT', 'GT']
const AvailableTargets = {
  organization: {
    fields: [
      {
        label: 'Type',
        param: 'org_type',
        type: 'fk',
        source: 'common-type'
      },
      {
        label: 'Is Active',
        param: 'is_active',
        type: 'boolean'
      },
      {
        label: 'Source',
        param: 'lead_source',
        type: 'fk',
        source: 'common-type'
      },
      {
        label: 'Group Size',
        param: 'group_size',
        type: 'integer'
      }
    ]
  }
}

function Filter({ data, filterGroupIndex, index, onChange }) {
  const labelId = `filterGroup-${filterGroupIndex}-filter-${index}`

  const leadSources = useSelector(selectLeadSources)
  const organizationTypes = useSelector(selectOrganizationTypes)

  const [target, setTarget] = useState(null)
  const [field, setField] = useState(null)
  const [operand, setOperand] = useState(null)
  const [value, setValue] = useState(value)

  const handleChange = () => {
    onChange(filterGroupIndex, index, {
      target,
      field,
      operand,
      value
    })
  }

  useEffect(() => {
    setField(null)
  }, [target])

  useEffect(() => {
    setOperand(null)
  }, [field])

  useEffect(() => {
    if (operand == null) {
      setValue(null)
    }
  }, [operand])

  useEffect(() => {
    handleChange()
  }, [value])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        bgcolor: 'background.paper',
        borderLeft: '2px solid black',
        pl: 2,
        ml: 2
      }}
    >
      <Stack spacing={2} direction="row">
        <FormControl sx={{ minWidth: '200px' }}>
          <InputLabel id={labelId}>Target</InputLabel>
          <Select
            labelId={labelId}
            label="Target"
            value={target != null ? target : ''}
            onChange={(e) => {
              const {
                target: { value }
              } = e
              setTarget(value)
            }}
          >
            {Object.keys(AvailableTargets).map((target, targetIndex) => (
              <MenuItem key={`${labelId}-target-${targetIndex}`} value={target}>
                {target.charAt(0).toUpperCase() + target.slice(1)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {target != null && (
          <FormControl sx={{ minWidth: '200px' }}>
            <InputLabel id={`${labelId}-target-${target}-field`}>
              Field
            </InputLabel>
            <Select
              labelId={`${labelId}-target-${target}-field`}
              label="Field"
              value={field != null ? field : ''}
              onChange={(e) => {
                const {
                  target: { value }
                } = e
                setField(value)
              }}
            >
              {AvailableTargets[target].fields.map((field, fieldIndex) => (
                <MenuItem
                  key={`${labelId}-target-${target}-field-${fieldIndex}`}
                  value={fieldIndex}
                >
                  {field.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {field != null && (
          <FormControl sx={{ minWidth: '200px' }}>
            <InputLabel
              id={`${labelId}-target-${target}-field-${field}-operand`}
            >
              Operand
            </InputLabel>
            <Select
              labelId={`${labelId}-target-${target}-field-${field}-operand`}
              label="Operand"
              value={operand != null ? operand : ''}
              onChange={(e) => {
                const {
                  target: { value }
                } = e
                setOperand(value)
              }}
            >
              {AvailableTargets[target].fields[field].type === 'integer'
                ? AvailableFilterNumberOperands.map((operand, operandIndex) => (
                    <MenuItem
                      key={`${labelId}-target-${target}-field-${field}-operand-int-${operandIndex}`}
                      value={operandIndex}
                    >
                      {operand}
                    </MenuItem>
                  ))
                : AvailableFilterOperands.map((operand, operandIndex) => (
                    <MenuItem
                      key={`${labelId}-target-${target}-field-${field}-operand-nonint-${operandIndex}`}
                      value={operandIndex}
                    >
                      {operand}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        )}
        {target != null &&
          field != null &&
          operand != null &&
          (AvailableTargets[target].fields[field].type === 'boolean' ? (
            <FormControl sx={{ minWidth: '200px' }}>
              <InputLabel
                id={`${labelId}-target-${target}-field-${field}-operand-nonint-${operand}-value`}
              >
                Value
              </InputLabel>
              <Select
                labelId={`${labelId}-target-${target}-field-${field}-operand-nonint-${operand}-value`}
                label="Value"
                value={value || ''}
                onChange={(e) => {
                  const {
                    target: { value }
                  } = e
                  setValue(value)
                }}
              >
                <MenuItem
                  key={`${labelId}-target-${target}-field-${field}-operand-nonint-${operand}-value-true`}
                  value={true}
                >
                  True
                </MenuItem>
                <MenuItem
                  key={`${labelId}-target-${target}-field-${field}-operand-nonint-${operand}-value-false`}
                  value={false}
                >
                  False
                </MenuItem>
              </Select>
            </FormControl>
          ) : AvailableTargets[target].fields[field].type === 'fk' ? (
            <FormControl sx={{ minWidth: '200px' }}>
              <InputLabel
                id={`${labelId}-target-${target}-field-${field}-operand-fk-${operand}-value`}
              >
                Value
              </InputLabel>
              <Select
                labelId={`${labelId}-target-${target}-field-${field}-operand-fk-${operand}-value`}
                label="Value"
                value={value || ''}
                onChange={(e) => {
                  const {
                    target: { value }
                  } = e
                  setValue(value)
                }}
              >
                {AvailableTargets[target].fields[field].param === 'org_type'
                  ? Object.values(organizationTypes).map((x) => (
                      <MenuItem
                        key={`${labelId}-target-${target}-field-${field}-operand-fk-${operand}-value-orgType-${x.id}`}
                        value={x.id}
                      >
                        {x.name}
                      </MenuItem>
                    ))
                  : Object.values(leadSources).map((x) => (
                      <MenuItem
                        key={`${labelId}-target-${target}-field-${field}-operand-fk-${operand}-value-leadSource-${x.id}`}
                        value={x.id}
                      >
                        {x.name}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          ) : (
            <TextField
              label="Value"
              value={value || ''}
              onChange={(e) => {
                const {
                  target: { value }
                } = e
                setValue(value)
              }}
            />
          ))}
      </Stack>
    </Box>
  )
}

function QueryBuilder({ organizationId }) {
  const dispatch = useDispatch()

  const leadSources = useSelector(selectLeadSources)
  const organizationTypes = useSelector(selectOrganizationTypes)

  const [queryData, setQueryData] = useState([])
  const [canSubmit, setCanSubmit] = useState(false)

  useEffect(() => {
    if (
      Object.values(leadSources).length === 0 ||
      Object.values(organizationTypes).length === 0
    ) {
      dispatch(loadCommonTypes({ limit: 500 }))
    }
  }, [leadSources, organizationTypes])

  const addFilterGroup = () => {
    setQueryData(
      produce(queryData, (draft) => {
        draft.push({
          operand: 'AND',
          children: []
        })
        return draft
      })
    )
  }

  const removeFilterGroup = (index) => {
    setQueryData(
      produce(queryData, (draft) => {
        draft.splice(index, 1)
        return draft
      })
    )
  }

  const addFilter = (filterGroupIndex) => {
    setQueryData(
      produce(queryData, (draft) => {
        draft[filterGroupIndex].children.push({
          target: null,
          field: null,
          operand: null,
          value: null
        })
        return draft
      })
    )
  }

  const removeFilter = (filterGroupIndex, filterIndex) => {
    setQueryData(
      produce(queryData, (draft) => {
        draft[filterGroupIndex].children.splice(filterIndex, 1)
        return draft
      })
    )
  }

  const handleFilterChange = (filterGroupIndex, filterIndex, data) => {
    setQueryData(
      produce(queryData, (draft) => {
        draft[filterGroupIndex].children[filterIndex] = data
      })
    )
  }

  useEffect(() => {
    if (
      queryData.length > 0 &&
      queryData[0].children.length > 0 &&
      queryData[0].children[0].value != null
    ) {
      setCanSubmit(true)
    } else {
      setCanSubmit(false)
    }
  }, [queryData])

  return (
    <Box>
      <Tooltip title="Add Filter Group">
        <IconButton>
          <Add onClick={addFilterGroup} />
        </IconButton>
      </Tooltip>
      <List sx={{ width: '100%' }}>
        {queryData.length === 0 && (
          <Typography variant="caption">
            Press button to add a filter group.
          </Typography>
        )}
        {queryData.map((filterGroup, filterGroupIndex) => (
          <List
            key={filterGroupIndex}
            sx={{ width: '100%', mb: 1, bgcolor: 'background.paper' }}
          >
            <ListItem>
              <Stack spacing={2} direction="row">
                <FormControl sx={{ minWidth: '200px' }}>
                  <InputLabel id={`filterGroup-${filterGroupIndex}`}>
                    Operand
                  </InputLabel>
                  <Select
                    labelId={`filterGroup-${filterGroupIndex}`}
                    label="Operand"
                    value={filterGroup.operand}
                    onChange={(e) => {
                      const {
                        target: { value }
                      } = e
                      setQueryData(
                        produce(queryData, (draft) => {
                          draft[filterGroupIndex].operand = value
                          return draft
                        })
                      )
                    }}
                  >
                    {AvailableFilterGroupOperands.map((operand) => (
                      <MenuItem
                        key={`filterGroup-${filterGroupIndex}-${operand}`}
                        value={operand}
                      >
                        {operand}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Tooltip title="Add Filter">
                  <IconButton>
                    <Add onClick={() => addFilter(filterGroupIndex)} />
                  </IconButton>
                </Tooltip>
              </Stack>
            </ListItem>
            {queryData[filterGroupIndex].children.map((filter, filterIndex) => (
              <ListItem key={filterIndex}>
                <Filter
                  filterGroupIndex={filterGroupIndex}
                  index={filterIndex}
                  data={filter}
                  onChange={handleFilterChange}
                />
              </ListItem>
            ))}
          </List>
        ))}
      </List>
      <Button disabled={!canSubmit}>Submit</Button>
    </Box>
  )
}

QueryBuilder.propTypes = {
  organizationId: PropTypes.number
}

export default QueryBuilder
